import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthMuiF } from '../Mui/module/OMPAuthMuiF.js';

export const openSignUp = () =>
  OMPCoreUtilF.openFrameFit(OMPAuthMuiF.modal_frame, (frame, page, [login, signup]) => {
    frame.always_remove = true;
    frame.animation = true;
    signup.selected = true;
    login.selected = false;

    login.title = TT('auth::login::login_01');
    signup.title = TT('auth::signup::signup_01');

    frame.prev_frame_show = true;
  });

export const openLogin = () =>
  new Promise((res) => {
    OMPCoreUtilF.openFrameFit(OMPAuthMuiF.modal_frame, (frame, page, [login, signup]) => {
      frame.always_remove = true;
      frame.animation = true;
      signup.selected = false;
      login.selected = true;

      login.title = TT('auth::login::login_01');
      signup.title = TT('auth::signup::signup_01');

      frame.prev_frame_show = true;
      frame.closed = (x, data) => {
        res(data);
      };
    });
  });
