import axios from 'axios';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { find, go, sel, some, sumBy } from 'fxjs/es';
import { makeApiUrl } from '../../../Util/S/Function/util.js';
import { PaymentLogConstantS } from '../../../PaymentLog/S/Constant/module/PaymentLogConstantS.js';
import { GlobalPortOneConstantS } from '../../S/Constant/module/GlobalPortOneConstantS.js';
import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { GlobalPortOneS } from '../../S/Function/module/GlobalPortOneS.js';

export class Impt {
  constructor(collabo_type) {
    try {
      if (collabo_type === '') {
        this.KEY = process.env.GLOBAL_PORT_ONE_KEY_MARPPLE;
      } else if (collabo_type === 'creator') {
        this.KEY = process.env.GLOBAL_PORT_ONE_KEY_MARPPLESHOP;
      } else {
        throw new Error('Impt collabo type 필요합니다.');
      }
      if (!this.KEY) throw new Error('KEY가 없어서 결제 모듈을 이용할수 없습니다.');
      this.ENVIRONMENT = process.env.GLOBAL_PORT_ONE_ENVIRONMENT;
      this.MERCHANT_NAME = collabo_type === 'creator' ? 'MARPPLE SHOP' : 'MARPPLE';
    } catch (e) {
      ConfigSentryF.error(e);
    }
  }

  async requestPay(
    {
      merchant_order_id,
      amount,
      currency,
      billing_name,
      billing_email,
      billing_phone,
      success_url,
      failure_url,
      order_details,
      iamport_name,
      method_key,
    },
    error_cb,
    success_cb,
  ) {
    try {
      const { signature_hash, jwt_token } = await go(
        axios.get(UtilS.makeApiUrl(`/:lang/@api/global_port_one/hash`, { lang: T.lang }), {
          params: {
            success_url,
            failure_url,
            merchant_order_id,
            currency,
            amount,
          },
        }),
        sel('data'),
      );
      const _params = {
        merchant_details: {
          name: this.MERCHANT_NAME,
          promo_discount: 0,
          // promo_discount: 0.0,
          shipping_charges:
            Math.round(
              (amount -
                go(
                  order_details,
                  sumBy(({ price }) => price),
                )) *
                100,
            ) / 100,
        },
        merchant_order_id,
        signature_hash,
        amount,
        currency,
        environment: this.ENVIRONMENT,
        description: iamport_name,
        order_details,
        billing_details: {
          billing_name,
          billing_email,
          billing_phone,
        },
        country_code: T.lang === 'jp' ? 'JPY' : T.lang === 'en' ? 'USD' : null,
        expiry_hours: 24,
        is_checkout_embed: false,
        show_back_button: false,
        show_shipping_details: false,
        default_guest_checkout: true,
        show_items: false,
        success_url,
        failure_url,
        user_configured_field1: iamport_name,
        user_configured_field2: this.ENVIRONMENT,
        /* 표기 이름 */
      };

      if (
        some(
          (payment_method) => payment_method.method_key === method_key,
          GlobalPortOneConstantS.PAYMENT_SERVICE_PAYMENT_METHODS,
        )
      ) {
        const pmt_channel = go(
          GlobalPortOneConstantS.ALL_PAYMENT_METHODS,
          find((payment) => payment.method_key === method_key),
          sel('pmt_channel'),
        );
        const params = {
          ..._params,
          key: this.KEY,
          environment: this.ENVIRONMENT,
          pmt_channel,
          pmt_method: method_key,
          response_type: 'redirect_url_only',
          override_auto_redirect: true,
        };

        const payment_method = GlobalPortOneS.getPaymentMethodInfo(method_key);
        if (payment_method.method_type === GlobalPortOneConstantS.METHOD_TYPE.BANK_TRANSFER) {
          const [surname, last_name] = billing_name.trim().split(/\s+/);
          params.billing_details.billing_name = last_name || surname;
          params.billing_details.billing_surname = surname;
        }
        try {
          axios.post(
            makeApiUrl(`/:lang/@api/payment_logs/request`, {
              lang: T.lang,
            }),
            {
              module: PaymentLogConstantS.MODULE.GLOBAL_PORT_ONE,
              description: '결제 요청',
              environment: this.ENVIRONMENT,
              merchant_uid: merchant_order_id,
              data: params,
            },
          );
        } catch (e) {}
        new window.PortOne({ protOneKey: this.KEY }).paymentService.payment(
          params,
          (err) => {
            try {
              axios.post(
                makeApiUrl(`/:lang/@api/payment_logs/api_result`, {
                  lang: T.lang,
                }),
                {
                  module: PaymentLogConstantS.MODULE.GLOBAL_PORT_ONE,
                  collabo_type: G.collabo_type,
                  server: PaymentLogConstantS.SERVER.service,
                  description: '결제 실패',
                  err,
                },
              );
            } catch (e) {}
            error_cb({ message: 'payment failed' });
          },
          (response) => {
            const { data } = response;
            try {
              axios.post(
                makeApiUrl(`/:lang/@api/payment_logs/api_result`, {
                  lang: T.lang,
                }),
                {
                  module: PaymentLogConstantS.MODULE.GLOBAL_PORT_ONE,
                  payment_status: data.status || data.is_success,
                  merchant_uid: data.merchant_order_ref,
                  imp_uid: data.order_ref,
                  collabo_type: G.collabo_type,
                  server: PaymentLogConstantS.SERVER.service,
                  description: '결제 완료 front',
                  data,
                },
              );
            } catch (e) {}
            if (data.is_success) {
              const originalUrl = `${success_url}`;
              const url = new URL(originalUrl);
              url.searchParams.set('status', GlobalPortOneConstantS.STATUS.Initiated);
              url.searchParams.set('order_ref', data.order_ref);
              url.searchParams.set('link_order_ref', data.merchant_order_ref);
              url.searchParams.set('signature_hash', signature_hash);
              url.searchParams.set('merchant_order_ref', data.merchant_order_ref);
              url.searchParams.set('channel_order_ref', data.channel_order_ref);
              location.href = url.toString();
              success_cb();
            } else {
              error_cb({ message: 'payment failed' });
            }
          },
        );
      } else {
        const payment_method = go(
          GlobalPortOneConstantS.CHECKOUT_SERVICE_PAYMENT_METHODS,
          find((payment_method) => payment_method.method_key === method_key),
        );
        const params = {
          ..._params,
          chosen_payment_methods: [
            {
              payment_channel: payment_method.pmt_channel,
              payment_method: payment_method.method_key,
            },
          ],
        };
        try {
          axios.post(
            makeApiUrl(`/:lang/@api/payment_logs/request`, {
              lang: T.lang,
            }),
            {
              module: PaymentLogConstantS.MODULE.GLOBAL_PORT_ONE,
              description: '결제 요청',
              environment: this.ENVIRONMENT,
              merchant_uid: merchant_order_id,
              data: params,
            },
          );
        } catch (e) {}
        new window.PortOne({
          // Your PortOne Key
          portOneKey: this.KEY,
          jwtToken: jwt_token,
        }).checkoutService.checkout(params);
        success_cb();
      }
    } catch (e) {
      ConfigSentryF.error(e);
    }
  }
}
