import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthChangeEmailError } from '../../Error/OMPAuthChangeEmailError.js';

/**
 * @param {AxiosError|Error} err
 * @return {Promise<void>}
 */
export const authChangeEmailErrorHandler = async (err) => {
  const chang_email_error = new OMPAuthChangeEmailError(
    err.response?.data || TT('error::common::error_01'),
    err,
  );
  ConfigSentryF.error(chang_email_error);
  await OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
};
