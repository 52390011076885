import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * @param {string?} social_id
 * @param {string?} email
 */
export const changeEmailTmpl = ({ social_id, email }) => {
  return html`
    <div class="omp-auth__change-email">
      <form action="" method="post" class="omp-auth__change-email__form">
        <div class="omp-auth__change-email__input-wrap">
          <div class="omp-auth__change-email__current-email-wrap">
            <span class="omp-auth__change-email__current-email-label">
              ${TT('auth::change_email::change_email_02')}
            </span>
            <span class="omp-auth__change-email__current-email">
              ${social_id
                ? `${email || TT('auth::find_id::find_id_12')}(${TT('auth::find_id::find_id_13')})`
                : email}
            </span>
            <input type="hidden" name="email" value="${email}" />
            <input type="hidden" name="social_id" value="${social_id}" />
          </div>

          <div class="omp-auth__change-email__new-email">
            ${OMPCoreAtomTmplS.input({
              class: 'omp-auth__change-email__new-email-input',
              name: 'new_email',
              type: 'email',
              placeholder: TT('auth::change_email::change_email_03'),
              required: true,
              style: {
                underline: true,
                height: 50,
              },
            })}
          </div>

          ${social_id
            ? ''
            : html`
                <div class="omp-auth__change-email__password-check">
                  ${OMPCoreAtomTmplS.input({
                    class: 'omp-auth__change-email__password-check',
                    name: 'password',
                    type: 'password',
                    placeholder: TT('auth::change_email::change_email_04'),
                    required: true,
                    style: {
                      underline: true,
                      height: 50,
                    },
                  })}
                </div>
              `}
        </div>

        <div class="omp-auth__change-email__btn">
          ${OMPCoreAtomTmplS.actionButton({
            solidity: 'thick',
            klass: 'omp-auth__change-email__submit-btn',
            type: 'submit',
            text: TT('auth::change_email::change_email_05'),
          })}
        </div>
      </form>
    </div>
  `;
};

// 현재 이메일과 같은 이메일 입니다. 다시 확인해 주세요.
