import { CreatorAddressConstantS } from '../../../../../../../modules/Creator/Address/S/Constant/module/CreatorAddressConstantS.js';

export default {
  modules: {
    VectorEditor: {
      AcrylicFigure: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                svg_img_el_load_error: 'フィギュアの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'フィギュアの形を作るのに失敗しました。',
                png_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'アクリル状の編集',
                prev_button: '以前',
                next_button: '次へ',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
            },
            alert_message: {
              empty_acrylic_figure: 'まず、フィギュアーを作ってください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'フィギュアエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_acrylic_figure_editor: 'フィギュアエディタを開けません。',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                svg_img_el_load_error: 'フィギュアの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'フィギュアの形を作るのに失敗しました。',
                png_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '取り消し',
                done_button: '完了',
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                path_button_reset: '新しく作り',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'レイヤー',
                layer_label_group: 'グループ',
                layer_label_text: 'テキスト',
                layer_label_shape: '図形',
                layer_label_image: 'イメージ',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_close: 'パスを閉じる',
                path_button_open: 'パスを開く',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                done_button: '完了',
              },
            },
            template: {
              cancel_button: '取り消し',
              next_button: '次へ',
              text_menu_decorate_text: 'テキスト飾り',
              text_menu_open_keyboard: 'キーボードを開く',
              text_menu_duplicate: 'コピー',
              text_menu_delete: '削除',
              path_menu_set_color: '色',
              path_menu_set_stroke: '枠',
              path_menu_edit_path: 'パス編集',
              path_menu_duplicate: 'コピー',
              path_menu_delete: '削除',
              shape_menu_set_color: '色',
              shape_menu_set_stroke: '枠',
              shape_menu_set_duplicate: 'コピー',
              shape_menu_set_delete: '削除',
              object_menu_duplicate: 'コピー',
              object_menu_delete: '削除',
              bottom_menu_image: 'アップロード',
              bottom_menu_shape: '図形',
              bottom_menu_path: 'パス',
              bottom_menu_text: 'テキスト',
              bottom_menu_group: 'デコレーション',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: '以前の作業復元に失敗しました。',
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              empty_acrylic_figure: 'まず、フィギュアを作ってください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                svg_img_el_load_error: 'フィギュアの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'フィギュアの形を作るのに失敗しました。',
                png_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'アクリル状の編集',
                prev_button: '以前',
                next_button: '次へ',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
            },
            alert_message: {
              empty_acrylic_figure: 'まず、フィギュアーを作ってください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'フィギュアエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_acrylic_figure_editor: 'フィギュアエディタを開けません。',
            },
          },
        },
        StandLeg: {
          CreatorPC: {
            template: {
              header_title: 'アクリルスタンド固定台編集',
              description: 'アクリルスタンド固定台の位置を移動してみてください。',
              minus_button: '-',
              plus_button: '+',
              size_description: 'アクリルスタンド固定台の横サイズを調節してください',
              position_description: 'アクリルスタンド固定台の位置を移動してみてください。',
              cancel_button: '移転',
              done_button: '完了',
            },
            error_message: {
              no_editor_container_el: 'エディタへ接続できません。',
              no_acrylic_figure_stand_leg_editor: 'エディタへ接続できません。',
              no_make_target_image_el: 'フィギュアのイメージを見つかりません。',
              max_cutting_line_offset_error: 'これ以上幅を増やす事はできません。',
              min_cutting_line_offset_error: 'これ以上幅を減らす事はできません。',
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
            },
          },
          Mobile: {
            template: {
              title: 'アクリルスタンド固定台編集',
              cancel_button: '移転',
              done_button: '完了',
              description: 'アクリルスタンド固定台の位置を移動してみてください。',
              minus_button: '-',
              plus_button: '+',
              size_description: '幅',
              position_description: '位置',
            },
            error_message: {
              max_cutting_line_offset_error: 'これ以上幅を増やす事はできません。',
              min_cutting_line_offset_error: 'これ以上幅を減らす事はできません。',
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
            },
          },
          PC: {
            template: {
              header_title: 'アクリルスタンド固定台編集',
              description: 'アクリルスタンド固定台の位置を移動してみてください。',
              minus_button: '-',
              plus_button: '+',
              size_description: 'アクリルスタンド固定台の横サイズを調節してください',
              position_description: 'アクリルスタンド固定台の位置を移動してみてください。',
              cancel_button: '移転',
              done_button: '完了',
            },
            error_message: {
              no_editor_container_el: 'エディタへ接続できません。',
              no_acrylic_figure_stand_leg_editor: 'エディタへ接続できません。',
              no_make_target_image_el: 'フィギュアのイメージを見つかりません。',
              max_cutting_line_offset_error: 'これ以上幅を増やす事はできません。',
              min_cutting_line_offset_error: 'これ以上幅を減らす事はできません。',
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
            },
          },
        },
        template: {
          empty_template_guide: '自分だけのスタンドを作ってみてください。',
        },
        message: {
          invalid_prev_acrylic_figure_svg: '以前の作業を読み込むことができません。',
          login_first: 'ログインが必要です。 先にログインしてください。',
          make_images: '出力用の高解像度イメージを作成中です。 完了するまでお待ちください。',
          too_large_image: 'フィギュアのイメージが大きすぎて作成できません。 PC環境でお作りください。',
          output_making_error: '製品製作用ファイル作成に失敗しました。',
          load_image_error: 'イメージファイルを読み込むことはできません。',
          invalid_meta_data: 'フィギュアのデータが見つかりません。',
        },
      },
      FreeShape: {
        Mobile: {
          template: {
            title: 'シェイプ',
          },
        },
        PC: {
          template: {
            title: 'シェイプ',
          },
        },
      },
      Keyring: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                svg_img_el_load_error: 'キーホルダの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'キーホルダの形を作るのに失敗しました。',
                png_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'アクリル状の編集',
                prev_button: '以前',
                next_button: '次へ',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
            },
            alert_message: {
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              hook_cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'キーリングエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_keyring_editor: 'キーリングエディタを開けません。',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                svg_img_el_load_error: 'キーホルダの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'キーホルダの形を作るのに失敗しました。',
                png_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '取り消し',
                done_button: '完了',
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                path_button_reset: '新しく作り',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'レイヤー',
                layer_label_group: 'グループ',
                layer_label_text: 'テキスト',
                layer_label_shape: '図形',
                layer_label_image: 'イメージ',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_close: 'パスを閉じる',
                path_button_open: 'パスを開く',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                done_button: '完了',
              },
            },
            template: {
              cancel_button: '取り消し',
              next_button: '次へ',
              text_menu_decorate_text: 'テキスト飾り',
              text_menu_open_keyboard: 'キーボードを開く',
              text_menu_duplicate: 'コピー',
              text_menu_delete: '削除',
              path_menu_set_color: '色',
              path_menu_set_stroke: '枠',
              path_menu_edit_path: 'パス編集',
              path_menu_duplicate: 'コピー',
              path_menu_delete: '削除',
              shape_menu_set_color: '色',
              shape_menu_set_stroke: '枠',
              shape_menu_set_duplicate: 'コピー',
              shape_menu_set_delete: '削除',
              object_menu_duplicate: 'コピー',
              object_menu_delete: '削除',
              bottom_menu_image: 'アップロード',
              bottom_menu_shape: '図形',
              bottom_menu_path: 'パス',
              bottom_menu_text: 'テキスト',
              bottom_menu_group: 'デコレーション',
            },
            error_message: {
              reopen_prev_keyring_error: '以前の作業復元に失敗しました。',
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'アクリル状の編集',
                description: 'アクリル状を編集してくだい。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                svg_img_el_load_error: 'キーホルダの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'キーホルダの形を作るのに失敗しました。',
                png_data_url_making_error: 'キーホルダの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'アクリル状の編集',
                prev_button: '以前',
                next_button: '次へ',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_keyring_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るキーホルダーイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: 'リングの裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
            },
            alert_message: {
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              hook_cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'キーリングエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_keyring_editor: 'キーリングエディタを開けません。',
            },
          },
        },
        Grid: {
          CreatorPC: {
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: '画像アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: 'キャンセル',
              next_button: '次へ',
            },
            alert_message: {
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
              hook_cutting_line_making_error:
                'リングの裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'キーホルダーのエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_keyring_editor: 'キーホルダーのエディタを開けません。',
            },
          },
          Mobile: {
            ElementLayer: {
              template: {
                header_title: 'レイヤー',
                label_group: 'グループ',
                label_text: 'テキスト',
                label_shape: '図形',
                label_image: 'イメージ',
              },
            },
            PathEditor: {
              template: {
                done_button: '完了',
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_close: 'パスを閉じる',
                path_button_open: 'パスを開く',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
              },
            },
            template: {
              cancel_button: 'キャンセル',
              next_button: '次へ',
              text_menu_decorate_text: 'テキスト飾り',
              text_menu_open_keyboard: 'キーボードを開く',
              text_menu_duplicate: '複製',
              text_menu_delete: '削除',
              path_menu_set_color: '色',
              path_menu_set_stroke: '枠',
              path_menu_edit_path: 'パス編集',
              path_menu_duplicate: '複製',
              path_menu_delete: '削除',
              shape_menu_set_color: '色',
              shape_menu_set_stroke: '枠',
              shape_menu_set_duplicate: '複製',
              shape_menu_set_delete: '削除',
              object_menu_duplicate: '複製',
              object_menu_delete: '削除',
              bottom_menu_image: '画像アップロード',
              bottom_menu_shape: '図形',
              bottom_menu_path: 'パス',
              bottom_menu_text: 'テキスト',
              bottom_menu_group: 'デコレーション',
            },
            error_message: {
              reopen_prev_keyring_error: '以前の作業復元に失敗しました。',
              cutting_line_making_error:
                'リングの裁断線を作るのに失敗しました。 もう一度やり直してください。',
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
          },
          PC: {
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: '画像アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: 'キャンセル',
              next_button: '次へ',
            },
            alert_message: {
              empty_keyring: 'まず、キーホルダーを作ってください。',
              close_keyring_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_calculation_error: '裁断線の計算に失敗しました。',
              hook_cutting_line_making_error:
                'リングの裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'キーホルダーのエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_keyring_editor: 'キーホルダーのエディタを開けません。',
            },
          },
        },
        Hook: {
          CreatorPC: {
            template: {
              header_title: 'キーホルダーリングの編集',
              description: 'キーホルダーリングのタイプを選択して、位置を移動してみてください。',
              inside_button: '内側',
              outside_button: '外側',
              cancel_button: '移転',
              done_button: '完了',
            },
            error_message: {
              no_editor_container_el: 'エディタへ接続できません。',
              no_keyring_hook_editor: 'エディタへ接続できません。',
              no_make_target_image_el: 'キーホルダーのイメージを見つかりません。',
            },
          },
          Mobile: {
            template: {
              title: 'キーホルダーリングの編集',
              cancel_button: '移転',
              done_button: '完了',
              description: 'キーホルダーリングのタイプを選択して、位置を移動してみてください。',
              inside_button: '内側',
              outside_button: '外側',
            },
          },
          PC: {
            template: {
              header_title: 'キーホルダーリングの編集',
              description: 'キーホルダーリングのタイプを選択して、位置を移動してみてください。',
              inside_button: '内側',
              outside_button: '外側',
              cancel_button: '移転',
              done_button: '完了',
            },
            error_message: {
              no_editor_container_el: 'エディタへ接続できません。',
              no_keyring_hook_editor: 'エディタへ接続できません。',
              no_make_target_image_el: 'キーホルダーのイメージを見つかりません。',
            },
          },
        },
        template: {
          empty_template_guide: '自分らしいキーホルダを作って見てください。',
        },
        message: {
          invalid_editor_type: '間違ったエディタタイプです。',
          invalid_prev_keyring_svg: '以前の作業を読み込むことができません。',
          login_first: 'ログインが必要です。 先にログインしてください。',
          make_images: '出力用の高解像度イメージを作成中です。 完了するまでお待ちください。',
          too_large_image: 'キーホルダーのイメージが大きすぎて作成できません。 PC環境でお作りください。',
          output_making_error: '製品製作用ファイル作成に失敗しました。',
          load_image_error: 'イメージファイルを読み込むことはできません。',
          invalid_meta_data: 'キーホルダーのデータが見つかりません。',
        },
      },
      TopMenu: {
        PC: {
          template: {
            top_menu_history_reset: '初めから',
            top_menu_history_undo: '取消',
            top_menu_history_redo: '元に戻す',
            top_menu_toggle_layers_mode: 'レイヤー',
            top_menu_duplicate: 'コピー',
            top_menu_delete: '削除',
            top_menu_forward: '前に',
            top_menu_backward: '後ろに',
            top_menu_group: 'グループ化',
            top_menu_ungroup: 'グループ解除',
            top_menu_flip_horizontal: '右左反転',
            top_menu_flip_vertical: '上下反転',
            top_menu_move_to_left_end: '左',
            top_menu_move_to_right_end: '右',
            top_menu_move_to_top_end: '上',
            top_menu_move_to_bottom_end: '下',
            top_menu_move_to_horizontal_center: '中央',
            top_menu_move_to_vertical_center: '中央',
          },
        },
      },
      Sticker: {
        Single: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'ステッカーを編集してください。',
                description: 'ステッカーを剥がせるように、カッターラインの形状を編集してください。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
                done: '終わりました',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                svg_img_el_load_error: 'フィギュアの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'フィギュアの形を作るのに失敗しました。',
                png_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '直接編集',
                prev_button: '閉まる',
                next_button: '次へ',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                done_button: '閉まる',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
              done_button: '終わりました',
            },
            alert_message: {
              empty_acrylic_figure: 'まず、フィギュアーを作ってください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'フィギュアエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_acrylic_figure_editor: 'フィギュアエディタを開けません。',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'ステッカーのカットラインを編集する',
                description: 'ステッカーを剥がせるようにカットラインの形状を編集してください。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
              },
              error_message: {
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '取り消し',
                done_button: '完了',
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                path_button_reset: '新しく作り',
              },
              error_message: {
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'レイヤー',
                layer_label_group: 'グループ',
                layer_label_text: 'テキスト',
                layer_label_shape: '図形',
                layer_label_image: 'イメージ',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '直線追加',
                path_button_add_c: '曲線追加',
                path_button_remove: '点削除',
                path_button_close: 'パスを閉じる',
                path_button_open: 'パスを開く',
                path_button_change_to_l: '直線変更',
                path_button_change_to_c: '曲線変更',
                done_button: '完了',
              },
            },
            template: {
              cancel_button: '取り消し',
              next_button: '次へ',
              done_button: '完了',
              text_menu_decorate_text: 'テキスト飾り',
              text_menu_open_keyboard: 'キーボードを開く',
              text_menu_duplicate: 'コピー',
              text_menu_delete: '削除',
              path_menu_set_color: '色',
              path_menu_set_stroke: '枠',
              path_menu_edit_path: 'パス編集',
              path_menu_duplicate: 'コピー',
              path_menu_delete: '削除',
              shape_menu_set_color: '色',
              shape_menu_set_stroke: '枠',
              shape_menu_set_duplicate: 'コピー',
              shape_menu_set_delete: '削除',
              object_menu_duplicate: 'コピー',
              object_menu_delete: '削除',
              object_menu_background: '背景設定',
              bottom_menu_image: 'アップロード',
              bottom_menu_shape: '図形',
              bottom_menu_path: 'パス',
              bottom_menu_text: 'テキスト',
              bottom_menu_group: 'デコレーション',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: '以前の作業復元に失敗しました。',
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'ステッカーを編集してください。',
                description: 'ステッカーを剥がせるように、カッターラインの形状を編集してください。',
                edit_manual_button: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
                done: '終わりました',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                no_default_outer_cutting_line: '基本アクリル形が見つかりません。',
                svg_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                svg_img_el_load_error: 'フィギュアの形を作るのに失敗しました。',
                svg_canvas_to_blob_error: 'フィギュアの形を作るのに失敗しました。',
                png_data_url_making_error: 'フィギュアの形を作るのに失敗しました。',
                max_cutting_line_offset_error: 'これ以上オフセットを増やす事はできません。',
                min_cutting_line_offset_error: 'これ以上オフセットを減らす事はできません。',
                manual_cutting_line_error: 'アクリル形を直接編集することはできません。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '直接編集',
                prev_button: '以前',
                next_button: '次へ',
                done_button: '完了',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                path_button_reset: '初めから',
              },
              error_message: {
                no_editor_container_el: 'アクリル状エディタを開けられません。',
                no_acrylic_figure_cutting_line_editor: 'アクリル状エディタを開けられません。',
                no_make_target_image_el: 'アクリル状を作るフィギュアイメージを作る事ができません。',
                waiting_cutting_line_update:
                  'まだ、内部のカット線が反映されていません。 アップデートされた内部のカット線をご確認ください。',
                cutting_line_calculation_error: '裁断線の計算に失敗しました。',
                cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
                inner_cutting_line_making_error: 'アクリルの内側の形状を計算する事に失敗しました。',
              },
            },
            PathEditor: {
              template: {
                header_title: 'パス編集',
                path_button_group_add: '追加',
                path_button_group_delete_close: '削除/閉じる',
                path_button_group_change: '変更',
                path_button_add_l: '選択した点に直線を追加',
                path_button_add_c: '選択した点に曲線を追加',
                path_button_remove: '選択点の削除',
                path_button_close: '開点閉め',
                path_button_open: '閉点開き',
                path_button_change_to_l: '選択した点を直線で変更',
                path_button_change_to_c: '選択した点を曲線に変更',
                done_button: '完了',
                prev_button: '閉まる',
              },
            },
            template: {
              menu_upload_image: 'アップロード',
              menu_my_image: 'マイデザイン',
              menu_shape: '図形',
              menu_path: 'パス',
              menu_text: 'テキスト',
              layer_title: 'レイヤー',
              layer_label_group: 'グループ',
              layer_label_text: 'テキスト',
              layer_label_shape: '図形',
              layer_label_image: 'イメージ',
              cancel_button: '取り消し',
              next_button: '次へ',
              prev_button: '前の',
              done_button: '終わりました',
            },
            alert_message: {
              empty_acrylic_figure: 'まず、フィギュアーを作ってください。',
              close_acrylic_figure_editor: 'すべてのデザインが削除ます。<br />エディターを終了しますか？',
            },
            error_message: {
              cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
              no_editor_container_el: 'フィギュアエディタを開けません。',
              no_pre_process: '以前の作業復元に失敗しました。',
              no_acrylic_figure_editor: 'フィギュアエディタを開けません。',
            },
          },
        },
        template: {
          empty_template_guide: '自分だけのスタンドを作ってみてください。',
        },
        FreeMobile: {
          cancel: 'キャンセル',
          done: '終わり',
          set_background: '背景色',
          add_sticker: 'ステッカーを追加する',
          edit: '編集',
          duplicate: 'コピー',
          remove: '消去',
        },
        obj: {
          '배경 색상': '背景色',
        },
        message: {
          invalid_prev_acrylic_figure_svg: '以前の作業を読み込むことができません。',
          login_first: 'ログインが必要です。 先にログインしてください。',
          make_images: '出力用の高解像度イメージを作成中です。<br />完了するまでお待ちください。',
          too_large_image: 'フィギュアのイメージが大きすぎて作成できません。 PC環境でお作りください。',
          output_making_error: '製品製作用ファイル作成に失敗しました。',
          load_image_error: 'イメージファイルを読み込むことはできません。',
          invalid_meta_data: 'フィギュアのデータが見つかりません。',
          fail_image: 'イメージの作成に失敗しました。',
          cutting_line_making_error: '裁断線を作るのに失敗しました。 もう一度やり直してください。',
          cutting_line_making_error2: '裁断線を作るのに失敗しました。',
          '현재 모든 스티커가 1개로':
            'すべてのステッカーはリンクされています。<br/>完了することはできません。<br/>ステッカーは複数枚作成してください。',
          '스티커 에디터 안전 간격 찾을 수 없습니다.': '스티커 에디터 안전 간격 찾을 수 없습니다.',
          '스티커 에디터 안전 영역 찾을 수 없습니다.': '스티커 에디터 안전 영역 찾을 수 없습니다.',
          '안전 간격을 계산할 수 없습니다.': '안전 간격을 계산할 수 없습니다.',
          '안전 영역을 계산할 수 없습니다.': '안전 영역을 계산할 수 없습니다.',
          '스티커 에디터 앞면 이미지를 찾을 수 없습니다.': '스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
          '스티커 영역을 계산할 수 없습니다.': '스티커 영역을 계산할 수 없습니다.',
          '잘못된 그리드 크기입니다.': '잘못된 그리드 크기입니다.',
          '잘못된 그리드입니다.': '잘못된 그리드입니다.',
          '재단선을 만들 수 없습니다.': '재단선을 만들 수 없습니다.',
          '스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.':
            'ステッカー画像が大きすぎて作成できません。 PC環境で作ってください。',
          '스티커를 찾을 수 없습니다.': 'ステッカーが見つかりません。',
          '디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?':
            'デザインはすべて削除されます。<br/>エディタを終了しますか？',
          '죄송합니다. 다시 시도해 주세요.': '申し訳ありません。もう一度お試しください。',
          '빈 스티커입니다. 스티커를 만들어주세요 :)': '空のステッカー。<br />ステッカーを作ってください :)',
          '너무 가깝거나 가장자리에 있는 스티커를 움직여주세요.':
            'ステッカーが近すぎるか、端にあります。<br/>ステッカーを動かしてください。',
          '복사에 실패했습니다.': 'コピーに失敗しました。',
          '스티커는 최대 20개까지 생성할 수 있습니다.': 'ステッカーは最大20個まで作成できます。',
          '선택된 스티커가 없습니다.': '選択したステッカーはありません。',
        },
        FreeGrid: {
          '스티커 추가': 'ステッカーを追加してください。',
          '스티커 추가하기': 'ステッカーを追加してください。',
          '기존 스티커를 덮어쓰기 하시겠습니까?': '既存のステッカーを上書きしますか？',
          취소: 'キャンセル',
          완료: '完了',
          편집: '編集',
          삭제: '消去',
          스티커: 'ステッカー',
          '배경 설정': '背景設定',
          '만든 스티커 수정이 필요하신가요?': '作成されたステッカーに修正が必要ですか?',
          '스티커 편집하기': 'ステッカーを編集する',
          복제: '複製',
          '복제할 영역을 선택하고 완료해 주세요.': '複製する領域を選択して完了してください。',
          이전: '以前',
          '반칸이 있습니다. 모든 빈칸을 채워주세요.':
            'バンカンがあります。<br />すべての空白を記入してください。',
        },
      },
    },
  },

  mshop_cart_and_checkout: {
    배송비추가: '+ 送料',
    구매불가: '購入できません',
    마플샵배송: 'MarppleShop配送',
    셀러배송: 'クリエイター配送',
    디지털상품: 'デジタルグッズ',
    요청사항: 'リクエスト',
    '수량 변경': '数量変更',
    배송비: '輸送費',
    무료배송: '送料無料',
    도서산간비: '遠方地域の場合は別途運賃がかかります',
    이상: '以上',
    '구매시 무료 배송': '購入時の送料無料',
    '결제 완료 후 7일간 다운로드 가능': '支払い後7日間ダウンロード可能',
    '결제 완료 후': '後払い',
    '7일간 다운로드': '7日間のダウンロード',
    가능: '利用可能',
    상품금액: '製品価格',
    주문금액: '注文合計',
    '주문 정보': '注文情報',
    주문정보: '注文情報',
    '총 주문 금액': '合計注文金額',
    수량: '量',
    개: 'Qty',
    '총 수량': '総量',
    '총 상품금액': '製品の合計価格',
    '총 배송비': '送料総額',
    '마플샵배송 도서산간': '마플샵배송 도서산간',
    '셀러배송 도서산간': '셀러배송 도서산간',
    '쿠폰 할인': 'クーポン割引',
    '포인트 할인': 'ポイント割引',
    '주문서 작성': '注文書',
    '구매 불가 상품이 있습니다.': `購入不可の商品があります。<br>購入不可の商品の下の説明を参照してください。`,
    '상품의 옵션이 변경되었습니다. 확인후 장바구니에 다시 넣어주세요.':
      '商品のオプションが変更されました。確認後、買い物かごに入れてください。',
    '선택한 옵션 또는 상품이 품절되었습니다.': '選択したオプションまたは商品が在庫切れです。',
    '현재 가능한 재고는 개 입니다.': '在利用可能な在庫は{{remaining_stock_quantity}}つです。',
    '선택한 옵션 또는 상품의 재고가 부족하여 주문이 불가합니다. 수량을 변경해 주세요.':
      '選択したオプションや商品の在庫が不足しているため、注文はできません。数量を変更してください。',
    'An error occurred.<br>Will refresh.': 'エラーが発生しました。<br>リロードします。',
  },
  mshop_customer_order: {
    '배송지 변경 불가': '配送先変更不可',
    '상품 취소 요청 접수': '商品キャンセルリクエスト受付',
    '취소 완료': 'キャンセル完了',
    '확인 후 자동 취소가 완료됩니다.': '確認後、自動キャンセルは完了です。',
    '취소 요청': 'キャンセルリクエスト',
    '문제가 발생했습니다.': '問題が発生しました。',
    '상품 취소 요청': 'キャンセルリクエスト',
    '취소 상품은 다시 복구할 수 없습니다.<br>정말 주문을 취소하시겠습니까?':
      'キャンセル商品は元に戻せません。<br>本当に注文をキャンセルしますか？',
    '스토어의 모든 "마플샵 배송" 상품들이 취소 됩니다.<br>계속 하시겠습니까?': `ストア [ {{name}} ] のすべての「Marple Shop Delivery」製品はキャンセルされます。<br>続行しますか?`,
  },

  mp_checkout: {
    '쿠폰이 적용 됐습니다.': 'クーポンが適用されました。',
    '주문서 작성': '注文書',
  },
  masking_tape_modal_page: {
    title: 'プレビュー',
    description1: '実際、上記の画像のように真ん中の部分のみパターンで製作されます。',
    description2:
      '* 問題があるように見えたら、ガイドの「パターン飾り→点線領域」部分をもう一度確認して作業してください。',
    cancel: 'ガイドの確認',
    done: '続く',
  },

  composite_preview_modal_page: {
    title: 'プレビュー',
    description1: '* アップロードしたデザインそのまま印刷するので詳細ページのガイドを必ず確認してください。',
    description2: '* カラーモードの違いにより、画面と実際の商品の色がお互いに細かく異なることがあります。',
    cancel: 'ガイドの確認',
    done: '続く',
    back: '作りツールに戻る',
  },
  // TODO 영준 jp 받으면 적용하자!
  stores_delivery_company_info: {
    '배송 방법': '配送方法',
    '일반 택배': '一般宅配',
    '기본 배송비': '基本送料',
    '도서 산간비': '離島地域送料',
    '배송 지역': '配送地域',
    전국: '全国',
    '배송 기간': '配送期間',
    '상품 출고 후 영업일':
      '商品出荷後、1~3営業日以内にお受け取りが可能で離島地域や配送業者の物量が多い場合期間がもう少しかかる場合があります。',
    '무료 배송 조건': '무료 배송 조건',
    '제작 소요 기간': '제작 소요 기간',
    배송기간: '배송기간',
    '지금 주문하면':
      '只今ご注文すると&nbsp;<span class="pd-guide__start-end-strong">{{start}} ~ {{end}}&nbsp;</span>の間に出発します！',
    '특별함을 담아 제작해서 배송해드려요.':
      '特別さを込めて製作し配送いたします。<br />わくわくする気持ちでお待ちください！',
    배송정보: '配送情報',
  },
  plus_admin: {
    pa1: 'クリエイター管理',
    pa2: '所属クリエイターのストアを追加して管理してください。',
    pa3: 'ストア',
    pa4: 'ラベル',
    pa5: 'クリエイター情報',
    pa6: 'ストア生成',
    pa7: '初期化',
    pa8: '検索',
    pa9: 'ラベル無',
    pa10: '重要ユーチューバー',
    pa11: '重要インスタ',
    pa12: '重要アーティスト',
    pa13: '重要その他',
    pa14: '問題、話題',
    pa15: 'その他',
    pa16: '富平区文化財団',
    pa17: 'ロゴ',
    pa18: 'ラベル',
    pa19: 'SNS',
    pa20: 'ストア名',
    pa20_1: 'Filter',
    pa21: '生成時間',
    pa22: 'クルー名',
    pa23: 'クリエイター',
    pa24: '商品数',
    pa25: '公開',
    pa26: '非公開',
    pa27: '紹介',
    pa28: 'リンク',
    pa29: 'スタジオ',
    pa30: '編集',
    pa31: 'ストアラベル修正に失敗しました。',
    pa32: 'ストア生成',
    pa33: 'ストアドメイン',
    pa34: 'クリエイターEメール',
    pa35: 'ストアドメインはすべて小文字で保存されます。',
    pa36: '追加',
    pa37: 'Eメールを入力してください。',
    pa38: '{label}を入力してください。',
    pa39: '有効なドメインを入力してください。',
    pa40: 'ストアを生成しました。',
    pa41: 'ストア生成に失敗しました。',
    pa42: 'ストア編集',
    pa43: '修正',
    pa44: '削除',
    pa45: '登録',
    pa46: 'ストア紹介',
    pa47: '審査評',
    pa48: 'クルー名',
    pa49: '参考URL',
    pa50: '添付ファイル',
    pa51: '公開商品数',
    pa52: '非公開商品数',
    pa53: 'ストアアドレス',
    pa54: 'スタジオアドレス',
    pa55: 'HOTクリエイター',
    pa56: 'ストアサムネイル',
    pa57: 'サムネイルのアップロード',
    pa58: '運営中止',
    pa59: '内部規定違反',
    pa60: '販売承認',
    pa61: '招待したクリエイター',
    pa62: 'ストアを見る',
    pa63: 'クルーモールホーム商品公開',
    pa64: 'ブランドホーム公開',
    pa65: '退会希望',
    pa66: 'タトゥー販売',
    pa67: 'はい',
    pa67_1: 'いいえ',
    pa68: '不合格状態です。',
    pa69: '本当にストアのクリエイターを削除しますか？',
    pa70: '本当に当該ストアを削除しますか？',
    pa71: 'サムネイルのアップロード失敗',
    pa72: 'サムネイルのアップロード成功',
    pa73: 'サムネイルの削除失敗',
    pa74: 'サムネイルの削除成功',
    pa75: '修正失敗',
    pa76: '修正完了',
    pa77: 'ストア名修正',
    pa78: 'ストア名を入力してください。',
    pa79: '非運営クリエイター管理',
    pa80: '現在運営していないクリエイターのストアを確認し、運営を開始してください。',
    pa81: 'ストアID',
    pa82: 'ラベル',
    pa83: 'SNS',
    pa84: '生成日',
    pa85: '未承認ストア',
    pa86: 'ファイル',
    pa87: '名前なし',
    pa88: '販売承認',
    pa89: '販売承認失敗',
    pa90: '最終不合格',
    pa91: '最終不合格失敗',
    pa92: '招待されたクリエイター',
    pa93: '招待されたクリエイター',
    pa94: '連結したクリエイターなし',
    pa95: '未登録ストア',
    pa96: '不合格ストア',
    pa97: 'クリエイター販売状況',
    pa98: 'クリエイター販売状況と収益を確認できます。',
    pa98_1: '셀러 상품의 판매 현황을 확인할 수 있습니다.',
    pa99: '{{domain}}クルーが見つかりません。',
    pa100: '週間',
    pa101: '月間',
    pa102: '年間',
    pa103: '全体',
    pa104: 'クルー',
    pa105: '注文数',
    pa106: '取消',
    pa107: '数量',
    pa108: '{{unit}}消費者販売価格',
    pa111: 'デザイン収益',
    pa112: 'デザイン追加収益',
    pa113: 'デザイン総収益',
    pa114: 'クリエイターデザイン収益',
    pa115: '企業デザイン収益',
    pa116: '企業割引収益',
    pa117: '企業総収益',
    pa118: 'クリエイター出金',
    pa119: 'クリエイター収益による出金申請金額を確認し、精算を進めてください。',
    pa120: 'ショップ',
    pa121: '金額',
    pa122: '実入金額',
    pa123: '口座情報',
    pa124: '処理ステータス',
    pa125: '申請日時',
    pa126: '処理日時',
    pa127: '取消日時',
    pa128: '入金予定日',
    pa129: '機能',
    pa130: '処理ステータス',
    pa131: '処理中',
    pa132: '承認',
    pa133: '取消',
    pa134: '検証可否',
    pa135: '検証された出金',
    pa136: '検証されない出金',
    pa137: '口座種類',
    pa138: '個人',
    pa139: '19歳未満',
    pa140: '事業者',
    pa141: '海外居住者',
    pa142: 'エクセルダウンロード',
    pa143: '日付変更',
    pa144: '承認処理',
    pa145: '入金する',
    pa146: '本当に出金をお断りしますか？',
    pa147: '選択された出金がありません。',
    pa148: '出金が要請されました。',
    pa149: 'サーバーにエラーがあります。',
    pa150: '税金計算書の発行を確認して進めてください。',
    pa151: '口座情報修正ロック',
    pa152: '口座情報',
    pa153: 'ショップ名',
    pa154: '種類',
    pa155: '入金する金額',
    pa156: '銀行名',
    pa157: 'ペイパルアカウント',
    pa158: '口座番号',
    pa159: 'ペイパルアカウントのキャプチャ',
    pa160: '通帳の写し',
    pa161: '個人情報',
    pa162: '名前',
    pa163: '国籍',
    pa164: '携帯電話',
    pa165: '身分証の写し',
    pa166: '情報提供の同意可否',
    pa167: '同意',
    pa168: '未同意',
    pa169: '会社情報',
    pa170: '商号名',
    pa171: '代表者名',
    pa172: '事業者番号',
    pa173: '担当者Eメール',
    pa174: '事業所アドレス',
    pa175: '業種、業態',
    pa176: '事業者登録証の写し',
    pa177: '個人情報',
    pa178: '法定代理人の情報',
    pa179: '法定代理人の生年月日',
    pa180: '法定代理人の氏名',
    pa181: '法定代理人の同意書',
    pa182: 'ウォン（韓国）',
    pa183: 'ドル',
    pa184: '実入金額',
    pa185: '拒否',
    pa186: 'ペイパルEメール',
    pa187: '口座番号',
    pa188: 'マイナンバー',
    pa189: '必要な口座情報の内容がすべて記入されていません。口座情報ページで内容を記入してください。',
    pa190:
      'マープルに税金計算書の発行可否を確認後に入金します。税金計算書の発行がまだの場合、税金計算書の発行後に出金申請をしなければなりません。続けますか？',
    pa191:
      'ストップ！口座情報登録を正確に入力しましたか？情報に誤りのある場合、出金申請は取り消され、再度行う出金申請は翌月に精算が行われます。',
    pa192: '入力した入金額が精算金額よりも多いです。続ける場合は確認を押してください。',
    pa193: '企業出金',
    pa194: '企業出金申請金額を確認し、出金申請をしてください。',
    pa195: 'Marpple Shop分析',
    pa196: 'ストア招待',
    pa197: '{n}つ',
    pa198: '販売承認された招待',
    pa199: 'ショップオープン承認',
    pa200: 'オープンしたショップ',
    pa201: '商品生成ショップ',
    pa202: '商品未生成ショップ',
    pa203: 'ショップ未オープン',
    pa204: '登録したクリエイターのうち、合格メールの通知後1週間を経過しても製品生成を行わなかったストア数',
    pa205: 'ショップ別平均商品数',
    pa206: '1つ以上売れたショップ',
    pa207: '合格クリエイター',
    pa208: '不合格クリエイター',
    pa209: '出金金額',
    pa210: '精算／出金累積金額',
    pa211: '時間別商品生産統計',
    pa212: 'クリエイター販売金額／販売量 Top 10',
    pa213: 'クリエイター別商品生成個数 Top 15',
    pa214: 'クリエイター統計',
    pa215: 'クリエイター申請分類',
    pa216: '週別売上',
    pa217: '売上累積統計',
    pa218: '週別受付者数',
    pa219: '受付者累積統計',
    pa220: '週別クリエイターオープン数',
    pa221: 'クリエイターオープン累積統計',
    pa222: '週別商品生成数',
    pa223: '商品生成累積統計',
    pa224: '受付者数',
    pa225: 'クリエイターオープン数',
    pa226: '商品生成数',
    pa227: '売上額',
    pa228: '日付',
    pa229: '注文数',
    pa230: '生成数',
    pa231: '実際クリエイター数',
    pa232: 'クリエイター申請数',
    pa233: 'クリエイター別種類',
    pa234: 'クリエイター別売上額',
    pa235: 'クリエイター別販売量',
    pa236: '月別出金金額（万ウォン）',
    pa237: '精算金額（万ウォン）',
    pa238: '出金金額（万ウォン）',
    pa239: 'Marpple Shopデザイナーリスト',
    pa240: '全体',
    pa241: '申請',
    pa242: '申請日',
    pa243: 'ステータス',
    pa244: '詳細を見る',
    pa245: 'Marpple Shopデザイナーのプロフィール',
    pa246: 'インスタグラム',
    pa247: 'ユーチューブ',
    pa248: 'その他',
    pa249: 'ポートフォリオ',
    pa250: 'デザイナーポートフォリオ',
    pa251: '当該要請に失敗しました。',
    pa252: '当該デザイナー申請を承認しました。',
    pa253: '当該デザイナー申請を拒否しました。',
    pa254: '全体商品',
    pa255: '公開商品',
    pa256: '非公開商品',
    pa257: '販売保留商品',
    pa258: '検収完了商品',
    pa258_1: '検収完了',
    pa259: '検収中商品',
    pa260: 'すべての商品',
    pa261: '衣類',
    pa262: '携帯電話ケース／テク',
    pa263: 'ファッション雑貨',
    pa264: 'ホームデコ／リビング',
    pa265: 'ステッカー／紙類',
    pa266: '額縁',
    pa267: '文房具／オフィス',
    pa268: 'デジタルグッズ',
    pa269: 'キッズ／愛犬',
    pa270: '一般商品',
    pa271: 'デジタル商品',
    pa272: '検収する商品がありません！',
    pa273: '販売保留',
    pa274: '残り件数',
    pa275: '通過',
    pa276:
      '検収確認された商品を再び確認したいときは、「全体商品 -> 検収完了」に移動してください。\n デザインが変更されると、再び検収を受けなければならないため、検収した商品が、また出てくることがあります。',
    pa277: '確認',
    pa278: 'キーボード作動を許可しますか？ \n 右側 Key [➡️] 通過 \n 下側 Key [⬇️️] 販売保留',
    pa279: '1000人以上',
    pa280: '{{number}}人',
    pa281: 'クリエイター名',
    pa282: '準備中',
    pa283: 'チャンネル登録者数',
    pa284: 'ユーチューバー',
    pa285: 'デザイナー',
    pa286: '商品デザイン検収',
    pa287: 'ユーチューブ販売クリエイター',
    pa288: 'クリエイターチップ管理',
    pa289: '申告受付',
    pa290: '商品検収',
    pa291: 'PB商品注文書',
    pa292: 'PB商品注文書の詳細を見る',
    pa293: 'PB商品リスト',
    pa294: '企業口座登録',
    pa295: '製品クォリティ―管理',
    pa296: '企業精算',
    pa297: 'サイト管理',
    pa298: '要検収商品数',
    pa299: 'ストアへのショートカット',
    pa300: '検修へのショートカット',
    pa301: '価格',
    pa302: 'PB商品交換／払戻し（完了:{{settled}}／全体:{{total}}）',
    pa303: '払戻要請',
    pa304: '払戻完了',
    pa305: '取消要請',
    pa306: '取消完了',
    pa307: '有効でないsearch_condition_key',
    pa308: '注文日基準',
    pa309: '過去1カ月',
    pa310: '過去3カ月',
    pa311: '過去6カ月',
    pa312: '過去1年',
    pa313: '降順',
    pa314: '昇順',
    pa315: '検索条件',
    pa316: '宅配',
    pa317: 'まとめて配送',
    pa318: 'クイック配送',
    pa319: '訪問受領',
    pa320: '個別配送',
    pa321: '払戻し',
    pa322: '交換',
    pa323: '注文日',
    pa324: '注文者',
    pa325: '決済手段',
    pa326: '注文商品',
    pa327: '要約情報',
    pa328: '注文商品情報',
    pa329: '注文商品ID',
    pa330: '商品名',
    pa331: '注文数量',
    pa332: '注文金額',
    pa333: '配送情報',
    pa334: '配送ID',
    pa335: '配送種類',
    pa336: '受領者',
    pa337: '運送状',
    pa338: '連絡先',
    pa339: 'アドレス',
    pa340: 'メモ',
    pa341: '連絡先1',
    pa342: '連絡先2',
    pa343: '注文者も配送通知',
    pa344: 'なし',
    pa345: 'Marpple Shop商品コード',
    pa346: '画像',
    pa347: 'カテゴリー',
    pa348: 'Marpple Shopスタジオ',
    pa349: 'オプション',
    pa350: 'PB商品交換／払戻し',
    pa351: '回収ID',
    pa352: '生成日／修正日',
    pa353: 'ステータス',
    pa354: '払戻金額',
    pa355: '運送状番号',
    pa356: '払戻商品',
    pa357: '商品ID（回収／注文）',
    pa358: '回収種類',
    pa359: '注文商品名',
    pa360: '数量（回収／注文）',
    pa361: '払戻商品ではありません。',
    pa362: '事由',
    pa363: '追加説明',
    pa364: '交換／払戻商品',
    pa365: '全体',
    pa366: '進捗中',
    pa367: '処理完了',
    pa368: '決済手段',
    pa369: '全額割引',
    pa370: 'リアルタイム口座振替',
    pa371: 'イニシスのリアルタイム口座振替',
    pa372: 'クレジットカード',
    pa373: 'サムスンペイ',
    pa374: 'PAYCO',
    pa375: 'ネイバーペイ',
    pa376: 'SSGPAY',
    pa377: 'LPAY',
    pa378: 'Alipay',
    pa379: 'イニシスのクレジットカード',
    pa380: 'お振り込み',
    pa381: 'オフラインカード',
    pa382: 'オフライン現金',
    pa383: '仮想口座',
    pa384: 'カカオペイ',
    pa385: 'トスペイ',
    pa386: '入力した情報で企業収益が精算されるため、正確に記入してください。',
    pa387: 'ペイパルEメール',
    pa388:
      '出金申請中には口座を修正することができません。誤って入力された情報がある場合、お問い合わせください。',
    pa389: 'ペイパルアカウント画面（選択）',
    pa390: '通帳の写しアップロード（選択）',
    pa391: '法定代理人の資格を満たしていません。',
    pa392: '未成年者は19歳未満の種類で申請しなければなりません。',
    pa393: '誤謬があります。開発チームにお問い合わせください。',
    pa394: 'PayPalアカウント（メール）',
    pa395: '次の内容が有効ではありません。',
    pa396: 'クリエイターの商品販売量と精算金額を確認することができます。',
    pa397: '総出金額',
    pa398: '合計',
    pa399: 'エクセルダウンロード',
    pa400: '総入金額',
    pa401: '残額',
    pa402: 'ブランド管理',
    pa403: '色、ロゴなどの企業のブランドアイデンティティを反映して、サイトに適用してください。',
    pa404: 'メインページGNB設定',
    pa405: 'PC用ロゴ',
    pa406: '推奨サイズ（横80～920px、縦88px）',
    pa407: 'モバイル用ロゴ',
    pa408: '推奨サイズ（横60～460px、縦56px）',
    pa409: '添付されたファイルがありません。',
    pa410: 'ファイルのアップロード',
    pa411: 'フッター（Footer）設定',
    pa412: '企業名',
    pa413: '企業名を入力してください。',
    pa414: '企業公式ホームページURL',
    pa415: 'フェイスブックURL',
    pa416: 'インスタグラムURL',
    pa417: 'ユーチューブURL',
    pa418: 'ツイッターURL',
    pa419: '保存する',
    pa420: '画像ファイルではありません。',
    pa421: '保存されました',
    pa422: 'プロモーションバナー管理',
    pa423: 'メインに公開するプロモーションバナーを作成して管理してください。',
    pa424: '公開したいプロモーションバナーを作成して管理してください。',
    pa425: '全体プロモーション',
    pa426: 'メイン並び順変更',
    pa427: 'プロモーションバナー管理',
    pa428: 'メインに公開するプロモーションバナーを作成して管理してください。',
    pa429: '予約掲示',
    pa430: 'サイトのメインページに登録するプロモーションバナーを作成してください。',
    pa431: 'ドラッグして好きなように並び順を変更することができます。',
    pa432: 'プロモーションバナーを非公開に変更する',
    pa433: '公開制限されたバナーは非公開に切り替わります。<br/>今すぐメインで公開解除しますか？',
    pa434: 'プロモーションバナー作り',
    pa435: '画像選択',
    pa436: 'PC用バナー画像を登録してください。',
    pa437: '推奨サイズ',
    pa438: 'モバイル用バナー画像を登録してください。',
    pa439: 'バナー生成',
    pa440: 'PCプロモーションタイトル設定',
    pa441: '内容入力',
    pa442: '多言語をサポートし、入力時に左側でプレビュー可能です。',
    pa443: 'タイトル入力',
    pa444: '色選択',
    pa445: 'コンテンツ詳細',
    pa446: '商品詳細',
    pa447: 'クリエイターショップホーム',
    pa448: 'Marpple Shopプラスホーム',
    pa449: '商品リスト＋カテゴリー組み合わせ',
    pa450: 'クリエイター申請',
    pa451: 'モバイルプロモーションタイトル設定',
    pa452: 'プロモーションURL・公開設定',
    pa453: 'URLアドレス',
    pa454: 'バナークリック時に移動するURLを入力してください。',
    pa455: 'リンクタイプ',
    pa456: '現在ウィンドウ',
    pa457: '新ウィンドウ',
    pa458: '予約',
    pa459: '韓国標準時間（KST）基準で、設定した掲示日まで非公開状態に維持されます。',
    pa460: 'アプリリンク種類',
    pa461: 'クリエイターまたはMarpple Shopプラス名を入力してください。',
    pa462: 'バナー生成',
    pa463: '保存完了',
    pa464: '初期化',
    pa465: 'Marpple Shopクリエイター申請',
    pa466: 'モバイル用画像を登録してください。',
    pa467: '保存されました。',
    pa468: '画像容量は{{size}}KBまでアップロード可能です。',
    pa469: 'プロモーションバナーを削除しますか？',
    pa470: 'コンテンツ管理',
    pa471: '色々なテーマのコンテンツを作って管理してください。',
    pa472: '全体コンテンツ',
    pa473: 'メイン並び順変更',
    pa474: '様々なテーマを主題として商品を構成して、<br/>コンテンツを新たに作ってください。',
    pa475: '保存する',
    pa476: 'コンテンツを作成する',
    pa477: '文と写真、商品、リンクなどを活用して、コンテンツを作成してください。',
    'pa477-1':
      '(コンテンツ画像推奨サイズ:横1120px*縦5000px以下/登録可能形式:jpg、jpeg、png、gif、webp / 容量:5MB以下))',
    pa478: 'プレビュー',
    pa479: '発行する',
    pa480: '指定された比率のサムネイル画像を登録してください。',
    'pa480-1': 'サムネイルイメージ必須サイズです',
    'pa480-2': '登録可能形式:jpeg、png / 容量5MB以下',
    'pa480-3': '画像のサイズを確認します',
    'pa480-4': '画像のサイズが合いません。<br/>確認後、もう一度アップロードしてください。',
    'pa480-5': '画像容量を確認します',
    'pa480-6': '画像の容量が超過しました。<br/>確認後、もう一度アップロードしてください。',
    'pa480-7': '画像拡張子を確認します',
    'pa480-8': '画像の拡張子が正しくありません。<br/>確認後、もう一度アップロードしてください。',
    pa481: 'フィードタイプ',
    pa482: 'Marpple Shopナウ',
    pa483: '画像詳細設定',
    pa484: 'メイン登録可否',
    pa485: 'サムネイルで商品プレビュー',
    pa486: '締切可否',
    pa487: 'コンテンツのカテゴリー',
    pa488: '選択してください',
    pa489: '必要な内容が記入されていません。',
    pa490: 'コンテンツのタイトル',
    pa491: 'タイトルを入力してください。',
    pa492: 'サムネイルテキスト位置',
    pa493: '上段',
    pa494: '下段',
    pa495: 'キービジュアル画像',
    'pa495-1': 'キービジュアルイメージ必須サイズ',
    'pa495-2': '登録可能な形式: jpeg、png / 容量は5MB以下です',
    pa496: '画像をアップロードします',
    pa497: 'キービジュアルテキスト',
    pa498: 'キービジュアルテキスト色',
    pa499: '企画展をメインで非公開にしますか？',
    pa500: '問題が発生しました。',
    pa501: '商品ページ管理',
    pa502: '商品ページのカテゴリーを作り上げてください。',
    pa503: 'カテゴリーのまとめを作ってください。',
    pa504: '集まり',
    pa505: '並び順',
    pa506: 'タイプ選択',
    pa507: '1つ以上の言語にタイトルを入力してください。',
    pa508: 'カテゴリーを追加してください。推薦個数：small、medium - 6つ以上、large - 4つ以上',
    pa509: 'カテゴリーは必須です。',
    pa510: 'カテゴリー追加',
    pa511: 'カテゴリー設定（必須）',
    pa512: '画像変更',
    pa513: 'スタイル変更（選択）',
    pa514: 'カテゴリー選択',
    pa515: 'ライブ放送中管理',
    pa516: 'メインに公開するライブ放送を作成して管理してください。',
    pa517: '全体ライブ放送',
    pa518: 'メイン並び順変更',
    pa519: 'サイトのメインページに登録するライブ放送を作成してください。',
    pa520: 'ライブ放送タイトル設定',
    pa521: 'タイトル入力',
    pa522: '説明入力',
    pa523: '多言語をサポートします。',
    pa524: 'ライブ放送URL・公開設定',
    pa525: 'URLアドレス',
    pa526: 'iframe動画を取得',
    pa527: '保存する',
    pa528: 'ライブ放送作り',
    pa529: 'iframeの形が正しくありません。',
    pa530: 'ライブ放送を削除しますか？',
    pa531: 'タイトル入力',
    pa532: '多言語をサポートします。',
    pa533: '説明の入力',
    pa534: '多言語をサポートします。',
    pa535: 'ライブ放送タイトル設定',
    pa536: 'ライブ放送URL/公開設定',
    pa537: 'URLアドレス',
    pa538: 'バナークリック時に移動するURLを入力してください。',
    pa539: 'iframe動画を取得',
    pa540: 'メイン画面に公開するiframeアドレスを入力してください。',
    pa541: '代表商品URLアドレス',
    pa542: 'メイン画面に公開する代表商品URLアドレスを入力してください。',
    pa543: '公開ステータス',
    pa544: 'メイン公開可否',
    pa545: '公開解除されたライブ放送は非公開に切り替わります。今すぐメインで公開解除しますか？',
    pa546: 'マネージャー管理',
    pa547: 'サイト管理のためにマネージャーを追加し、権限範囲を設定してください。',
    pa548: '機能',
    pa549: 'クルー権限リスト',
    pa550: '権限',
    pa551: 'マスター',
    pa552: '権限領域：クルー',
    pa553: 'クリエイター出金内訳照会',
    pa554: 'クリエイター出金承認',
    pa555: 'クリエイター出金取消',
    pa556: 'クルー口座登録',
    pa557: 'クルー出金申請',
    pa558: 'クルー出金照会',
    pa559: 'すべてのスタジオアクセス',
    pa560: 'ショップラベル修正',
    pa561: 'ショップ作り',
    pa562: 'ショップ削除',
    pa563: 'ショップクリエイター変更',
    pa564: 'ショップ名変更',
    pa565: '商品ホーム公開可否修正',
    pa566: '注文および販売分析収益照会',
    pa567: 'マネージャー権限修正',
    pa568: 'マネージャー削除',
    pa569: 'マネージャー追加',
    pa570: 'サイト管理',
    pa571: 'クルーアドミンアクセス',
    pa572: '権限領域：スタジオ',
    pa573: 'クリエイター出金内訳照会',
    pa574: '出金要請',
    pa575: '口座情報照会',
    pa576: '商品製作',
    pa577: '精算内訳詳細',
    pa578: '注文内訳詳細',
    pa579: '販売分析照会',
    pa580: 'ショップ名変更',
    pa581: '商品削除',
    pa582: '商品販売保留',
    pa583: '注文および販売分析収益照会',
    pa584: '本当に削除しますか？',
    pa585: 'サーバーエラー発生、開発チームにお問い合わせください。',
    pa586: '{label}を入力してください。',
    pa587: 'マネージャー追加',
    pa588: 'マネージャーメール',
    pa589: 'クルー権限',
    pa590: 'セルラー登録',
    pa591: '不合格',
    pa592: '成人認証が必要',
    pa593: '失敗しました',
    pa594: '権限目録表',
    pa595: 'クルー権限の変更',
    pa596: '出金できる金額がありません。',
    pa597: '出金可能な金額が最低金額1万ウォンを超えません。',
    pa598: '銀行',
    pa599: '商品リスト',
    pa600: 'サムネイル画像を先に登録してください。',
    pa601: '内容を入力してください',
    pa602: 'サムネイル',
    pa603: 'キービジュアル',
    pa604: '画像のアスペクト比を確認してください。推定身長： {{height}}',
    pa605: 'サムネイルイメージがありません。',
    pa606: '重要な視覚的イメージがありません。',
    pa607: '主要',
    pa608: 'メインブランドカラーを選択',
    pa609: 'ブランドカラー',
    pa610: 'サイトのメイン上部メニューやポイントの色がすべて反映されます。',
    pa611: 'メタタグの設定',
    pa612: 'OpenGraph イメージ',
    pa613: 'OpenGraph タイトル',
    pa614: 'OpenGraph 内容',
    pa615: '推奨サイズ (横 1200px, 縦 600px)',

    pa616: 'プレビューを確認して保存してください。',
    pa617: '保存すると元に戻せません。保存しますか？',
    pa618: '準備中..',
    pa619: '申請日',
    pa620: '保存に失敗しました。',
    pa621: 'メインクリエイターは最大7名まで選択できます。 他のクリエイターを選択解除後、進めてください。',
    pa622: 'メインクリエイター管理',
    pa623: 'メインに公開するクリエイターを選択してください。ドラッグして露出順を変更できます。',
    pa624:
      '以下のクリエイターリストからサイトメインに公開するクリエイターを選択してください。<br />メインクリエイターは最大7名まで登録可能です。',
    pa625: 'フルクリエイター',
    pa626: '入金額を入力する必要があります。',
    pa627: '入金額を数字で入力する必要があります。',
    pa628: 'すでに処理された出金リクエストです。',
    pa629: 'すでにキャンセルされた出金リクエストです。',
    pa630: '住民番号',
    pa631: '製品のクオリティを追加',
    pa632: '製品クオリティ修正',
    pa634: 'サイト管理 - バナー',
    pa635: '{{period}} 公開予定',
    pa636: '{{period}} 公開終了',
    pa637: '{{period}} 公開中',
    pa638: '{{date}} 公開中',
    pa639: 'バナーの所有権はありません。',
    pa640: 'サイト管理 - ブランド',
    pa641: '検収依頼商品数',
    pa642: '検収ページ',
    pa643: 'そのデザイナー（{{designer_id}}）が見つかりません。',
    pa644: 'サイト管理 - ライブ放送',
    pa645: 'ライブ放送の修正',
    pa646: '売り手のメールはありません。',
    pa647: 'すでに登録されているメールです。',
    pa648: '要求されたクルー権限（{{level}}）がありません。',
    pa649: 'crewはありません。',
    pa650: 'certificationはありません。',
    pa651: '該当する注文書はありません。',
    pa652: '無効な差額です。',
    pa653: '注文書ID',
    pa654: '追加する',
    pa655: 'Excelのアップロード',
    pa666: 'リクエスト',
    pa667: 'サイト管理 - タイトル',
    pa668: 'タイトル管理',
    pa669: 'メインの各エリアタイトルを変更してサイトに適用してみてください。',
    pa670: 'メインクリエイタータイトル設定',
    pa671: '多言語をサポートします。',
    pa672: 'タイトルを入力してください。 （スペースを含めて最大29文字）',
    pa673: 'コンテンツの入力',
    pa674: '企画展タイトル設定',
    pa675: '該当するストアはありません。',
    pa676: '該当するラベルが見つかりません。',
    pa677: 'ストアの該当する属性は変更できません。',
    pa678: 'ストアの該当する属性を変更する権限がありません。',
    pa679: '変更されたシェアの割合は、以前に作成された商品には適用されません。',
    pa680: '収益シェア比',
    pa681: 'プラス',
    pa682: 'すでに使用中の販売者のメールです。',
    pa683: 'allクルーは存在しません。',
    pa684: 'marppleクルーは存在しません。',
    pa685: 'クリエイターに合うクルー権限がありません。',
    pa686: '{{type}}はありません。',
    pa687: 'ドメインはありません。',
    pa688: 'ストア名がありません。',
    pa689: '重複したドメインが存在します。',
    pa690: 'Content Description',
    pa691: 'Product Category',
    pas690: 'スタイル管理',
    pas691: 'サイト管理-スタイル管理',
    pas692: 'スタイルに合わせて製品の写真を作成·管理します。',
    pas693: '公開',
    pas694: 'プライベート',
    pas695: '商品追加',
    pas696: '商品修正',
    pas697: '商品アドレス',
    pas698: '商品の住所を入力してください。',
    pas699: 'バナーが公開処理されました。',
    pas700: 'バナーが非公開になりました。',
    pas701: `一時的なエラーです。\nもう一度お試しください。`,
    pas702: '商品が追加されました。',
    pas703: '商品が修正されました。',
    pas704: '이미지 용량은 {{size}}KB까지 업로드할 수 있습니다.',
    pas705: '이미지 사이즈는 {{size}}까지 업로드할 수 있습니다.',
    pas706: '투데이 상세',
    pa707: '検索エンジン露出',
    pa708: 'Tags',
    pa709: 'SEO - Description',
    pa710: 'SEO - Title',
  },

  event: {
    e1: 'コメントを入力してください。\n投稿とは無関係なコメント、アクプルなどは削除される可能性があります:)',
    e2: '1000文字以下',
    e3: 'イベントの削除',
    e4: '保存を押すと元に戻せません。公開はプレビューでのみ可能です。',
    e5: 'ぜひ保存してからプレビューを押してください。',
    e6: 'イベント名',
    e7: 'リストサブタイトルです。',
    e8: 'イベント期間：',
    e9: 'リストのサムネイル (350x350)',
    e10: '画像の変更',
    e11: '992px',
    e12: 'モバイル用バナー（600x600）',
    e13: 'ユーザー確認',
    e14: '問題が発生しました。もう一度お試しください。',
    e15: '電子メールを確認してください。<br/>数分来ない場合は、スパムメールボックスを確認してください。',
    e16: '5文字以上1000文字以下で作成してください。',
    e17: 'イベント期間ではないか、一時的なエラーです。もう一度お試しください。',
    e18: '一時的なエラーです。もう一度お試しください。',
    e19: '新しいイベントを作成する',
  },

  creator: {
    feed: {
      f1: 'フィードの所有権がありません。',
    },
  },

  bank_account: {
    ba1: `口座情報登録`,
    ba2: `入力した口座情報で収益の精算を受けることができます。`,
    ba3: `クリエイター種類選択`,
    ba4: `満14～19歳`,
    ba5: `個人`,
    ba6: `事業者`,
    ba7: `海外居住者`,
    ba8: `口座情報登録`,
    ba9: `銀行`,
    ba10: `入金を受ける口座の銀行を入力してください。`,
    ba11: `口座名義人`,
    ba12: `口座名義人名を入力してください。`,
    ba13: `口座番号`,
    ba14: `-無しで数字のみを入力してください。`,
    ba15: `通帳の写し（選択事項）`,
    ba16: `添付されたファイルがありません。`,
    ba17: `ペイパル（PayPal）アカウント`,
    ba18: `ペイパル（PayPal）アカウントのEメールを入力してください。`,
    ba19: `ペイパル（PayPal）アカウントのキャプチャイメージ`,
    ba20: `ペイパル（PayPal）ウェブサイト&nbsp;{{- link}} にログインします。`,
    ba21: `右上の歯車模様の設定ボタンを押すと表示されるアカウントページをキャプチャし、アップロードしてください。&nbsp;{{- link}}`,
    ba22: `ファイルの例を見る`,
    ba23: `個人情報登録`,
    ba24: `Marpple Shopクリエイターの本人名義で登録された携帯電話を通じて、本人認証が必要です。`,
    ba25: `Marpple Shopで個人に発生する収益に基づき、源泉徴収を通じて金融所得申告が行われます。`,
    ba26: `携帯電話による本人認証`,
    ba27: `クリエイター様の実名を入力してください。`,
    ba28: `国籍`,
    ba29: `クリエイター様の国籍を入力してください。`,
    ba30: `住民登録番号`,
    ba31: `住民登録番号の最初の桁`,
    ba32: `身分証明書の写し（選択事項）`,
    ba33: `サービス規約同意`,
    ba34: `個人情報活用同意（必須）`,
    ba35: `収益支払い、源泉徴収処理、支払明細書の提出、個人事業者事業所得申告、休業・廃業者所得申告および処理結果の返信のために、手続上、名前、住民登録番号が必須です。`,
    ba36: `{{- link}}よる個人情報の活用に同意します。`,
    ba37: `同意します。`,
    ba38: `収益支払いと税金申告のための必須情報であるため、同意しない場合、収入の支払いができません。それでも同意を解除しますか？`,
    ba39: `精算規約同意（必須）`,
    ba40: `本人は、Marpple Corporation.のサービスであるMarpple Shopのクリエイターであり、当該サービスを通じて発生する収益の精算を受けるために、クリエイタースタジオ利用規約に基づき上記のように精算に必要な内容を記入し、情報提供に同意します。`,
    ba41: `上記に入力した内容は、すべて事実であり、情報を虚偽で記載するか、誤って記入して発生した損失については、異議を申し立て、または賠償を請求しないことを約定します。`,
    ba42: `法定代理人の本人認証および同意書登録`,
    ba43: `Marpple Shopクリエイターが満14歳～満19歳の未成年者である場合、Marpple Shopにおける収益活動のために法定代理人（父母など）の同意が必要です。`,
    ba44: `Marpple Shopで個人に発生する収益に基づき、源泉徴収を通じて金融所得申告が行われます。`,
    ba45: `これにより、法定代理人の本人名義で登録された携帯電話を通じて、本人認証が必要です。本人認証後、法定代理人同意書様式に直筆（手書き）で記載した書類のスキャン版もしくは撮影版を添付してください。`,
    ba46: `法定代理人の携帯電話の本人認証`,
    ba47: `法定代理人同意書の様式ダウンロード`,
    ba48: `法定代理人の携帯電話番号`,
    ba49: `法定代理人の生年月日`,
    ba50: `法定代理人の氏名`,
    ba51: `法定代理人同意書の登録`,
    ba52: `電子税金計算書の発行可能可否を選択`,
    ba53: `電子税金計算書の発行が可能ですか？`,
    ba54: `事業者種類は、電子税金計算書の発行が可能な場合に選択可能です。`,
    ba55: `クリエイター種類の選択前に、電子税金計算書を発行可能か確認し、電子税金計算書の発行が不可能な事業者は、個人種類で登録を進めてください。`,
    ba56: `電子税金計算書の発行可能`,
    ba57: `電子税金計算書の発行不可`,
    ba58: `会社情報登録`,
    ba59: `商号名`,
    ba60: `会社商号名を入力してください。`,
    ba61: `代表者名`,
    ba62: `会社の代表者名を入力してください。`,
    ba63: `事業者番号`,
    ba64: `担当者Eメール`,
    ba65: `メールアドレスを入力してください。`,
    ba66: `事業所アドレス`,
    ba67: `事業所アドレスを入力してください。`,
    ba68: `業態`,
    ba69: `会社業態を入力してください。`,
    ba70: `業種`,
    ba71: `会社業種を入力してください。`,
    ba72: `事業者登録証の写し`,
    ba73: `海外居住者の精算実施条件を満たしているか確認`,
    ba74: `海外居住者の精算実施条件`,
    ba75: `海外に年間183日以上滞在した場合`,
    ba76: `Marpple Shopの収益のほか、大韓民国国税庁に申告されている所得がない場合`,
    ba77: `法人は、「海外居住者の精算実施条件」を満たしています。`,
    ba78: `為替レート適用基準`,
    ba79: `入金日基準で前月最終営業日の売買基準率を適用し、USDで入金精算されます。`,
    ba80: `例：2020年6月に支払われる場合、5月の最終営業日である2020年5月29日の売買基準率`,
    ba81: `為替レート適用基準に基づき、USDで入金精算を受けることに同意します。`,
    ba82: `ペイパルEメール`,
    ba83: `ペイパルアカウントのキャプチャ`,
    ba84: `通帳の写しアップロード（選択）`,
    ba85: `ペイパルにログイン後、右上の歯車ボタンを押すと表示されるアカウントページをキャプチャし、アップロードしてください。`,
    ba86: `会社情報`,
    ba87: `入力した情報で企業収益が精算されるため、正確に記入してください。`,
    ba88: `本人は、Marpple Corporation.のサービスであるMarpple Shopのクリエイティブクリエイターとして、当該サービスを通じて発生する収益の精算を受けるために、クリエイタースタジオの利用規約に基づき上記の通り精算に必要な内容を記入し、情報提供に同意します。`,
    ba89: `上記の内容はすべて事実であり、情報を虚偽で記載するか、誤って記入して発生した損失については、異議を申し立て、または賠償を請求しないことを約定します。`,
    ba90: 'ファイル確認',
    ba91: '出金申請時は口座情報がロックされます。 修正変更はできません。',
    ba92: '携帯電話番号',
    ba93: '住民登録番号の後ろの数字',
    ba94: 'クリエイタースタジオ利用約款',
    ba95: '精算約款に同意(必須)',
    ba96: '電子税金計算書の発行ができない場合',
    ba97: '電子税金計算書の発行が不可能な事業者は、個人のタイプで口座情報を登録してください。',
    ba98: '個人タイプで登録しますか？',
    ba99: '事業者タイプで継続進行',
    ba100: '個人のタイプで登録',
    ba101: 'ファイル削除',
    ba102: 'ファイル確認',
    ba103: 'ペイパルアカウントキャプチャーしたことがありません。',
    ba104: 'アプリからはダウンロードできません。 PC から進行してください。',

    ba105: '약관에 동의가 필요합니다.',
    ba106: '계좌 인증 오류: {{msg}}',
    ba107: '계좌정보가 정확하지 않습니다.',
    ba108: '필요한 내용이 아직 기입되지 않았습니다.',
  },

  translation: {
    'Facebook Error':
      'フェイスブックで何らかのエラーが発生したため、現在フェイスブックでのログインができません。',
    MARPPLE: 'MARPPLE',
    마플샵: 'MARPPLE SHOP',
    님: '{{name}}さん',
    '자세히 보기': '詳細を見る',
    // maker
    'No Print': 'プリント無し',
    'Change Character': 'キャラクター変更',
    Text: 'テキスト',
    Design: '無料デザイン',
    Character: 'キャラクター追加',
    Wappens: 'ワッペン',
    Library: 'マイデザイン',
    'Upload Your Design': 'アップロード',
    Image: 'アップロード',
    'Add To Cart': 'カートに入れる',
    'Start Creating': '商品を作る',
    Revert: '初めから',
    Undo: '取消',
    Redo: '元に戻す',
    Delete: '削除',
    Forward: '前に',
    Backward: '後ろに',
    Group: 'グループ化',
    Ungroup: 'グループ解除',
    Horizontal: '右左反転',
    Vertical: '上下反転',
    Left: '左',
    Center: '中央',
    Right: '右',
    Top: '上',
    Middle: '中央',
    Bottom: '下',
    Font: 'フォント選択',
    Spacing: '文字の間隔',
    Rotate: '回転',
    Line: '行の間隔',
    Opacity: '透明度',
    Lock: 'ロック',
    Unlock: 'ロック解錠',
    'Edit Image': 'イメージ編集',
    'Change Font': 'フォント選択',
    Change: '商品変更',
    'Change Product': '商品変更',
    'Coming soon': '準備中です',
    'One size': 'One size',

    Share: '共有する',
    'Wish List': 'お気に入り',

    'Select the image at the bottom and edit it with your finger.':
      '下のイメージを選択後,<br/>指で自由に編集してみましょう！',
    'Embroidery patches are not available for this product': 'この商品はワッペンの使用ができません。',
    'Embroidery patches can not be grouped': 'ワッペンはグループ化できません。',
    'The font colors are not available for this product.': 'この商品では使用できないカラーです。',
    'The font colors or image colors are not available for this product.':
      'この商品では使用できないカラーです。',
    'The image color is not available for this product. Please choose the others.':
      'この商品では使用できないカラーです。他のカラーを選択してください。',
    'Please check if the image is on the printable area.':
      '印刷可能範囲を越えています。<br/>イメージ位置および大きさを確認してください。',
    'Some image size is too large. Please set the image size smaller.':
      'イメージが大きすぎます。<br/>画像サイズを確認してください。',
    'Some image size is too small. Please set the image size bigger.':
      'イメージが小さすぎます。<br/>画像サイズを確認してください 。',
    'The image placed on the non-printable area will be deleted automatically. Would you like to proceed?':
      '印刷可能範囲外にあるイメージは自動的に削除され、マイカートに入ります。<br/>よろしいですか？',
    'Item is overflowed.': '印刷可能な大きさを超えました。',

    'Embroidery patches can not be resized.': 'ワッペンは大きさの調節ができません。',
    'Enlarge function makes image editing easier.': '拡大機能を使用すると、より簡単に編集できます。',
    "It's easy to edit with the lock function.": 'イメージが多い場合はロック機能を活用してみてください。',

    'Emoji and special characters are not supported.': '絵文字と特殊文字は使用できません。 ',
    Cancel: '取消',
    Done: '完了',
    'Design Complete': 'デザイン完了',
    'Drag and drop your images.': 'イメージをドラック＆ドロップしてください。',
    'There are no images selected.': '編集するイメージがありません。',
    'Background Remove Request': '背景消し',
    'Buckle Color': 'Buckle Color',
    Copy: 'コピー',
    Duplicate: '複製',
    Cut: '切り取り',
    Paste: '貼り付け',
    'Select All': '全て選択',
    'Remove All': '全て削除',

    'Hidden images are automatically deleted.': '隠れたイメージは自動削除されます。',
    'Privacy Policy': 'プライバシーポリシー',
    'Terms & Conditions': '利用規約',
    모음: '組み',

    //tutorial
    'Add text and design templates': '商品デザイン',
    'Design your own with text and design templates.':
      'テキストとデザインのテンプレートを使用しあなただけのデザインを作ってみましょう！',
    'Upload your photo or design': 'イメージアップロード',
    'You can upload your own design and photo.': '直接デザインしたイメージや写真をアップロードできます。',
    'Edit your design': 'デザイン編集',
    'You can easily edit your design by using editing toolbar.':
      'イメージ編集機能を使用しデザインを簡単に変更できます。',
    'Design Align Difficult': 'デザインの配置が難しいですか？\n',
    'Choose printing side': 'プリント面選択',
    'Design Loc Opt':
      '位置調節を要請していただくとMarppleの担当者がデザインの位置を最適化された所に配置させていただきます。',
    'You can have your product printed front and back of the product':
      '商品にプリントする面を前面、後面、横面等から選択できます。',
    Next: '次に進む',
    'Got it': '完成',

    Check: 'カスタムする方法',
    'Check before you start': 'カスタムする方法',
    'Read carefully before you start designing': '商品をデザインする前に必ず確認してください。',

    Product: '商品',

    'Font Style': 'フォントスタイル',
    keyboard: 'キーボード',
    Color: 'カラー',
    'Device Color Preview': 'デバイスカラープレビュー',
    'Text Color': 'テキストカラー',
    'Image Color': 'カラー',
    Pattern: 'パターン',
    Crop: '切り取り',
    Layer: 'レイヤー',

    'Next step': '次に進む',
    'Sell Your Products': '商品販売',

    'Add Product': '商品追加',
    Title: '商品名',
    'Set Your Price': '価格設定',
    'Minimum Price': '基本価格',
    Profit: '利益',
    Submit: '完成',
    'All Collaboration': 'コラボ商品',
    Collaboration: 'コラボ',

    'Added to cart': '商品をカートに追加しました。',
    'My Cart': 'マイカート',
    Cart: 'カート',
    Checkout: 'ご購入手続き',
    'Order Complete': '注文完了',
    'Your shopping cart is empty': 'カートに商品が入っていません。',
    'Your wish list is empty': 'お気に入り商品がまだありません。',
    'Product Details': '商品情報',
    'Size/Quantity': 'サイズ/数量',
    Price: '価格',
    Optional: '編集',
    'Total Qty': '総数量',
    'How many do you need?': '数量を選択してください。',
    Confirm: '確認',
    'Buy Now': 'ご購入手続きへ',
    'Buy Now 2': 'ご購入手続きへ',
    Buy: '購入する',
    'Add to Cart': 'カートに入れる',
    'Add All To Cart': '全てカートに追加する',
    Subtotal: '価格',
    Shipping: '送料',
    Discount: '割引',
    Total: '合計',
    'No.': 'No.',
    Print: 'プリント',
    Size: 'サイズ',
    Brand: 'ブランド',
    Edit: '数量変更',
    'Save for Later': '後で購入',
    Remove: '削除',
    'Add Color': '他のカラー',
    '+ Add Color': 'カラー追加 +',
    'Proceed To Checkout': 'ご購入手続き',
    'Order completed': 'ご注文完了',
    'Resizing failed. Please try again.': 'サイズ変更に失敗しました。もう一度行ってください。',
    'Please select one or more products.': '１つ以上の商品を選択してください。',
    'There are some items in your cart': 'サイズと数量を選択していない商品があります。',
    'This item is already in your cart': 'すでにカートに入っている商品です。',
    'Failed to put in cart': 'カートに入れることができませんでした。もう一度行ってください。',
    'Your payment request failed': '決済要請が完了できませんでした。',
    'Please check your balance': '残高を確認してください。',
    'Payment has been stopped': '決済が中断されたため、決済を取り消しました。',

    'View more': 'もっと見る',

    Featured: 'おすすめ順',
    'Low to High': '価格が低い順',
    'High to Low': '価格が高い順',
    Filter: 'フィルター検索',
    Filters: 'フィルター',
    'Select Filters': 'フィルター検索',
    Clear: '初期化',
    Apply: '適用',
    'All products': '全ての商品',
    'Show products': '商品を見る',
    'No products matching the conditions.': 'フィルターに合う商品がありません。',

    'Go to my shop': '私の店に行きなさい',

    'Sign in': 'ログイン',
    'Sign in Facebook': 'Facebookでログイン',
    'Sign up Facebook': 'Facebookでログイン',
    'Sign out': 'ログアウト',
    'Sign up': '会員登録',
    'Your Name': 'お名前',
    Male: '男性',
    Female: '女性',
    'failed please retry': 'リクエストに失敗しました。もう一度お試しください。',

    'This ID is already Withdrawal': '退会済みのIDです。',
    'This ID is already registered': 'すでに加入しているIDです。',
    'You already have a registered email':
      'すでに登録されているメールアドレスです。一般のログインを利用してください。',
    'Name is empty': 'お名前を入力してください。',
    'Email is empty': 'メールアドレスを入力してください。',
    'Password is empty': '暗証番号をご入力ください。',
    'This email address is not available.': 'メールアドレスの形式を確認してください。',
    'Incorrect email or password': 'メールアドレスまたはパスワードを確認してください。',
    'Sign up failed': '会員登録に失敗しました。',
    'Reset password failed': 'パスワード変更に失敗しました。',
    'Check your email for a link':
      'パスワード再設定のため、メールを送信しました。<br/>数分以内にメールが来ない場合は、迷惑メールフォルダを一度ご確認ください。',
    'Please select the shipping provider.': '配送業者を選択してください。',
    'Please enter your shipping address.': '入力されていない必須情報があります。',
    'Please enter the postal code in the postal code field.': '郵便番号欄に郵便番号を入力してください。',
    'Please select the filter you want.': 'ご希望のフィルターを選択してください。',
    'Unfortunately this promo': 'このクーポンコードは無効です。確認後、もう一度行ってください。',
    'The promo code has been exhausted.': 'このクーポンコードは無効です。',
    '주문 금액보다 할인 금액이 큰 경우 쿠폰 적용이 불가능합니다.':
      '注文金額より割引金額が大きい場合、クーポンを適用できません。',
    Qty: '数量',
    'Please choose the size of the product.': 'サイズを選択してください。',
    'Special Requests': 'ご要望',
    'Shipping Address': 'お届け先住所',
    'Payment Summary': 'ご請求額',
    Coupon: 'クーポン',
    Point: 'ポイント',
    Description: '詳細情報',
    'My Point': '保有ポイント',
    'Total Price': '最終ご請求額',
    'Cancel Price': '注文取消金額',
    'Other Shipping Price 2': '同時配送料',
    'No special requests.': '特別な要請事項がありません。',
    'Credit Card': 'クレジットカード',
    'Without a Bank Account': '無通帳入金',
    NAVERPAY: 'NAVERPAY',
    SSGPAY: 'SSGPAY',
    Alipay: 'Alipay',
    SamsungPay: 'SamsungPay',

    'Refund Price': '払い戻し金額',
    Free: '無料',

    'Other Discount': '追加割引',
    'Other Price': '追加金額',
    'Other Shipping Discount': '追加送料',
    'Other Shipping Price': '追加送料割引',
    'Additional Payment': '追加決済',
    'Amount due': '未決済額',
    'Paid Price': '総決済金額',
    Payable: '払い戻し予定',

    'Are you sure you want to delete?': '削除しますか？',
    Post: '確認',
    '로그인 후 이용해 주세요.': 'ログイン後、ご利用ください。',
    '이벤트기간에만 댓글을 작성할 수 있습니다.': 'イベント期間中のみ、コメントの記入が可能です。',
    '내용을 적어주세요.': '内容を記入してください。',
    'Page Not Found': 'ページが見つかりません。',
    'View all': '全体を見る',

    'Are you sure you want to cancel order?': '本当に注文を取り消しますか？',
    'Cancel request is fail.': '注文取消要請に失敗しました。お問い合わせください。',
    'Cancel request is success.': 'お客様の注文取消要請が完了しました。確認後、取消処理をいたします。',
    'Upload an Image': 'アップロード',
    'My Review': 'マイレビュー',
    'Writable Review': '作成可能なレビュー',
    'Written Review': '私が作成したレビュー',
    'File size is too large': 'ファイルの容量が大きすぎます。<br/>{{mb}}MB以下でアップロードしてください。',

    'You have pay an unpaid balance due to the order has been changed':
      '注文情報が変更され、追加でご決済が必要です。<br/>今すぐ決済しますか？',

    coupon_min_price_error: 'このクーポンはご請求額が ¥{{min_price}} 以上でないと使用できません。',

    'Order Number': 'ご注文番号',

    'Product Mismatched': '商品のジャンルを選択してください',
    'Price Mismatched': '決済方法をお選びください',

    'Payment Error': 'ご注文を正常に確定できませんでした。もう一度お試しください。',
    'This browser is not supported and will not work properly':
      '現在お使いのブラウザはではご利用いただけません。最新のIE11以降、Chrome、Safari、モバイルブラウザを使用してください。',

    'Create your style, Curate your vibe.': '簡単に思いどおりの<br/>アイテム完成!',
    "Don't miss out": '新しいテーマ',
    'Be yourself, Be a creator': '自分を表現してみよう',
    'More Themes': '他のテーマを見る',
    'All Themes': '全体を見る',
    'See more': 'もっと見る',
    'Start Designing': 'Start Designing',

    '이전에 페이스북으로 가입하신 마플 계정이 있습니다. 페이스북으로 로그인 하시겠습니까?':
      '以前フェイスブックで登録したマープルアカウントがあります。フェイスブックでログインしますか？',
    '같은 이메일로 가입하신 마플 계정이 있습니다. 로그인 하시겠습니까?':
      '同じEメールで登録したマープルアカウントがあります。ログインしますか？',

    '판매 종료': '販売終了',
    '판매 시작': '販売開始',
    '판매 종료까지 남은 시간': '販売終了までの残り時間',
    '판매 시작까지 남은 시간': '販売開始までの残り時間',
    '한정 판매 기간': '限定販売期間',

    // settings
    '썸네일 선택': 'サムネイル選択',
    '선택 완료': '選択完了',
    '상품 공개 여부': '商品公開可否',
    '배송 출발일 노출': '配送出発日の公開',
    '판매 색상 선택': '販売色選択',
    '다국어 지원 (선택 사항)': '多言語サポート（選択可能）',
    '전체 선택': '全体選択',
    '대표 색상 선택': '代表色選択',
    '판매 수량': '販売数量',
    '1개당 판매 수익': '1つ当たり販売収益',
    '1개당 추가 수익': '1つ当たり追加収益',
    '디자인 스토리 작성하기': 'デザインストーリーを作成する',
    '한정 판매 기간 & 수량 설정': '限定販売設定',
    '1인당 구매 가능 수량': '1人当たりの購入数量',
    '상품 생성': '商品生成',
    '파일 선택': 'ファイル選択',
    '언어별 스토리 설정': '言語別ストーリー設定',
    'SNS에 올린 글 가져오기': 'SNSにアップした投稿を取得',
    '이미지 첨부': '画像の添付',
    '다중 선택 가능': '多重選択可能',

    Up: '上へ',
    Down: '下へ',
    '샵 디자인': 'スタイル設定',
    '상품 만들기': '商品をつくる',
    '상품별 판매 분석': '商品別販売分析',
    정산내역: '精算内訳',
    계좌정보등록: '口座情報登録',
    '계좌정보 확인': '口座情報の確認',
    계정정보: '口座情報',
    마케팅: 'マーケティング',
    '출금 신청': '出金管理',
    '상품 정렬': '製品手配',
    '내샵 보기': 'マイショップを見る',
    로그아웃: 'ログアウト',
    '로고 &middot; 배너 변경': 'スタイルを修正する',
    유형: 'タイプ',
    '소비자 가격': '消費者価格',
    '판매 수량별 수익': '販売数量別収益',
    '판매 수량에 따라 추가 수익이 발생됩니다.': '販売量によって追加収入が発生します。',
    날짜: '日付',
    수량: '数量',
    '취소된 수량': 'キャンセルした数量',
    '주문 내역': '注文数',
    '취소된 주문수': 'キャンセルした注文数',
    '1개당 수익': '1つ当たりの収入',
    '총 수익': '総収益',
    기업: '기업',
    '셀러 수익': '셀러 수익',
    '1개당 제작비': '1つ当たり製作費',
    '1개당 내 수익': '1つ当たりマイ収益',
    '내 수익': '内収入',
    수정: '修正',
    삭제: '削除',
    시작일: '開始日',
    종료일: '終了日',
    '공개 여부': '公開するかどうか',
    공개: '公開',
    비공개: '非公開',
    '판매 보류': '販売中止',
    '관리자에 의해 판매가 보류된 상품입니다.': '管理者によって販売停止された商品です。',
    '디자인 이름': 'デザイン名',
    '디자인 가격': 'デザイン価格',
    한국어: '한국어',
    영어: 'English',
    일본어: '日本語',
    '판매 기간은 1주에서 4주로 설정할 수 있으며, 판매 종료 후 일괄 제작하여 출고됩니다.':
      '販売期間は1週間から4週間まで設定でき、販売終了後に一括製作して出荷されます。',
    '제품 생성': '製品生成',
    '변경 사항 취소': '変更事項取消',
    저장하기: '保存する',
    '디자인 수정': 'デザインを修正する',
    '이 디자인으로 다른 상품 만들기': 'このデザインで他の商品お作り',
    '상품 복제': '商品コピー',
    '미리 보기': 'プレビュー',
    '상품 삭제': '商品削除',
    '샵 이름': 'ショップ名',
    '대표 SNS 주소': '代表SNSアドレス',
    '오른쪽 상단에 표시': '右上に表示',
    '샵 설명': 'ショップ説明',
    '로고 이미지 업로드': 'ロゴ画像アップロード',
    '배너 배경색': 'バナー背景色',
    '배너 이미지 업로드': 'バナー画像アップロード',
    '배너 제목': 'バナータイトル',
    '배너 설명': 'バナー説明',
    저장: '保存',
    취소: '取消',
    초기화: 'リセット',
    완료: '完了',
    변경: '変更',
    '기본 이미지로 지정': '基本イメージに指定',
    '필터 적용': 'フィルター適用',
    '판매할 상품을 만들어보세요.': '販売する商品を作ってください。',
    '판매 순위': '販売順位',
    상품: '商品',
    '공개 상태': '公開状態',
    '판매 기간': '販売期間',
    판매량: '販売量',
    수익: '収入',
    '추가 수익': '追加収入',
    선택: '選択',
    '스토리 작성하기 <i>(권장)</i>': 'ストーリーを作成する <i>(推薦)</i>',
    '제목을 작성해 주세요.': 'タイトルを作成してください。',
    '내용을 작성해 주세요.': '内容を作成してください。',
    '유튜브 비디오의 링크를 입력해 주세요.': 'ユーチューブ動画のリンクを入力してください。',
    '인스타그램 글의 링크를 입력해 주세요.': 'インスタグラム投稿のリンクを入力してください。',
    '트위터 글의 링크를 입력해 주세요.': 'ツイッター投稿のリンクを入力してください。',
    '페이스북 글의 링크를 입력해 주세요.': 'フェイスブック投稿のリンクを入力してください。',
    '이미지를 업로드해 주세요.': '画像をアップロードしてください。',
    '상품 디자인': '商品デザイン',
    '상품 정보 입력': '商品情報入力',
    '상품 선택': '商品選択',
    '상품 카테고리 선택': '商品カテゴリー選択',
    '상품 정보 수정': '情報修正',
    '다시 시도해 주세요.': '再度行ってください。',
    '구매되었거나 장바구니에 담긴 상품은<br/>삭제 할 수 없습니다.<br/>대신 공개 설정을 이용해보세요.':
      'カート購入したりカートに入れた商品は、<br/>削除できません。<br/>代わりに公開設定を利用してください。',
    '삭제 하시겠습니까?': '削除しますか？',
    '구매되었거나 장바구니에 담긴 상품은<br/>디자인 가격을 수정할 수 없습니다.':
      '購入したりカートに入れた商品は、<br/>デザイン価格を修正できません。',
    '디자인에 대한 가격을 설정해 주세요.': 'デザインに対する価格を設定してください。',
    최소: '最小',
    최대: '最大',
    '날짜 변경': '日付を変更',
    '판매 기간을 설정해 주세요.': '販売期間を設定してください。',
    '최소 높이 28px, 최대 높이 56px': '最小高さ28px、最大高さ56px',
    '최소 1120 * 400px, 최대 2240 * 800px': '最小1120 * 400px、最大2240 * 800px',
    '최소 400px * 400px, 최대 800 * 800px': '最小400px * 400px、最大800 * 800px',
    '계정정보에서 전화번호를 입력해 주세요.': 'アカウント情報から電話番号を入力してください。',
    '프로필 이미지': 'プロフィール画像',
    셀러: '販売',
    '이 화면은 미리보기 페이지입니다.': 'この画面はプレビューページです。',
    '디자인 하기': 'デザインする',
    '디자인 수정하기': 'デザインを修正する',
    money_sign: '¥',

    '약관에 동의해 주세요.': '規約に同意してください。',
    '판매 기간이 설정된 상품만 구매할 수 있습니다.': '販売期間が設定された商品のみ購入することができます。',
    '이미지 크기를 확인해 주세요.': '画像サイズを確認してください。',
    '이미지의 비율을 확인해 주세요.': '画像の比率を確認してください。',
    '판매 기간 설정 필요': '販売期間の設定必要',
    '상품이 판매되거나 장바구니에 담길 경우 가격 변경이 불가능하며 판매 기간 수정이 제한됩니다. 상품을 공개하시겠습니까?':
      '商品が販売されたりカートに盛り込まれる場合の価格変更が不可能であり、販売期間の変更が制限されます。商品を公開しますか？',
    파일선택: 'ファイル選択',
    KIDS: 'キッズ',
    '다른 판매 기간과 겹치게 설정할 수 없습니다.': '他の販売期間と重なって設定することはできません。',
    품절: '品切れ',
    '한정 판매 수량': '限定販売数量',
    기간: '期間',
    daily: '日間',
    yearly: '年間',
    monthly: '月間',
    weekly: '週間',
    all: '全体',
    'Please enter your address and name in English.':
      'お届け先ご住所およびご氏名を英語にて入力してください。',
    '1:1 문의': '1：1チャット',
    클립보드: 'クリップボード',
    정산금액: '決済金額',
    결제수수료제외: '決済手数料を除く',
    '상품 색상 변경': '商品色変更',
    '색상 선택 가능': '色選択可能',
    '색상 선택 불가능': '色選択不可能',
    '대표 색상': 'Primary Color',
    '판매 가능 색상': 'Colors Available For Sale',
    '인쇄 불가능 색상': 'Non-Printable Colors',
    '대표 색상은 판매 가능 색상에서 제외 할 수 없습니다.':
      'The primary color cannot be excluded from the available colors.',
    '대표 색상은 인쇄 불가능 색상으로 지정할 수 없습니다.':
      'The primary color cannot be specified as non-printable.',
    '스토어 도메인은 모두 소문자로 저장됩니다.': 'All store domains are stored in lowercase letters.',
    Community: 'コミュニティ',
    Seller: '売り手',
    '카테고리 보기': 'カテゴリーを表示する',
    '카테고리 닫기': 'カテゴリーを閉じる',
    최신순: '最新順',
    인기순: '人気順',
    'Sorry. Payment could not be completed because the limited quantity was exceeded.':
      '申し訳ありません。数量限定を超えて決済を完了していませんでした。',
    '신고 접수는 PC에서만 가능합니다.': '申告受付はPCでのみ可能です。',
    전체: '全体',
    '답변 대기': '回答待ち',
    '판매 재개/샵 정상 운영': '販売再開／<br/>ショップ通常運営',
    '판매 중지/샵 운영 중지': '販売中止／<br/>ショップ運営中止',
    '내부 규정 위반 답변완료': '内部規定違反の回答完了',
    '접수된 신고가 없습니다.': '受け付けられた申告がありません。',
    '마플 개인 커스텀 주문 리뷰를 참고해보세요!':
      'マープル個人カスタマイズ注文のレビューを参照してみてください！',
    '부가세 포함 가격': '{{profit_title}}は付加税込みの価格です。',
    '디자인 가격이 더해져 자동 계산됩니다.': '{{title}}＋デザイン価格を加えて自動計算されます。',
    '판매 수수료': '販売手数料',
    '기본 상품 가격': '基本商品価格',
    '환불 정보': '返金情報',
    '취소 금액 합계': 'キャンセル金額の合計',
    '환불 수단': '返金方法',
    '디자인 가격 부가세 포함 가격': '',
    '디자인 가격 부가세 포함 가격 아프리카': '',
  },

  community: {
    리뷰: 'レビュー',
    '권한이 없습니다.': '権限がございません。',
    '로그인 된 유저가 없습니다.': 'ログインユーザーが見つかりません。',
    실패: '失敗',
    등록하기: '登録する',
    '파일 업로드 실패했습니다.': 'ファイルのアップロードに失敗しました。',
    '로그인 후 이용해 주세요. 로그인 하시겠습니까?': 'ログイン後ご利用ください。ログインしますか？',
    '크리에이터님의 소식을 기다리고 있습니다. 다양한 소식을 전해 주세요 !':
      'クリエイター様の更新を待っています。<br/>さまざまな情報を共有してください。',
    '글 작성하기': 'テキストを作成する',
    '이미지를 등록해 주세요.': 'イメージを登録する',
    '커뮤니티에 올릴 글을 작성해 주세요.': 'コミュニティーに記載するテキストを作成してください。',
    '더 보기': 'もっと見る',
    댓글: 'コメント',
    상품: '商品',
    커뮤니티: 'コミュニティ',
    '댓글 달기': 'コメントを書く',
    등록: '登録',
    좋아요: 'いいね',
    개: '個',
    수정: '修正',
    삭제: '削除',
    신고: '報告書',
    '불러오기 실패 다시 클릭해 주세요.': '読み込みに失敗しました。もう一度クリックしてください。',
    '내용을 입력해 주세요': '内容を入力してください。',
    '삭제하시겠습니까?': '削除しますか？',
    '크리에이터님의 소식이 아직 없습니다. 곧 다양한 소식을 전해 드릴게요 !':
      'まだクリエイターからの知らせはありません。<br/>もうすぐ様々なニュースをお伝えします。',
    인기글: '人気の文',
    최신글: '最新の文',
    '리뷰 상세': 'レビュー 詳細',
    '갤러리 상세': 'ギャラリー詳細',
    '갤러리 글 수정하기': 'ギャラリー文章を修正する',
    글쓰기: '書き物',
    '커뮤니티 글 등록하기': 'コミュニティの書き込みの登録',
    '커뮤니티 글 수정하기': 'コミュニティの書き込みの修正',
    '이미지 첨부': 'イメージファイルの添付',
    '커뮤니티 상세': 'コミュニティ詳細',
    '팬심 가득 담은 응원 한마디!': 'ファンの気持ちを込めた応援の一言',
    '포토 상세': 'フォトの詳細',
  },

  image_editor: {
    image_editor: 'イメージ編集',
    edit: '編集',
    filter: 'フィルター',
    adjustment: '調整',
    transform: '変形',
    initialize: 'しょきか',
    edit_freely: 'イメージをご自由に編集してください。',
    cancel: '取り消し',
    complete: '完了',
    original: '原本',
    Paris: 'パリ',
    LosAngeles: 'ロサンゼルス',
    Oslo: 'オスロ',
    Lagos: 'ラゴス',
    Melbourne: 'メルボルン',
    Jakarta: 'ジャカルタ',
    AbuDhabi: 'アブダビ',
    SaoPaulo: 'サン・パウロ',
    NewYork: 'ニューヨーク',
    Cairo: 'カイロ',
    Moon: '月',
    brightness: '明るさ',
    contrast: '明暗',
    saturation: '彩度',
    hue: '色合い',
    red_tone: '赤系',
    green_tone: 'グリーン系',
    blue_tone: '青系',
    brown_tone: '茶色系',
    opacity: '透明度',
    definition: '鮮明度',
    technicolor: 'テクニカラー',
    film_camera: 'フィルム',
    polaroid: 'ポラロイド',
    vintage: 'ビンテージ',
    vibrance: '活気',
    vignette: 'ビネット',
    noise: 'ノイズ',
    gamma: 'ガンマ',
    warmth: '暖かさ',
    bright_area: '明るい領域',
    dark_area: '暗い領域',
    invert: '反転',
    texture: 'エンボス',
    size: '擴大/縮小',
    rotate: '回転',
    move_A: '横へ移動',
    move_B: '縦へ移動',
    apply: '適応でよろしいですか？',
    editor_cancel: '取り消しでよろしいですか？',
    vertical: '縦',
    horizontal: '横',
    crop_limit_1: 'イメージ',
    ie_warning:
      'ご利用のブラウザ では「イメージ編集」の機能が制限されます。 円滑なご利用のため、他のブラウザ(クローム、Safari、モバイルブラウザなど）ご使用ください。',
    cmyk_alert:
      'こちらのイメージは CMYKカラーモードで作成されてます。 イメージの編集機では RGBカラーモードで自動的に変換され、色が変わる場合があります。',
    crop_alert:
      'イメージを拡大する場合、イメージの品質が低下され印刷面の色が薄くなり、濃度差が生じる場合がございます。',
    color_change_alert:
      '画面上にアップロードされたイメージは実際にプリントされるカラーと多少誤差があり、フィルターを使う場合、プリント時に画質が荒い状態で印刷される場合がございます。',
  },

  creator_adjustment: {
    crop_limit_2: 'の長さが80PXより小さいので変更の機能が制限されます。',
    release: '出庫',
    change: '変更',
    confiremd: '購買確',
    '정산 날짜': '精算日',
  },

  plus_main: {
    event: '企画展',
    'no-today': 'Todayはまだありません。<br/>すぐに、様々なニュースをお届けしましょう！',
    event_default_title: 'Special Curations Just For You',
    event_default_desc: 'あなただけのスペシャルな企画',
    'Featured Creators': 'Featured Creators',
    '지금 가장 인기 있는 셀러들의 굿즈를 만나보세요.': '今時の人気のあるクリエイターのグッズを紹介します！',
    '셀러샵 전체 보기': 'クリエイターショップ全体を見る',
  },

  dialog_msg: {
    Yes: 'はい',
    No: 'いいえ',
    Ok: 'Ok',
  },

  maker: {
    select_face: {
      tooltip: '👀 他の面も印刷できます！',
    },
    locOpt: {
      name: '位置調節要請',
      guideTitle: '位置調節のご案内',
      guideDescription: '作業したデザインを基準に商品に最も似合う位置に調整いたします。',
      guideWarning: '*デザインの大きさやカラーを修正せず、上記の4つの場合を除いた位置の調整は致しません。',
      guideWarningCheckbox: '位置変更による交換、払い戻し、返品ができかねることを確認し同意します。',
      requestActivation: '位置調節要請',
      requestCancel: '位置調節取り消し',
      resetWarning: '商品変更により位置調節機能が初期化されます。',
      noDesignWarning: '位置調節するデザインがありません。',
      frontLeft: '表面 左側',
      frontRight: '表面 右側',
      frontCenter: '表面 中央',
      backCenter: '裏面 中央',
      close: '閉じる',
    },
    'lets design': "Let's design!",
    '양옆 채우기': '両脇に詰めるボタン',
    icon: {
      Preview: 'Preview',
    },
    'Accepted File Types: JPEG, PNG, AI':
      'アップロード可能なイメージファイルはJPEG 又は、PNG, AIファイルです。',

    'Accepted File Types: JPEG, PNG, AI.':
      'アップロード可能なイメージファイルはJPEG 又は、PNG, AIファイルです。',
    'Accepted File Types: JPEG, PNG, SVG.': 'アップロード可能なイメージファイルはJPEG 又は、PNG, SVG。',
    'Accepted File Types: JPEG, PNG.': 'アップロード可能なイメージファイルはJPEG 又は、PNG。',
    Product: '商品選択',
    Design: 'イメージ追加',
    Character: 'キャラクター追加',
    'This product does not support ai file. Please convert the file into png or jpeg format.':
      'この商品はAIファイルは適用されません。<br/>PNGまたはJPEG変換してアップロードしてください。',
    'Please notice that unsupportive characters are going to be excluded.':
      'Please notice that unsupportive characters are going to be excluded.',
    'This font does not support bold. The font will be changed to the default thickness.':
      '只今、品切れとなっております。<br/>こちらのフォントはボールドが適用されないので、基本的な太さで変更になります。',
    'This size is temporarily out of stock.': '以前、ご注文していただいたサイズは品切れでございます。',
    'The size of the embroidery patch is': 'このワッペンの大きさは',
    'cm.': 'cmです。',
    Photo: 'フォトレビュー',
    Preview: '本体カラー別プレビュー',
    'At least 500px': 'よりきれいな仕上げりにするには<br/>最小500px以上のイメージが必要です。',
    'MAX 8000px': '8,000px以下のイメージに変更してアップロードしてください。',
    file_error: '不明または間違った画像です。<br>画像を修正または再保存してアップロードしてみてください。',
    '100%': '100%',
    'Something went wrong. Please check all the designs.':
      '印刷可能範囲外にデザインが隠れています。ご確認ください。',
    'The font is no longer supported. Please select the other fonts.':
      'このフォントはご利用いただけません。他のフォントを選択してください。',
    Unknown: 'Unknown',
    "The font doesn't support Bold.": 'このフォントは太字に対応していません。',
    Message: {
      undesigned_area_message: `あっ! 設計されていないエリアがあります。😂`,
      there_is_no_design_message: `あっ！デザインがありません。😂`,
      'Fill the image to the dotted line for full-sided printing.':
        'サイドの部分までプリントする場合は点線まで埋めてください。',
      'Place important images inside the dotted line.': '重要なイメージは点線の中の方に配置してください。',
      'Fill the image to the solid line for full-sided printing.':
        'サイドの部分までプリントする場合は点線まで埋めてください。',
      'Please fill in the design to the outer dotted line to full print the image.':
        '全面印刷を求める場合は、イメージを点線の中まで埋めてください。',
      'Please fill in the design to the dotted line for production safety.':
        '制作の安定性のためイメージを点線の中まで埋めてください。',
      'The product image has been changed. Please check out the positions of your designs.':
        'こちらの商品のイメージが変更になりましたので、<br/>デザインをもう一回ご確認お願い致します。',
      'If you dont put an image on the bottom of it, its made in white. Would you like to proceed with it?':
        '底の部分にイメージをアップロードしない場合、白になります。よろしいでしょうか？',
      'If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.':
        '刻みたいイメージの背景を消さない場合四角の背景含めて刻まれれますので、必ず背景消しのイメージをアップロードしてください。',
      'This product is does not allow background remove request.': '背景を消せない商品でございます。',
      'Images with small resolution may reduce print quality. Do you want to change to the optimal size?':
        'イメージの画質が良くないため印刷の品質が低下されます。最適なサイズに変更しますか？',
      go_to_not_designed_fcanvas: `デザインされてない印刷面がございます。ご注文を続けますか？`,
      cancel_fit_width: '画像のサイズ調節が必要な場合は、「両サイドに詰める」オプションを解除してください。',
      it_is_not_product_for_fit_width_function: 'it_is_not_product_for_fit_width_function',
      border_1mm: `製品の特性上、枠1mmは印刷できません。`,
      border_2mm: `製品の特性上、枠2mmは印刷できません。`,
      embro_product: `アップロードした画像のサイズによって別途の追加費用が発生する場合があります。`,
      important_in_green: `デザイン内の重要なイメージは緑色の点線内に配置してください。`,
      '전면 인쇄를 원하시는 경우 반드시 이미지를 점선 끝까지 채워주시기 바랍니다.':
        '全面印刷をご希望の場合は、必ず画像を点線の端まで埋めてください。',
      '로고 주변은 피해서 디자인 해주세요.':
        'ロゴの上にはプリントができませんので、ロゴを避けてデザインしてください。😉',
      '해당 제품의 경우 자수 제작은 불가합니다.': 'この商品は刺繍製作は不可となります。',
      '제품 이미지가 수정 됐습니다. 디자인의 위치와 크기를 확인해주세요.': `The product image has been edited.<br>Please double-check the design's position and size.`,
      '그대로 작업하기': 'そのまま進行する。',
      '수정하러 가기': '修正しに行く。',
      '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주시고/중요 요소는 초록색 점선 내에 배치해 주세요.':
        '全面印刷をご希望の場合はイメージを外側の点線まで埋め込み、<br>デザインの重要要素は緑色の点線内に配置させてください。',
      '이 상품은 뒷면만 인쇄가 가능합니다.': 'この商品は裏面のみ印刷が可能です。',
      '앞면과 뒷면 모두 동일한 색상으로 인쇄돼요.': '前面と背面とも同じ色で印刷されます。',
    },
  },

  line_maker: {
    Change: '変更',
    'Right click is not available.': '右クリックは使用できません。',
  },

  time_unit: {
    days: '日',
    hours: '時',
    minutes: '分',
    seconds: '秒',
  },

  line_create: {
    '의류 커스텀': '衣類カスタム',
    '폰케이스 커스텀': 'フォンケースカスタム',
    '캔버스백 커스텀': 'キャンバスバックカスタム',
    '에어팟케이스 커스텀': 'Airpodケースカスタム',
    '홈데코 커스텀': 'ホームデコカスタム',
    '키즈의류 커스텀': '子供服のカスタム',
  },

  product_detail: {
    Tip: 'カスタムする方法',
    Story: '物語',
    'Product Info': '商品詳細',
    'Print Guide': '製作ガイド',
    Guide: 'ガイド',
    'Size chart': 'サイズ表',
    'Size Compare': 'サイズ別着用写真',
    Reviews: 'レビュー',
    '인쇄 관련 사항': '印刷関連事項',
    '교환 및 환불 안내': '交換と返金案内',
    '자주 묻는 질문': 'よくあるご質問',
    '배송 및 환불': '配送と払い戻し',
    배송안내: '配送案内',
    'FAQ 전체 보러가기': 'すべてのFAQ へ',
    '배송 FAQ 보러가기': '配送FAQを見に行く',
    '교환/환불 FAQ 보러가기': '交換/返品FAQを見に行く',
    '1:1 문의하기': '１:１お問い合わせ',
    '일대일 문의하기': 'お問い合わせフォーム',
    precautions: '購入時の注意事項',
    precautions_info:
      '電子商取引等における消費者保護に関する法律に基づき、未成年者が商品を購入する場合、法定代理人が購入に同意しない場合、未成年者本人または法定代理人がその購入を取り消しができます。',
    '셀러 배송 상품': 'クリエイター配送商品',
    '해당 상품은 셀러가 직접 배송하는 상품입니다. 구매 안내를 확인해주세요.':
      'その商品は出品者様ご自身で配送する商品です。購入案内をご確認ください。',
    '브랜드 배송 상품': 'クリエイター配送商品',
    '해당 상품은 브랜드에서 직접 배송하는 상품입니다. 구매 안내를 확인해주세요.':
      'その商品は出品者様ご自身で配送する商品です。購入案内をご確認ください。',
    '오류가 발생했습니다.<br>새로고침 됩니다.': '製品情報が正しくありません。<br>ページがリロードされます。',
    '상품 기본 정보': '商品基本情報',
    'KC 및 기타 인증': 'KC及びその他の認証',
    인증번호확인: '認証番号の確認',
    '구매전 확인': '購入前に安全認証情報を必ずご確認ください。',
    '인증 책임 판매자': 'この内容は販売者が直接登録したものであり、その情報に対する責任は販売者にあります。',
    '수량은 1개 이상이어야 합니다.': '数量は1つ以上でなければなりません。',
    '옵션은 필수로 선택하셔야해요.': 'オプションは必須として選択する必要があります。',
    '옵션으로 선택된 상품이 더 이상 존재하지 않습니다.': 'オプションで選択された商品はもう存在しません。',
    no_photo_reviews: 'この商品の最初のフォトレビューを書いてください。',
  },

  pattern: {
    'Pattern-Size': 'サイズ',
    'Pattern-Type': 'パターン',
    Copies: 'コピー',
    Type: 'タイプ',
    Repeat: '正方形',
    Lattice1: '斜め１',
    Lattice2: '斜め２',
    Lattice3: '斜め3',
    'Pattern-Move': '位置変更',
  },

  pattern_mobile: {
    'Pattern-Size': 'サイズ',
    'Pattern-Type': 'パターン',
    Copies: 'コピー',
    Type: 'タイプ',
    Repeat: '正方形',
    Lattice1: '斜め１',
    Lattice2: '斜め２',
    Lattice3: '斜め3',
    'Pattern-Move': '位置変更',
  },

  color: {
    Add: '商品追加',
    Done: '確認',
    'No colors are available to add': '追加できるカラーがありません。',
  },

  sticker: {
    'Are you sure to delete this?': 'イメージを削除しますか？',
    'Delete failed. Please try again.': 'イメージ削除失敗。もう一度行ってください。',
    'Delete Success.': 'イメージが削除されました。',
  },

  group: {
    Group: '団体',
    'All Group': '団体全体を見る',
  },

  payment_done: {
    'Payment Successful': '注文を承りました。',
    'View Orders': '購入内容確認',
    'Continue Shopping': 'ショッピングを続ける',
  },

  mpay: {
    '결제 안내': '결제 안내',
    copy_link: '링크복사',
    url_copied: 'URL이 클립보드에 복사되었습니다.',
    url_copied2: '주소가 복사되었습니다. 원하는 곳에 붙여넣기 해주세요.',
    url_failed: 'URL 복사에 실패했습니다.',
    wrong_url: '잘못된 주소 입니다.',
  },

  home: {
    Home: 'HOME',
    Reviews: 'レビュー',
    Goods: `Today's Goods`,
    'Make Your Own': 'オリジナルカスタム商品',
    'Quick and easy start': '簡単作成',
    'Complete within 3 minutes': '別途プログラムは必要ありません。',
    'with no installation of any programs': '簡単ツールを使って3分で完成',
    'Meticulously crafted products': "こだわりの<span class='mobile_v'></span>商品制作",
    'Guarantee the highest quality': 'プリントのノウハウで',
    'in the industry': '最高品質を保証',
    'Free design template': "デザイン<span class='mobile_v'></span>無料提供",
    'Enjoy the best collection of': 'デザインのアイディアを提供する',
    'art and fonts on the web': 'MARPPLEだけの無料テンプレート',
  },

  ad_res: {
    '지금 마플샵 사전예약 신청하세요 !': '今Marpple Shopの事前受付に申請してください！',
    '마플샵의 첫번째 셀러가 되어주세요': `Marpple Shopの最初の&nbsp;<span class='w_br m_br'></span>クリエイターになってください`,
    '마플샵의 두번째 셀러가 되어주세요':
      'Marpple Shopの&nbsp;<span class="w_br m_br"></span>2期クリエイターになってください',
    '2020년 1월, 크리에이터 커머스 플랫폼 마플샵이 오픈합니다.':
      '2020年1月、クリエイターコマースプラットフォーム、Marpple Shopがオープンします。',
    '2020년 1월 28일 ~ 2020년 2월 20일 크리에이터 커머스 플랫폼 마플샵의 2기 셀러를 모집합니다.':
      '2020年1月28日～2020年2月20日、クリエイターコマースプラットフォーム、Marpple Shopの2期クリエイターを募集します。',
    '첫번째 셀러로 당첨되시는 분들에게는 상품제작 및 촬영 서비스등 다양한 혜택을 제공해 드립니다.':
      '最初のクリエイターに当選した方には、商品製作および撮影サービスなど、様々な特典を提供します。',
    '2기 셀러로 선정되시는 분들에게는 상품 제작 및 촬영 서비스 등 다양한 혜택을 제공해 드립니다.':
      '2期クリエイターに当選した方には、商品製作および撮影サービスなど、様々な特典を提供します。',
    '아래 간단한 질문에 대한 답변을 작성해주시면 검토 후 개별 연락 드릴 예정입니다.':
      '以下の簡単な質問への回答を作成していただければ、検討後に個別連絡をする予定です。',

    '지금 신청하기 !': '지금 신청하기 !',
    '원하는 마플샵 웹 도메인명을 써주세요(영문/숫자 20자 이내)': `希望するMarpple Shopのウェブドメイン名を記載してください<span class='m_br'></span>（英数字2字以上20字以内）`,
    '당첨 결과 통지를 위해 이메일 주소를 입력해 주세요': `当選結果通知のために<span class='m_br'></span>メールアドレスを入力してください。`,
    '당첨되시면 연락드릴 본인의 연락처를 입력해 주세요': `当選後に連絡する本人の<span class='m_br'></span>連絡先を入力してください<span class='m_br'></span>（選択）`,
    '판매하게 될 콘텐츠와 관련하여 마플샵 입점심사과정에서 참고할 수 있는 자료나 웹사이트를 알려주세요': `販売することになるコンテンツに関連して、<span class='m_br'></span>Marpple Shopの入店審査過程で参考にできる資料やウェブサイトを入力してください<span class='m_br'></span>（選択）`,
    '판매하게 될 콘텐츠 또는 디자인과 관련하여 운영하고 계신 SNS 주소를 알려주세요': `販売することになるコンテンツまたはデザインに関連して、<span class='m_br'></span>運営しているSNSのアドレスを入力してください<span class='m_br'></span>（選択）`,
    '마지막이에요! 본인 또는 브랜드를 간략하게 소개해 주세요 (200자 이하)': `最後です！本人またはブランドを簡潔に紹介してください<span class='m_br'></span>（200字以内）`,
    '마플샵 셀러 신청이 완료되었습니다': `Marpple Shopクリエイター申請が<span class='m_br'></span>完了しました`,
    '저희와 함께 하고 싶으신가요?': `私達と一<span class='m_br'></span>緒にしたいですか？`,
    '기업 파트너쉽 문의는':
      '企業パートナーシップのお問い合わせは、&nbsp;<span class="w_br m_br"></span><a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>に連絡してください',
    '영문/숫자 20자 이내로 입력해 주세요': '英数字2字以上20字以内で入力してください。',
    '문자/숫자 20자 이내로 입력해 주세요': '文字／数字2字以上20字以内で入力してください。',
    'sample@sample.com': 'sample@sample.com',
    '엔터키를 누르면 다음으로 진행됩니다': 'エンターキーを押すと、次に進みます',
    '버튼을 누르면 다음으로 진행됩니다': 'ボタンを押すと、次に進みます',
    '010-0000-0000': '010-0000-0000',

    '올바른 연락처 형식을 입력해 주세요': '正しい連絡先形式を入力してください。',
    '올바른 URL 형식을 입력해 주세요': '正しいURL形式を入力してください。',
    '200자 이하로 입력해 주세요': '200字以内で入力してください。',
    '닉네임 또는 브랜드 명을 입력하세요.': 'ニックネームまたはブランド名を入力してください。',
    '브랜드 명을 입력하세요.': 'ブランド名を入力してください。',
    '도메인 명을 입력하세요.': 'ドメイン名を入力してください。',
    '간략하게 소개해 주세요': '簡潔に紹介してください。',
    '참고 할 수 있는 사이트를 입력해 주세요.': '参考可能なサイトを入力してください。',
    '버튼을 누르시면 사전예약이 완료됩니다 !': 'ボタンを押すと事前予約が完了します！',
    '사전신청이 완료되지 않았습니다.': `クリエイター申請が完了していません。再び行うか、<br/><a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>にお問い合わせください。`,
    '사전 신청은 PC에서 해주세요.': '事前申請はPCで行ってください。',
    '유효한 도메인을 입력하세요.': '有効なドメインを入力してください。',
    '유효한 브랜드 명을 입력하세요.': '有効なブランド名を入力してください。',
    '이미 사용중인 도메인입니다.': '既に使用中のドメインです。',
    '담당자가 제휴제안 내용을 검토하는 과정에서 추가 자료를 요청하거나 재문의할 수 있습니다. 제안 내용 및 관련자료는 제휴 검토 목적으로만 이용됩니다.': `担当者が提携提案内容を検討する過程で、追加資料を要請するか、再度問い合わせすることがあります。提案内容および関連資料は、提携検討の目的でのみ利用されます。`,
    '30일 이내에 제안자가 담당자의 자료요청 또는 재문의에 회신을 하지 않는 경우 해당 제휴제안은 종료 처리됩니다.': `30日以内に提案者が担当者の資料請求または再問い合わせに返信をしない場合、当該提携提案は、終了処理されます。`,
    삭제: '削除',
    '보내주실 디자인, 포트폴리오 등이 있다면 첨부해 주세요.':
      'お送りいただけるデザイン、ポートフォリオなどがある場合は、添付してください。',
    '업로드 중...': 'アップロード中…',
    '개인정보 수집’이용 동의에 관한 안내사항': '個人情報の収集・利用同意に関する案内事項',
    '- 수집’이용기관명:(주)마플코퍼레이션': `
  <p>- 収集・利用機関名:(株)Marpple Corporation</p>
  <p>- 収集項目：（必須項目）氏名、連絡先、メール</p>
  <p>- 収集・利用項目：Marpple Shopクリエイター申請およびサービス提供</p>
  <p>- 保有および利用期間：クリエイター申請問い合わせ日から1年</p>
`,
    '*개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 셀러접수 안내가 불가능 합니다.':
      '＊個人情報提供者は、個人情報の収集および利用に関する同意を拒否する権利があり、そのような場合、クリエイター受付案内ができません。',
    '상기와 같이 개인정보의 수집 및 이용에 관하여 동의합니다.':
      '上記のような個人情報の収集および利用に関して同意します。',
    '개인정보 수집 이용에 동의해 주세요': '個人情報の収集および利用に同意してください。',
    참고사항: '参考事項',
    '- 판매하는 콘텐츠의': `
  <p>- 販売するコンテンツの著作権は、販売主体であるクリエイターにあり、事前受付申請時、著作権所有者の確認を認証したものとみなされます。<p>
  <p>- 著作権など他人の権利を侵害するか、名誉を毀損する画像、デザインなどの掲示に対する責任は、クリエイターにあります。<p>
  <p>- 提供された個人情報は、個人情報提供者が同意した内容以外の目的で活用せず、保有期間内に提供された個人情報の利用を拒否しようとする際、自由に個人情報管理責任者（電子メール：&nbsp;<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>、顧客センター&nbsp;<a href='tel:1566-5496'>1566-5496</a>）を通じて閲覧、訂正、削除を要求することができます。<p>
`,
    '파일 업로드 중입니다. 업로드 후에 다시 시도해 주세요.':
      'ファイルをアップロード中です。アップロード後に再び行ってください。',
    '당신만의 특별한 마플샵을 런칭해볼까요?': 'あなただけの特別なMarpple Shopを立ち上げてみませんか？',
    필수입력: '必須入力',
    브랜드명: 'ブランド名',
    BRAND: 'BRAND',
    '영문/한글/숫자 조합하여 2자 이상 20자 이내로 입력해주세요.':
      '英字／ハングル／数字を組み合わせて2字以上20字以内',
    '영문/숫자 조합하여 2자 이상 20자 이내로 추후 마이페이지에서 수정이 가능합니다.':
      '英字／数字を組み合わせて2字以上20字以内。今後マイページで変更可能です。',
    도메인명: 'ドメイン名',
    '우리에게 당신의 숨겨져있는 가능성을 보여주세요!': '私達にあなたの隠された可能性を見せてください！',
    '1개 이상 필수 입력': '1つ以上必須入力',
    '운영중인 SNS': '運営中のSNS',
    '파일 업로드': 'ファイルのアップロード',
    '포트폴리오 첨부': 'ポートフォリオ添付',
    '첨부파일은 jpg':
      '添付ファイルは、jpg、png、ppt、keynote、pdf形式で50MB以下のみ可能（最大3つのアップロード可能）',
    '심사에서 참고할 사이트': '審査で参考にするサイト',
    이메일: 'メール',
    '이메일 입력': 'メール入力',
    휴대전화번호: '携帯電話番号',
    '휴대전화번호(-없이 숫자만 입력)': '携帯電話番号（- 無しで数字のみ入力）',
    '마지막으로 본인이나 브랜드에 대해 깊은 인상을 남겨주세요!':
      '最後に、本人やブランドについて深い印象を残してください！',
    '본인 또는 브랜드 소개': '本人またはブランド紹介',
    '개인정보 수집•이용 동의에 관한 안내사항을 확인해보세요.':
      '個人情報の収集・利用同意に関する案内事項を確認してください。',
    '전체 항목에 동의합니다.': '全体項目に同意します。',
    '개인정보의 수집 및 이용에 관하여 동의합니다.': '個人情報の収集および利用に関して同意します。',
    '크리에이터로 선정되면 어디로 연락 드릴까요?': 'クリエイターに選定されたらどこに連絡しますか？',
    본문보기: '本文を見る',
    '만 14세 이상입니다.': '満14歳以上です。',
    '꼭 읽어주세요!': '必ず読んでください！',
    '판매하는 콘텐츠의 저작권은':
      '販売するコンテンツの著作権は、販売主体であるクリエイターにあり、事前受付申請時、著作権所有者の確認を認証したものとみなされます。',
    '저작권 등 타인의 권리를':
      '著作権など他人の権利を侵害するか、名誉を毀損する画像、デザインなどの掲示に対する責任は、クリエイターにあります。',
    '제공된 개인정보는': `提供された個人情報は、個人情報提供者が同意した内容以外の目的で活用せず、保有期間内に提供された個人情報の利用を拒否しようとする際、自由に個人情報管理責任者（partner@marppleshop.com）、顧客センター（1566-5496）を通じて閲覧、訂正、削除を要求することができます。`,
    '신청 완료하기': '申請完了する',
    '개인정보 수집 및 이용에 관한 동의': '個人情報の収集および利用に関する同意',
    '수집•이용기관명 : (주)마플코퍼레이션':
      '収集・利用機関名：（株）Marpplee Corporation、収集・利用機関名：（株）Marpplee Corporation',
    '- 수집항목 : (필수항목) 성명, 연락처, 이메일': '－収集項目：（必須項目）氏名、連絡先、メール',
    '- 수집•이용목적 : 마플샵 셀러 신청 및 서비스 제공':
      '－収集・利用項目：Marpple Shopクリエイター申請およびサービス提供',
    '- 보유 및 이용기간 : 셀러 신청 문의 일로부터 1년':
      '－保有および利用期間：クリエイター申請問い合わせ日から1年',
    '* 개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 셀러접수 안내가 불가능 합니다.':
      '＊個人情報提供者は、個人情報の収集および利用に関する同意を拒否する権利があり、そのような場合、クリエイター受付案内ができません。',
    '최대 3개의 파일만 선택 가능합니다.': '最大3つのファイルのみ選択可能です。',
    '파일 용량이 너무 큽니다.': 'ファイル容量が大きすぎます。<br/>50MB以下でアップロードしてください。',
    '파일업로드가 실패했습니다.':
      'ファイルアップロードに失敗しました。<br/>再び行うか、<a href="mailto:partner@marppleshop.com">partner@marppleshop.com.</a><br/>にお問い合わせください。',
    '올바른 이메일 형식을 입력해 주세요': '正しいメール形式を入力してください。',
    '중복된 이메일입니다': '重複したメールです。',
    '만 14세 이상 동의해 주세요.': '満14歳以上に同意してください。',
    '셀러 신청 완료': 'クリエイター申請完了',
    '마플샵 생성 링크가 이메일로 전송되었습니다.': 'Marpple Shop生成リンクがメールで送信されました。',
    '마플샵 메인 가기': 'Marpple Shopのメインに行く',
    '심사에 참고할 자료가 부족하거나':
      '審査において参考とする資料が不足しているか、不適切な素材の内容が含まれる場合、申請が拒否されることがある点、予めご了承下さい。',
    '기업 파트너십 문의': `企業パートナーシップのお問い合わせ <a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>`,
    '셀러신청이 완료되지 않았습니다.':
      "クリエイター申請が完了していません。<br/>再び行うか、<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>にお問い合わせください。",
    '같은 이메일로 이미 신청되었습니다.':
      "同じメールが既に申請されています。メールが来ない場合、迷惑メールフォルダを確認してください。<br/>それでも見当たらない場合、<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>にお問い合わせください。",
    '마플샵 생성 링크를 전송했습니다.': '{{email}}にMarpple Shop生成リンクを送信しました。',
    '전송된 링크를 클릭하여 나만의 마플샵을 만들고 지금 바로 상품도 만들어보세요.':
      '送信されたリンクをクリックして、本人だけのMarpple Shopを作成し、今すぐ商品も作ってみましょう。',
    '2자 이상 20자 이내로 입력해 주세요': '2字以上20字以内で入力してください。',
    '메일이 오지 않았다면 스팸함을 확인해 주시고, 그래도 없다면 cs@marppleshop.com 으로 문의해 주세요.':
      'メールが来ない場合、迷惑メールフォルダを確認し、それでも見当たらない場合、<a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a>にお問い合わせください。',
    '유튜브, 인스타그램, 트위터 URL 등': 'YouTube, Instagram, Twitter URL, etc...',
    '개인 웹사이트, 노트폴리오, 비핸스 URL 등': 'Your websites, Behance URL, etc...',
  },

  shop_home: {
    'Go to Sign in': 'サインインしてください',
    'card1-maintitle': 'ようこそ MarppleShop!',
    'card2-maintitle': `3分でかんたん完成, あなただけのSHOP`,
    'card3-maintitle': `ARE YOU クリエイター？ アーティスト？`,
    'card4-maintitle': `販売からマーケティングまで`,
    'card5-maintitle': `商品制作、配送、顧客対応まですべておまかせ`,
    'Set the price for this t-shirt': `Tシャツの販売価格を <br /> 設定してください`,
    "Here's how much you'll get!": `商品が購入された際に <br /> 得られる収入です。`,
    'card1-mobile1':
      'https://s3.marpple.co/files/u_1165777/2021/10/original/702cb92087e58bf86cf69deaea872d70e753ab411.png',
    'card1-title1':
      'https://s3.marpple.co/files/u_1165777/2021/6/original/891fc2f1ccf149d415dc9a289d2777603a43a30d2.png',
    'card1-detail1':
      '自分だけのショップ？ <br /> グッズを作って販売できるの？ <br /><br /> これからは直接作らなくても <br /> 自分でデザインしたグッズを販売できます。 <br />  <br /> クリエイター、インフルエンサー、アーティスト <span class="d_br"></span> ブレイク中のあなたのために <br /> <br /> 商品制作、配送、顧客対応、在庫管理まで&nbsp <span class="d_br"></span> MARPPLE SHOPがお手伝いします。',
    'card1-title2': '<p> 3分で </p><p> かんたん完成 </p>',
    'card1-detail2':
      '3分だけで誰でもかんたんに作成可能！ <br /> <br /> 600個の商品に <br/> あなたのデザインを組み合わせて <br /> <br /> 多種多彩なアイテムを販売できます！',
    'card1-title3': '<p> 価格も思いのままに </p>',
    'card1-detail3':
      '「今日はいくら売れたかな？」 <br />  「今度はどんな商品を作ろう？」 <br/> </br/> 価格も思いのまま <br/> 収入も思いのまま <br /> すべてあなたの思いのままに！',
    'card1-title4': '<p> あなたのチャンネルでも </p>',
    'card1-detail4':
      'あなたのファンに <br /> 作った商品を紹介しよう。 <br /> <br /> あなただけの○○SHOPを <br /> YouTube、Facebook、Instagramに <br /> 連動することができます。',
    'card1-youtube': `YouTubeチャンネルで <br /> グッズ販売の告知ができます。`,
    'card1-instagram': `Instagramのフィードに <br/> 商品タグを設定するだけで <br /> あなたのグッズを販売できます。`,
    'card1-facebook': `Facebookのビジネスページと <br /> 連動できます。`,
    'card1-title5': '<p> すべておまかせ </p>',
    'card1-detail5': `アイディアさえあればOK！ <br /> 注文から在庫管理、顧客対応、配送まで <br /> すべてMARPPLE SHOPにおまかせ！`,
    'card1-step1': `販売開始`,
    'card1-step1-detail': `おめでとうございます！注文が入ってきました！`,
    'card1-step2': `制作 & 配送`,
    'card1-step2-detail': `私たちが責任を持って制作し全国・全世界に配送いたします。`,
    'card1-step3': `売上発生`,
    'card1-step3-detail': `商品が売れるたびに利益もプラスに！`,
    'card2-title1': `<p> 3分でかんたん </p> <p> あなただけの </p> <p> ○○SHOP </p>`,
    'card2-detail1': `未だに商品を制作し直接販売していますか？ <br /> <br /> MARPPLE SHOPでは <br /> 商品を選んでデザインし <br /> 価格を設定するだけ！`,
    'card2-title2': '商品を選ぶ',
    'card2-detail2': `衣類、スマホケース、帽子、ブランケット、カバンなどなど <br /> 600種類の多様な品揃え`,
    'card2-design1': `イメージを追加`,
    'card2-design1-detail': `あなたのデザインを好きな位置にレイアウト！`,
    'card2-design2': `商品のカラー変更`,
    'card2-design2-detail': `商品ごと様々なカラーが選べます。`,
    'card2-design3': `お気に入りの文字を追加`,
    'card2-design3-detail': `アイディア次第で様々なデザインが作れます！`,
    'card2-title3': '販売価格を設定',
    'card2-detail3': `商品価格もあなた次第。 <br /> デザインも価格も思いのままに！`,
    'card2-title4': 'あなただけの <br/> ○○SHOPの完成！',
    'card2-detail4': `新感覚オンラインショッピングサイト！ <br/> 制作NO！在庫NO！運営NO! <br /> 商品制作から在庫管理、運営、顧客管理まですべておまかせ！`,
    'card3-title1': `<p> ARE YOU </p> <p> クリエイター？ </p> <p> アーティスト？ </p>`,
    'card3-detail1': ` MARPPLE SHOPは クリエイターのグッズ&nbsp <span class='d_br'></span> すべてのアーティスト作品を <span class='d_br'></span> 人々の日常に <span class='d_br'></span> お届けします。 <br /> <br /> MARPPLE SHOPでは <br /> あなたが書いた言葉 <br/> 撮った写真、描いた絵 <br /> すべてのアイディアが <span class='d_br'></span> 商品となります。`,
    'card3-title2': `<p> クリエイターの </p> <p> グッズ販売 </p>`,
    'card3-detail2': `MARPPLE SHOPは クリエイターの <br /> グッズ販売のためのサイトです。 <br /> <br /> 商品を作り <br/> SNSにアップするだけ。 <br /> <br /> 商品の制作から配送、顧客対応まで <br /> MARPPLE SHOPにお任せください。`,
    'card3-design1': `MARPPLE <br/> SHOPで <br /> グッズ制作`,
    'card3-design1-detail': `画像、テキストを入れるだけ！ <br /> たった3分でグッズ完成`,
    'card3-design2': `チャンネルで <br /> グッズ販売を <br /> お知らせ`,
    'card3-design2-detail': `YouTube、Twitter、Instagram、Facebookなど あなたのグッズを 様々なチャンネルに連動し <br/> 販売することができます。`,
    'card3-design3': `かんたんに <br /> 売上UP！`,
    'card3-design3-detail': `注文確認、在庫管理、顧客対応 <br /> 配送まですべておまかせ`,
    'card3-title3': `<p> すべてのアーティストの </p><p> 成功を応援します </p>`,
    'card3-detail3': `あなたの作品が <br /> たくさんの人々と <br /> 素敵な時間を <br /> 共にします。 <br /> <br /> 世界中の人々が <br /> あなたの作品を <br /> 待っています。`,
    'card3-design4': `あなたの <br /> 作品を準備`,
    'card3-design4-detail': `画像、写真など <br /> 様々な作品をアップロードして <br/> 作ってみよう！`,
    'card3-design5': `MARPPLE <br/> SHOPで <br /> 商品を選択`,
    'card3-design5-detail': `600種類から <br /> あなたの作品にぴったりな <br /> 商品を選べます。`,
    'card3-design6': `あなたの <br/> 作品が日常に`,
    'card3-design6-detail': `あなたの作品が <br /> 人々の日常で、 <br /> 素敵な時間を共にします。`,
    'card3-title4': `<p> あなたも </p><p> ショップオーナーに </p>`,
    'card3-detail4': `誰もがアイディアを販売できます。 <br /> <br /> あなたが書いた言葉 <br /> 撮った写真、描いた絵 <br/> あなたのすべてのアイディアが商品になります。 <br /> <br /> いいアイディアが浮かんだら <br /> 今すぐMARPPLE SHOPを始めてみよう！`,
    'card4-title1': `<p> 販売から </p> <p> マーケティングまで </p>`,
    'card4-detail1': `MARPPLE SHOPはショップ運営のため <span class='d_br'></span> 様々な機能の提供や販売のお手伝いをしています。 <br /> <br /> 今すぐMARPPLE SHOPで <br /> ネットショップを運営してみよう。`,
    'card4-title2': `<p> ショップデザインも </p><p> かんたん </p>`,
    'card4-detail2': `多種多様な機能で <br /> ショップをデザインしよう。 <br /> <br /> PC版、モバイル版も <br /> かんたん一括設定!`,
    'card4-title3': `<p> 統計管理 </p><p> サービス </p>`,
    'card4-detail3': `リアルタイムで販売状況を <br /> 確認できます。 <br /> <br /> 売上、収入、訪問者数など <br /> 販売状況がわかる情報をご提供します。`,
    tshirt: 'Tシャツ',
    cushion: 'クッション',
    cup: 'マグカップ',
    rank1: '1位',
    rank2: '2位',
    rank3: '3位',
    count: '個',
    'Cumulated Product Sales Number': '累積販売個数',
    'Cumulated Sales Amount': '累積販売金額',
    'Sales Status': '売上実績',
    'card4-title4': `<p> 様々なチャンネルと </p><p> かんたん連動 </p>`,
    'card4-detail4': `あなたのチャンネルに <br /> あなたが作った商品を知らせることで <br /> <br /> よりたくさんのファンや訪問者を <br /> あなたのショップに招待できます。`,
    'card5-title1': `<p> 商品制作 – 配送 </p><p> - 顧客対応まで </p><p> すべておまかせ </p>`,
    'card5-detail1': `注文受付、決済、商品制作、配送、 <br /> 在庫管理、顧客対応まで <br /> すべてお手伝いします。 <br/> <br/> 初期費用や在庫負担の <br/> 心配はいりません。 `,
    'card5-shop1': `<p> あなたのショップで </p><p> 商品販売 </p>`,
    'card5-shop1-detail': `お客様が商品を注文すると <br /> MARPPLE SHOPが <br/> 注文確認および生産を行います。`,
    'card5-shop2': `<p> お客様のお手元に </p> <p> 安心配送 </p>`,
    'card5-shop2-detail': `商品生産が完了するとMARPPLE SHOPが <br /> お客様のもとにお届けします。`,
    'card5-shop3': `<p> 販売収入が </p> <p> あなたの手元に </p>`,
    'card5-shop3-detail': `収入は登録した <br /> 通帳に入金されます。`,
    'card5-shop4': `<p> カスタマーセンターの </p> <p> 運営まで </p>`,
    'card5-shop4-detail': `商品についてのお問い合わせ、返品交換案内など <br /> ネットショップ運営に必要な業務をお手伝いします。`,
    'card5-title2': `<p> 12年間の </p><p> 商品制作ノウハウ </p>`,
    'card5-detail2': `デジタルプリント、シルクスクリーン、UVプリント、 <span class='d_br'></span> 生地捺染、刺繍、転写など最先端のプリント技術を基に <br /> 最高の商品をお作りいたします。 <br /> <br /> 国内最高の Print-On-Demand（注文制作プリント）技術、 <br /> 生産運営システムで最高の品質を保証します。`,
    'footer-customer': `営業時間: 平日午前10時～午後6時`,
    'footer-info': `株式会社 Marpple Corp. 代表取締役 Hyeyun Park <br/> <span class='m_br'></span>
      事業者登録番号 <span is_mobile='false'> ： </span> <span is_mobile='true'> <br /> </span> 105-88-13322 <span class='m_br'></span>
      ${CreatorAddressConstantS.MPS_ADDRESS_JP} <br /> カスタマーサービス ： 1566-5496 <span is_mobile='true'> <br /> </span>&nbsp;FAX ： 02-6486-9437`,
    'copyright-info': `Copyright © ${new Date().getFullYear()} Marpple Corp. All rights reserved.`,
    'Customer Center': 'お客様センター',
    '1to1chat': '1対1のチャット',
    'Go to Customer Service': 'サポートショートカット',
  },

  crew_level: {
    master: '主人',
    accounter: '会計士',
    analyzer: 'アナリスト',
    maker: '商品メーカー',
    seller: 'Seller',
    designer: 'Designer',
    manager: 'Manager',
  },

  crew_admin: {
    디자인: 'デザイ',
    매출: '売上',
  },

  marppleshop_action: {
    read_orders: '注文を読む',
    read_sales_amount: '販売額を読む',
    read_adjustments: '読み取り調整',
    delete_manager: '削除マネージャー',
    delete_store: 'ストアを削除する',
    create_store: '店を作る',
    update_store_seller: 'ストア販売者を更新',
    update_store_name: '店の名前の変更',
    create_manager: 'マネージャーを作成',
    read_bank_account: '銀行口座を読む',
    read_sales_analytics: '販売分析を読む',
    update_manager_level: '更新マネージャーレベル',
    request_withdraw: '出金依頼',
    cancel_withdraw: '出金キャンセル',
    approve_withdraw: '出金承認',
    read_withdraws: '出金内訳照会',
    make_product: '商品製作',
    open_pb_private_info: 'PBバイヤー個人情報の閲覧',
    access_all_studio: 'すべてのお店のアクセスが',
    access_crew_admin: 'クルーの管理ページにアクセス',
    update_product_confirm: '商品検収',
    make_pb_product: 'プライベートブランド製品を作る',
    update_marppleshop_marketing_confirm: 'マプルシャプマーケティング露出確認',
    update_marppleshop_marketing_thumbnail: 'マプルシャプマーケティングサムネイル修正',
    read_marppleshop_analytics: 'マープルショップ分析',
    delete_product: '商品の削除',
    prohibit_product: '商品販売保留',
    update_suspend_store: 'ストア運営停止',
    confirm_designer_requests: 'デザイナー申請承認',
    read_pb_projections: 'PB注文書注文書照会',
    update_crew_store_labels: '店のラベルの変更',
    update_want_withdrawal: '販売承認可否の修正',
    update_store_can_selling: '退会希望かどうか修正',
    update_is_brand_home: 'メイン商品の露出を修正',
    manage_site: 'サイト管理',
  },

  crew_menu: {
    store_management: 'クリエイター管理',
    inactive_seller_list: '非運営クリエイター管理',
    disapproval_store_list: '未承認ストア',
    unregistered_store_list: '未登録ストア',
    rejected_store_list: '不合格ストア',
    invited_store_list: '招待されたクリエイター',
    manager_management: 'マネージャー管理',
    seller_adjustment: 'クリエイター出金',
    store_profit: 'Design商品販売現況',
    store_seller_profit: 'クリエイター商品販売現況',
    store_tpl_profit: '입고 상품 판매 현황',
    store_dtg_profit: '디지털 상품 판매 현황',
    confirmations: '商品検収',
    pb_projections: 'PB商品注文書',
    pb_projection_detail: 'PB商品注文書の詳細を見る',
    pb_products: 'PB商品リスト',
    designers: 'デザイナー',
    products: '全体商品',
    make_archive_admin: '製品クォリティ―管理',
    product_check: '商品デザイン検収',
    feeds: 'クリエイターチップ管理',
    solution: '申告受付',
    crew_withdraw: '企業出金',
    crew_bank_account: '企業口座登録',
    crew_adjustment: '企業精算',
    crew_youtube: 'ユーチューブ販売クリエイター',
    website: 'サイト管理',
  },

  signup: {
    '이용약관 동의': '利用規約同意',
    보기: '確認',
    '개인정보수집 이용 동의': 'プライバシーポリシー同意',
    '만 14세 이상입니다.': '満14歳以上です。',
    '만14세 이상 가입이 가능합니다.': '満14歳以上の会員登録が可能です。',
    '개인정보수집 이용에 동의해 주세요.': 'プライバシーポリシーに同意してください。',
    'Email News': '(選択)Emailにてお知らせを受ける。',
  },

  login: {
    Email: 'メールアドレス',
    'Your Name': 'お名前',
    Email_placeholder: 'メールアドレス',
    'Reset your password': 'パスワード再設定',
    'Forgot Password?': 'ID / パスワードを探す',
    'Reset password': 'パスワード変更用のメールを受け取る',
    'Enter your email address and we will send you a link to reset your password.':
      'メールアドレスを入力すれば、パスワード再設定専用リンクをお送りします。',
    'Your email address is verified.': 'ご利用ありがとうございます！<br/>メール認証が完了しました。',
    'Your email address is not verified.': 'メール認証がまだ完了していません。',
    'Your email address is not verified2': 'メール認証がまだ完了していません。<br/>認証しますか？',
    check_agree: '利用規約に同意してください。',
    'Change password': 'パスワード変更',
    '비밀번호 10 ~ 64자': '10~64文字',
    'Success change password': 'パスワードが変更されました',
    'Please verify your e-mail address': 'メール認証が必要です。',
    'Please click on the verification link you sent to':
      '認証するボタンをクリックして認証を行ってください。<br/>',
    'Resend verification email': '認証メールをもう一度送る',
    'Change Email Address': 'メールアドレス変更',
    'Current Email Address': '現在使用中のメールアドレスです。',
    'We ll send an order confirmation to this address.':
      'このメールアドレスにご注文確認書をお送りいたします。',
    'Please verify your current e-mail address.': '現在使用中のメールアドレスをご確認ください。',
    'New Email Address': '新しいメールアドレスを入力してください。',
    Password: 'パスワード',
    'Save Changes': 'メールアドレス変更',
    'Enter your new email': '新しいメールアドレスを入力してください。',
    'Enter your password': 'パスワードを入力してください。',
    'Check your email again': 'このメールアドレスは既に使用されています。もう一度ご確認ください。',
    'There was a problem<br/>Your password is incorrect': 'パスワードに誤りがあります。',
    'Email address already in use': '既に使用されているメールアドレスです。',
    'There was a problem<br/>Please try Again':
      'メールアドレス変更に失敗しました。<br/>もう一度入力してください。',
    'Check your email.':
      'メールアドレスをご確認ください。<br/> 数分以内に確認メールが届かなかった場合は、迷惑メールフォルダをご確認ください。',
    'failed please retry': 'リクエストに失敗しました。もう一度お試しください。',
    Back: '戻る',
    'login failed': 'ログイン失敗',
    'sign up failed': '会員登録に失敗しました',
    '비밀번호는 10자 이상 최대한 길게 작성해주세요.':
      'パスワードは10文字以上で、できるだけ長く作成してください。',
    '비밀번호에 동일한 문자를 과도하게 연속해서 사용할 수 없습니다.':
      'パスワードに同じ文字を過度に連続して使用することはできません。',
    '비밀번호에 ID를 포함할 수 없습니다.': 'パスワードにIDを含めることはできません。',
    '비밀번호에 특수문자는 !@#$%^*+=-만 사용 가능합니다.':
      'パスワードに特殊文字は！@#$%^*+=-のみ使用可能です。',
    '연속된 문자를 사용할 수 없습니다.': '連続した文字は使用できません。',
  },

  seller_login: {
    '초대 받으신 이메일과 다릅니다.': '招待されたメールとは異なります。',
    '마플샵 셀러 신청시 입력하신 이메일과 다릅니다. 동일한 이메일로 이용해 주세요.':
      'Marpple shopクリエイターの申請時に入力したメールとは異なります。同じメールでご利用ください。',
    '인증 정보가 잘못되었습니다.': '認証情報が正しくありません。',
    '다른 그룹에 이미 속해 있어 해당 스토어에 가입 및 로그인을 할 수 없습니다.':
      '他のグループにすでに属しているため、そのストアにサインアップしてログインすることはできません。',
    '유저의 스토어가 아닙니다.': 'ユーザーのストアではありません。',
    '마플샵 관련 정보가 없습니다. 고객센터에 문의해 주세요.':
      'Marpple Shopに関する情報はありません。カスタマーセンターにお問い合わせください。',
    '스토어가 없습니다. 고객센터에 문의해 주세요.':
      'ストアはありません。カスタマーセンターにお問い合わせください。',
  },

  gnb: {
    REVIEW: 'レビュー',
    PREVIEW: 'プレビュー',
    EVENT: 'イベント',
    HELP: 'お問い合わせ',
    CUSTOM: 'カスタム',
    CUSTOM2: 'CUSTOM',
    GROUP: '団体注文',
    COLLABORATION: 'コラボレーション',
    CHAT: '1:1トーク',
    'WELCOME!': 'ご利用ありがとうございます!',
    Collaboration: 'おすすめコラボレーション',
    'PHONE CASE': 'スマホケース',
  },

  line_gnb: {
    의류: 'Clothing',
    가방: 'Bags',
    폰케이스: 'Phone Cases',
    에어팟케이스: 'AirPods Cases',
    쿠션: 'Cushion',
    '담요/테피스트리': 'Blanket/Wall Tapestries',
    시계: 'Clocks',
    펫용품: 'Pet Supplies',
  },

  review: {
    'Accepted File Types: JPEG, PNG, GIF':
      'アップロード可能な画像ファイルは、JPEGまたはPNG、GIFファイルです。',
    'Write a Review': 'レビュー作成',
    'Support your Sellers': 'レビューで応援する',
    'Select Points': '評価を選択してください。',
    '사진을 올려 주세요.': '画像をアップロードしてください。',
    Review: '商品評を作成してください。',
    'Support your sellers with a review.': 'レビューで皆さんのクリエイターを応援してください。',
    'Minimum of 5 words': '少なくとも5文字以上で作成してください。',
    'Maximum of 2000 words': '最大2,000文字以内で作成してください。',
    'Write a review for this item.':
      'Marppleでの経験、作った商品のレビューお願いします。素敵なレビューには、追加ポイントも忘れずに進呈します！',
    'Write a review for this item.2': 'レビューを作成してください。',
    '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.':
      '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.',
    'Get MarppleShop points for both you and your seller by writing a review.':
      'クリエイターを応援するレビューを登録すると、審査後、{{order_point}} Pが積み立てられます。クリエイターにも作品活動支援金{{seller_point}} Pを差し上げます。',
    'Post Review': '作成完了',
    'Thank you for your comment': 'レビューを作成していただき、ありがとうございます',
    'Continue Shopping': 'ショッピングを続ける',
    'Wrong sns': 'SNSのアドレスが間違っています。確認してください。',
    'Maximum of MAX photos': '{{max}}枚以下でアップロードしてください。',
    'Agree to terms of review': 'レビュー活用規約同意',
    view: '見る',
    'The status will be changed to Delivered': 'レビューを作成すると「配送完了」に変更されます。',
    'Discontinued product': '販売保留商品',
    'No reviews have been written.': 'レビューがありません。',
    'No Photo review.': '画像レビューがありません。',
    'There are no reviews available to write.': '作成可能なレビューがありません。',
    '리뷰 응원 완료': 'レビュー応援完了',
    '리뷰 작성 완료': 'レビュー作成完了',
    '리뷰 응원이 완료된 상품입니다.': 'レビュー応援が完了した商品です。',
  },

  checkout_placeholder: {
    Recipient: 'Recipient',
    City: '市区町村',
    'Full Name': '姓 名',
    'Phone Number': '電話番号',
    'Street address': '住所',
    'Apt., ste., bldg.': 'アパート・マンション名',
    'State or Province': '都道府県',
    'Postal Code': '郵便番号',
  },

  checkout: {
    Payment: '決済',
    Checkout: 'ご購入手続き',
    Cart: 'マイカート',
    'Order Complete': '注文完了',
    'Total to Pay': 'お支払い',
    'Total Qty': '総数量',
    Qty: '数量',
    Subtotal: '小計',
    Shipping: '送料',
    Promotion: 'プロモーション',
    Total: '合計金額',
    Email: 'メールアドレス',
    'Order Summary': 'ご注文内訳',
    'Change Email': 'メールアドレス変更',
    "We'll send an order confirmation to this address.":
      'ご注文に関するすべてのお知らせをこちらのメールアドレスにお送りします。',
    'Input English':
      '配送情報と注文者情報は英語で入力してください。ただし、ヤマトと佐川は日本語入力が可能です。',
    "We'll send an order confirmation to this address. Please verify your current e-mail address.":
      'ご注文に関するすべてのお知らせをこちらのメールアドレスにお送りします。メールアドレスが正しいか必ずご確認ください。',
    'Enter your email address': 'メールアドレスを入力してください。',
    'Keep me up to date on news and exclusive offers': '最新情報を受け取る。',
    'Shipping Address': 'お届け先住所',
    'Search Address': '住所検索',
    'Street address': '番地',
    'Apt., ste., bldg.': 'ビル・アパート・マンション名',
    City: '市区町村',
    'State or Province': '都道府県',
    '국가를 먼저 선택해주세요.': '国を先に選んでください。',
    'Postal Code': '郵便番号',
    Recipient: '氏名',
    'Full Name': '姓名',
    'Phone Number': '電話番号',
    'Save this information for next time': 'この住所を保存する',
    'Special Requests': 'ご要望',
    'Please enter the details of your request.': 'ご要望等ございましたらこちらにご記入ください。',
    'Promotion Code': 'プロモーションコード',
    'Promotion Code (Optional)': 'コード入力',
    'We Accept': '以下の方法でお支払いいただけます。',
    'This payment method is provided by Eximbay and is billed as www.eximbay.com.':
      'こちらのお支払い方法はEximbay(www.eximbay.com)により行われます。',
    'Note: Please note that the billing descriptor will be listed as EXIMBAY.COM.':
      '請求書はEXIMBAY.COMにより作成されます。',
    'Proceed To Checkout': '注文を確定する',
    'Delivery Time': 'お届け日',
    'The estimated delivery time is 10~15 business days.': '予想されるお届け日は出荷後、10日～15日です。',
    'The estimated delivery time is 3~5 business days after the shipping starts. Express shipping will be delivered by DHL.':
      'ご注文商品は発送開始から3~5営業日以降のお届け予定です。',
    Economy: '一般配送',
    Express: '速達',
    Warning: '警告',
    'Shipping Options': '配送オプション',
    'This country is not availble for Economy Shipping option.': 'こちらの国への速達は承っておりません。',
    'In some countries outside the United States, the additional payments related to import taxes, customs duties and taxes maybe required. In such cases, the additional payments must be made through local customs. Please contact the local customs directly for more details.':
      'アメリカ以外の一部国家の場合、輸入税金、関税および通関手数料などにより追加決済が必要な場合がございます。追加決済が生じる場合、現地税関を通し行わなければなりません。詳しい内容は現地税関に直接ご連絡お願い致します。',
    Customs: '税関',
    'Shipping Provider': '配送業者',
    'Provider Options': 'プロバイダーのオプション',
    delivery_fee_error1_title: '商品をご確認ください。',
    delivery_fee_error1: '配送可能な重量を超えています。',
    delivery_fee_error2_title: '一部商品の送料を計算できません。',
    delivery_fee_error2: 'カスタマーセンターにお問い合わせください。',
    'Please enter the shipping address first.': 'Please enter the shipping address first.',
  },

  order: {
    'Sold Out': '品切れまたは改編された商品です。',
    'Checking deposit': '入金確認中',
    'Order Placed': '製作準備中',
    Processing: '製作中',
    'Preparing to Ship': '配送準備中',
    Shipped: '配送中',
    Delivered: '配送完了',
    Delivered2: '購入確定',
    Cancelled: '注文取消',
    'Confirming Cancellation': '注文取消確認中',
    'Order details': '購入内訳詳細情報',
    'Order Number': '注文番号',
    Tracking: '配送照会',
    'Cancel Order': '注文取消',
    'Ordered Items': '注文商品情報',
    'Product Details': '商品情報',
    'Size/Quantity': 'サイズ／数量',
    Price: '金額',
    'Payment Info': '決済情報',
    Method: '決済手段',
    'Paid at': '承認時間',
    Paid: '決済金額',
    Amount: '決済予定金額',
    Confirming: '入金確認中',
    'Change Payment Method': '決済手段変更',
    Receipt: '証憑',
    'Request Receipt': '証憑申請',
    cancel_req_err: '再び行っても作動しない場合は、顧カスタマーサービスへお問い合わせください。',
    재구매: '再購入',
    '배송지 수정': 'お届け先修正',
    배송지: 'お届け先',
    '배송 메모': '配送メモ',
    '결제 정보': '決済情報',
    마플샵배송: 'MarppleShop配送',
    셀러배송: 'クリエイター配送',
    디지털상품: 'デジタルグッズ',
    주문번호: '注文番号',
    외몇개: '他 {{count}}個',
    주문일시: '注文日時',
    구매불가: '購入不可',
    '배송지 수정이 완료되었습니다.': 'お届け先の修正が完了しました。',
    invoiceCopy: '送り状番号をクリップボードにコピーしました',
    permissionDeny: '権限がありません。',
    '까지 다운로드 가능': '<span>{{date}}</span>までダウンロード可能',
    '도착 예정': '<span>{{date}}</span>到着予定',
    다운로드: 'ダウンロード',
    '다운로드 만료': 'ダウンロード満了',
    '구매내역으로 이동': '購買内訳に移動',
    '총 상품 금액': '総商品の金額',
    '총 배송비': '総配送費',
    '쿠폰 할인': 'クーポン',
    '포인트 사용': 'ポイント',
    '총 주문 금액': '総注文金額',
    연락처: '連絡先',
  },

  order_list: {
    'Order Status': '購入履歴',
    Products: '商品情報',
    Total: '金額',
    Status: '注文商品の進行情報',
    VIEW: '詳細',
    'No orders yet': 'ご注文中の商品はございません。',
    '마음에 드는 상품을 찾아볼까요?': 'お気に入りの商品を探してみましょうか?',
    '상품 보러 가기': '商品を見に行きます',
  },

  my_info: {
    'Personal Information Modify': '会員情報',
    Email: 'メールアドレス',
    Password: 'パスワード',
    'Change Password': 'パスワード変更',
    'This email address is not available.':
      'メールアドレスに誤りがあります。正しいメールアドレスを入力後、ご利用下さい。',
    'Nick Name': 'ニックネーム',
    BirthDay: '生年月日',
    Name: '名前',
    Phone: '電話番号',
    'Only Numbers': '数字のみ入力可能です。',
    Gender: '性別',
    MALE: '男性',
    FEMALE: '女性',
    'SMS News': 'SMSにてお知らせを受ける。',
    'Email News': 'Emailにてお知らせを受ける。',
    YES: 'はい',
    NO: 'いいえ',
    Modify: '変更',
    Withdrawal: '会員退会',
    탈퇴하기: '脱退する',
    'It has not changed. please try again.': '変更できませんでした。もう一度お確かめください。',
    Changed: '変更しました。',
    'There is no user information. Please log in again after login.':
      '会員情報がございません。ログイン後、もう一度お確かめください。',
    'fail please try again.': '会員退会が正常に行われませんでした。もう一度お試しください。',
    'Do you really want to leave?': '本当に退会しますか？',
    Success: '退会の手続きが完了しました。',
    'ex) 19940203': '８文字で入力してください。 ex) 19940203',
    check_birthday: '有効な生年月日を入力してください。',
    'Only Numbers and Text': 'Only numbers and text for password combination.',
    '이메일 주소 (ID)': 'メールアドレス(ID)',
    '이메일 변경': 'メール変更',
    'ⓘ 위 이메일로 주문 내역 메일이 전송됩니다.': '上記のメールに注文内訳のメールが送信されます。',
  },

  withdrawal: {
    '고객님 마플샵을 정말 탈퇴하시나요?': 'お客様、Marpple Shopを<span class="m_br"></span>退会しますか？',
    '회원 탈퇴 전 아래 사항을 숙지해 주세요.': '会員退会前に以下の事項をご熟知ください。',
    ol_1: '1. 一般会員の場合は、退会後に再登録できますが、ご購入いただいたコンテンツ注文内訳保有ポイントなどの会員情報がすべて削除されます。',
    ol_2: `
2. クリエイターの場合、進行中の取引「0件」、残余収益金額「0ウォン」でなければならず、販売商品の配送出庫 / CS /
収益決済がすべて完了した後、脱退申請が可能です。退会後はクリエイターショップや商品販売・精算履歴など
メンバー情報はすべて削除され、復元できません。詳細はクリエイタースタジオで確認してください。
難しい場合は、カスタマーセンター（<a href="tel：1566-5496"> 1566-5496 </a>または
<a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a>)までお問い合わせください。ご案内いたします。`,
    ol_3: '3. お客様の個人情報は、会員脱退後も商品返品及びA/Sのために「電子商取引等における消費者保護に関する法律」に基づくMarpple Shop顧客情報保護方針に従って管理されます。',
    '진행 중인 거래': '進行中の取引',
    '잔여 수익 금액': '残余収益額',
    '보유 포인트': '保有ポイント',
    몇건: '{{count}}件',
    몇원: 'KRW {{amount}}',
    몇포인트: '{{point}}P',
    그동안:
      'これまでMarpple Shopをご利用いただきありがとうございます。<br />より良いサービスでお客様を再度お会いできるよう努力いたします。',
    '회원 탈퇴가 완료되었습니다.': '会員脱退が完了しました。',
    '회원 탈퇴를 완료할 수 없습니다.': '会員退会を完了できません。',
    '진행 중인 거래 0건':
      '進行中の取引「0件」、残余収益金額「0ウォン」でなければ会員脱退が可能です。詳細はクリエイタースタジオでご確認お願いし、進行が難しい場合は、カスタマーセンターにお問い合わせいただくとご案内いたします。',
    '평일 10:00~18:00': '平日 10:00~18:00',
  },

  creator_account_info: {
    Email: 'メールアドレス',
    'Save Changes': 'メールアドレス変更',
    Password: 'パスワード',
    'Reset password': 'パスワード変更用のメールを受け取る',
    Name: '名前',
    BrandName: 'Brand 名前',
    Phone: '電話番号',
    '스토어 url': 'ショップURL',
    Modify: '変更',
    '유효한 도메인을 입력하세요.': 'Please enter a valid domain name',
  },

  phonecases: {
    'Personalize Your Own Phone case': 'スマホケースを作る',
    'Make it with your own photos':
      "自分好みのオリジナルケースを<span class='br'></span>作ってみてください。",
    'Upload your image': '写真をアップロード',
    'Insert Text': 'テキスト入力',
    'Free Designs': '無料デザイン',
    'SELECT YOUR SMART PHONE': 'デバイスをお選びください。',
    'SELECT brand': 'ブランド選択',
    'SELECT model': 'モデル選択',
    'ex brand..': 'ex iPhone, Galaxy, LG',
    'ex model..': 'ex iPhone XS, Galaxy S10, LGV40...',
    'CUSTOM YOUR CASE': 'オリジナルケースを作る',
    'SHOP * CASES': 'ネオンサンドケース',
    'please choose': '選択してください。',
    'Please select brand and model.': 'ブランドとモデルを選択してください。',
  },

  error: {
    SORRY: 'Webページが見つかりません。',
    "We couldn't find that page.": 'リンクが間違っているか、ページが削除されました。',
    'Go home': 'トップページへ',
    'This product is out of stock.': '品切れ商品か、販売中止となった商品です。',
  },

  store_header: {
    customer_service: 'カスタマーサービス',
  },

  store_footer: {
    privacy_policy: 'プライバシーポリシー',
    terms_and_conditions: '利用規約',
    faq: 'FAQ',
    customer_service: 'カスタマーサービス',
    about_us: 'はじめに',
    seller_studio: 'クリエイタースタジオ',
    my_store: '私の店',
    logout: 'ログアウト',
    login: 'ログイン',
    company_name: 'Marpple Corp.',
    detail_info: `<p>${CreatorAddressConstantS.MPS_ADDRESS_JP}</p>`,
  },

  store_help: {
    customer_service: 'FAQ',
  },

  return_status: {
    on_request: 'リクエスト',
    on_process: '処理中',
    rejected: '不可不可',
    completed: '完了',
  },

  return_type: {
    exchange: '両替',
    refund: '払い戻し',
  },

  marppleshop_designers_status: {
    ON_REQUEST: '申し込み',
    ACCEPTED: '承認',
    REJECTED: '拒絶',
  },

  marppleshop_designers_stores_status: {
    ON_REQUEST: 'リクエスト',
    ACCEPTED: 'コラボレーションの',
    REJECTED: '拒絶',
    DISMISSED: '停止',
  },

  crew_mall: {
    mo_creator: '登録クリエイター数<br/><strong class="shop-status__bold">{{ s_count }}</strong>人',
    mo_product: '販売中の商品<br/><strong class="shop-status__bold">{{ p_count }}</strong>個',
    pc_title:
      'MARPPLE SHOPでは今<span>{{s_count}}</span>人のクリエイター·アーティスト·ミュージシャン<br/><span>{{p_count}}</span>個数の商品を販売しております！',
    mo_title: '今MARPPLE SHOPではクリエイター·アーティスト·ミュージシャンが様々なグッズを作って販売中です！',
    sub_title:
      '現在{{s_count}}名のクリエイター・アーティスト・ミュージシャンが<br/>MARPPLESHOPにて{{p_count}}個以上のオフィシャルグッズを販売しています。',
    title: '<p>世界中のクリエイターのための</p><p>グッズコマースプラットフォーム</p>',
    '제품 퀄리티': 'レビュー',
    '굿즈 탐색': 'グッズを見る',
    '셀러 신청하기': 'クリエイター申請をする',
    'HOT 셀러': '人気クリエイター',
    '마플샵 리뷰': 'レビュー',
    '실시간 상품': 'リアルタイム人気商品',
    'HOT 셀러로 선정된 마플샵 크리에이터를 소개합니다!':
      '人気クリエイターに選定されたMARPPLESHOPクリエイターを紹介します！',
    '지금 마플샵에서 판매 중인 상품들을 탐색해보세요!':
      'MARPPLESHOPにて販売中の人気商品をのぞいてみてください！',
    '고객 서비스에 대한 엄격한 기준, 최상의 상품과 최고의 커스텀 프린팅':
      '高品質な商品と最高の印刷技術にて、<span>質の高いサービスをご提供します</span>',
    FAQ: 'FAQ',
    고객센터: 'カスタマーセンター',
    'No Store': 'No Store.',
    '마플샵 Contents': 'Marppleshop コンテンツ',
    '마플샵의 소식을 전달합니다!': 'マープルショップのお知らせをお届けします!',
    플러스: 'plus',
  },

  Shopify: {
    footer: {
      terms: 'Terms & Conditions',
      privacy: 'Privacy Policy',
      저작권: '',
    },
    product: {
      '다음에 하기': 'つぎにする',
      등록하기: '登録する',
      게시하기: '掲示する',
    },
    dashboard: {
      '단 3분이면 가능 !{{- sp}}지금 바로 상품을 만들어보세요.':
        'わずか3分で利用可能！{{- sp}}今すぐ商品を作成してください。',
      '상품 주문 시 사용할 카드를{{- sp}}추가/변경할 수 있습니다.':
        '商品注文時に使用するカードを{{- sp}}追加/変更できます。',
      '내 마플샵 주문 내역은{{- sp}}이곳에서 확인하세요.':
        '私のMarppleShopの注文履歴は{{- sp}}で確認してください。',
      '궁금하신 내용을{{- sp}} 고객센터를 통해 해결해 드립니다.':
        'ご質問がある場合は{{- sp}}カスタマーセンターで解決します。',
      '내가 만드는 굿즈샵{{- sp}}마플샵을 소개합니다.':
        '私が作るグッズショップ{{- sp}}MarppleShopを紹介します。',
      '비밀번호와 연락처 등을{{- sp}}수정할 수 있습니다.': 'パスワードや連絡先などを{{-sp}}修正できます。',
    },
    signup: {
      '약관 전체 동의': 'すべての項目に同意します',
      '[필수] 만 19세 이상입니다.': '[必須] 19歳以上です。',
      '[필수] 서비스 이용약관 동의': '[必須] サービス利用規約に同意します',
      '[필수] 개인정보수집 이용 동의': '[必須] 個人情報の収集・利用に同意します',
    },
    my_info: {
      이름: '名前',
      변경: '変更',
      비밀번호: 'パスワード',
      '비밀번호 변경': 'パスワード変更',
      '휴대폰 번호': '携帯番号',
      '휴대폰 번호 변경': '携帯電話番号の変更',
    },
  },

  svg_editor: {
    footer_menu: {
      add: 'イメージ追加',
      text: 'テキスト',
      path: 'Path',
      shape: 'Shape',
      image: 'アップロード',
    },
    bottom_menu: {
      font_style: 'フォントスタイル',
      keyboard: 'キーボード',
      duplicate: '複製',
      delete: '削除',
      color: 'Color',
      stroke: 'Stroke',
      path: 'Edit Path',
    },
    image_upload: {
      title: 'アップロード',
      desc1: 'アップロードできるファイル: JPEG, PNG, SVG (最大: 25MB)',
      desc2:
        '画像をアップロードすることにより、当人にその画像を使用する法的権利があるということに同意したこととなります。詳しくはプライバシーポリシーおよびガイドラインをご覧ください。',
      buttons: {
        add_img: 'アップロード',
        my_lib: 'ライブラリー',
      },
      alert: {
        fail_upload_img: 'Failed to upload images. Please try again.',
        too_large_file: 'ファイルの容量が大きすぎます。<br />{{mb}}MB以下でアップロードしてください。',
        too_small_img: 'よりきれいな仕上げりにするには<br/>最小{{px}}px以上のイメージが必要です。',
        too_large_img: '{{px}}px以下のイメージに変更してアップロードしてください。',
        too_large_img2: '{{px}}px以下のイメージに変更してアップロードしてください。',
        default_msg: 'Oops. Sorry, try again please.',
      },
    },
  },

  mypage: {
    마이페이지: 'マイページ',
    '크리에이터를 위한 마플샵을 만나보세요.': 'クリエイターのためのMarpple Shopをご覧ください。',
    버전정보: 'バージョン情報',
    현재버전: '現在バージョン',
    '신고 접수 서비스': '申告受付サービス',
    '이메일 상담': 'メール相談',
    '사이트 이용 시 유의사항': 'サイト利用時の留意事項',
    쇼핑정보: 'ショッピング情報',
    '내 샵 가기': 'マイショップに行く',
    '셀러스튜디오 가기': 'MarrpleShopスタジオ',
    '플러스 어드민 보기': 'MarrpleShopアドミン',
    '셀러 신청하기': 'クリエイター申請する',
    설정: '設定',
    '계정 설정': '勘定管理',
    '최근 본 셀러': '最近見たクリエイター',
    '관심있는 셀러는 좋아요 해주세요.': '興味のあるクリエイターは「いいね」してください。',
    '더 많은 셀러 보러 가기': 'もっと多くのクリエイターを見に行く',
    '관심있는 상품은 좋아요 해주세요.': '興味のある商品は「いいね」してください。',
    '더 많은 상품 보러 가기': 'もっと多くの商品を見に行く',
    '최근 본 셀러가 없어요.': '最近見たクリエイターがいません。',
  },

  report: {
    '신고 접수 서비스 안내': '申告受付サービス案内',
    '서비스 안내': 'サービス案内',
    '신고 접수하기': '申告を受け付ける',
    조회하기: '照会する',
    '마플샵에서 판매 중인 상품이 제3자 저작물의':
      'Marpple Shopで販売中の商品が第三者の著作物の無断盗用または肖像権を侵害したと判断される場合と、クリエイターがMarpple Shop内部規定違反を目撃した場合に、申告を受け受けるサービスです。',
    '해당 상품이 실제로 제3자의 권리를 침해하였는지 여부는':
      '当該商品が実際に第三者の権利を侵害したかどうかは、Marpple Shopで任意に判断することができず、適法な資格を持った関連機関を通じて権利侵害かどうかについての判断および事後措置の決定を求めなければなりません。Marpple Shopは、関連機関の最終判断まで、当該商品を一時的に販売中止することにより、追加の権利侵害を防止するために申告受付サービスを設けています。',
    '신고 접수 서비스는 고객님을 보호하기 위한 임시적인 방법':
      '申告受付サービスは、顧客を保護するための一時的な方法であるだけで、究極的な解決は、関連機関を通じた法律相談および救済手続きを経て進めることができます。',
    '신속하고 정확한 신고접수 서비스 운영을 통해':
      '迅速かつ正確な申告受付サービス運営を通じて、Marpple Shopは、コマースプラットフォームサービスプロバイダとしての責任を尽くそうと努力しており、当事者間の紛争が適法な手続きを通じて合理的に解決されるまで関連するすべての当事者を保護しようとします。',
    '관련기관 홈페이지': '関連機関ホームページ',
    '이미지를 클릭 하면, 해당 홈페이지로 이동합니다.': '画像をクリックすると、当該ホームページに移動します。',
    '신고 접수 서비스를 접수할 수 있는 대상': '申告受付サービスの受付可能な対象',
    '① 저작권침해':
      '<strong>①著作権侵害</strong>、著作権侵害 著作物に対する正当な権利がある当事者（個人および団体）および無断盗用された商品の原本製作当事者',
    '② 위조상품 판매':
      '<strong>②偽造商品の販売</strong>、偽造商品販売 販売中の商品が商標権またはデザイン権に違反した場合、当該権利者または権利者から委任を受けた代理人',
    '③ 마플샵 내부 규정 위반':
      '<strong>③Marpple Shop内部規定違反</strong>、Marpple Shop内部規定違反 満14歳未満の未成年者のクリエイター、著作権者ではない他人の名義で運営中のクリエイターの違反事実を立証することができる者',
    '신고 접수 시 처리 과정': '申告受付時の処理過程',
    '저작권 침해 / 위조 상품 판매 (상표권·디자인권 침해)':
      '著作権侵害／偽造商品販売（商標権・デザイン権の侵害）',
    '저작권 침해 신고 접수 시 유의사항': '著作権侵害申告の受付時の留意事項',
    '저작권침해 신고 접수 시 해당 상품을 판매 중인':
      '著作権侵害の申告受付時、当該商品を販売中のクリエイターには、要請事由（団体の場合は「権利を保有する法人／団体名」に、個人の場合は「当該権利者」に通知される）が通知され、申告受付された商品は、非公開処理されます。',
    '셀러가 저작권자이거나 정당한 권리자임을 증빙':
      'クリエイターが著作権者であるか、正当な権利者であることを証明できる場合にのみ、再販要請が可能です。（14日以内の再販要請が必要であり、未受付時は販売が中止される。）',
    '신고 접수 스레드는 요청자-셀러 간의 합의를 위한 공간':
      '申告受付スレッドは、要請者およびクリエイター間の合意のための空間であり、Marpple Shopは、著作権を保有していないため、スレッド作成のほかにいかなる関与もしていません。',
    '저작권법 제 103조 제 3항 및 동법 시행령':
      '著作権法第103条第3項および同法施行令第43条2項の規定によりMarpple Shopが予定日（再販要請受付後7日）を申告受付者に通知した後、再販予定日に商品が復元されます。',
    '재판매된 상품에 대해서는 반복하여 신고 접수를 할 수 없으며':
      '再販された商品については、申告受付を繰り返すことができず、以降の手順は、当事者間の合意または関係行政機関の審議判断や裁判所の判決によって解決することができます。',
    '위조상품판매 신고 접수 시 유의사항': '偽造商品販売申告の受付時の留意事項',
    '위조상품에 대한 조치 요청은 상표법 제107조':
      '偽造商品に対する措置要請は、商標法第107条（権利侵害に対する差止請求権等）およびデザイン保護法第113条（権利侵害に対する差止請求権等）の規定に基づき権利者のみが可能です。',
    '상표법 제108조':
      '商標法第108条（侵害とみなす行為）およびデザイン保護法第114条（侵害とみなす行為）に該当する場合、直ちに当該商品の販売中止が措置されます。',
    '위조상품 판매로 인한 조치 시 해당 상품을':
      '偽造商品の販売による措置の際、当該商品を販売中のクリエイターに権利権者（団体の場合は「権利を保有する会社名」に、個人の場合「当該権利権者」に通知される。）と侵害権利が何であるかを通知します。',
    '셀러는 위조 상품이 아니라 정당한 상표 등의':
      'クリエイターは、偽造商品ではなく正当な商標などの使用であることを証明できる場合にのみ、スレッドに回答登録が可能です。',
    '마플샵 내부 규정 위반': 'Marpple Shop内部規定違反',
    '마플샵 내부 규정 위반 신고 접수 시 유의사항': 'Marpple Shop内部規定違反申告の受付時の留意事項',
    '마플샵 내부 규정 위반 신고 접수 시 해당 상품을 판매 중인':
      'Marpple Shop内部規定違反の申告受付の際、当該商品を販売中のクリエイターには、要請事由が通知され、申告受付非公開スレッドが生成されます。',
    '셀러가 내부 규정 위반 사실이 없음을 증명할 수 있는 자료를 첨부한':
      'クリエイターが内部規定違反の事実がないことを証明できる資料を添付した回答を登録する際、内部審査後に申告解決処理がなされます。',
    '14일 이내 답변 등록이 되지 않은 경우와 심사 후 규정 위반':
      '14日以内に回答登録がなされない場合と、審査後の規定違反が確認された場合、ショップ運営が中止されます。',

    본인인증: '本人認証',
    '신고 접수 서비스는 무분별한 상품 판매 정지 또는 샵의 운영 정지를 방지하고':
      '申告受付サービスは、無分別な商品販売停止またはショップの運営停止を防止し、本人の意思に沿った進行を確認するために本人確認を必要とします。',
    '본인 명의로 가입된 휴대전화를 통해 본인 인증을 할 수 있습니다.':
      '本人名義で登録された携帯電話を通じて本人認証を行うことができます。',
    '신고 접수 조회': '申告受付照会',
    '고객님의 신고 접수 요청 내역이 확인되지 않습니다.': '顧客の申告受付要請内訳が確認されません。',
    '1. 신고 접수 유형 선택': '1.申告受付種類選択',
    '유형 사유/유형 별 요청 서식이 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 진행되지 않습니다.':
      '種類事由/種類別に要請書式が異なるため、正確に選択しない場合、申告受付が行われません。',
    '저작권침해 (고객님의 작품, 콘텐츠 등을 침해한 상품 관련)':
      '著作権侵害（顧客の作品、コンテンツなどを侵害した商品関連）',
    '위조상품 판매 (상표권/디자인권 침해 상품 관련)': '偽造商品の販売（商標権／デザイン権侵害商品関連）',
    '마플샵 내부 규정 위반 (연령제한, 타인 명의로 운영 중인 샵 관련)':
      'Marpple Shop内部規定違反（年齢制限、他人名義で運営中のショップ関連）',
    '2. 권리 침해를 받은 당사자 선택': '2.権利侵害を受けた当事者を選択',
    '단체/개인에 따라 필요 서류가 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 되지 않습니다.':
      '団体／個人によって必要書類が異なるため、正確に選択しない場合、申告受付が行われません。',
    '단체 (기업, 사업체, 종교단체, 학교 등)': '団体（企業、企業、宗教団体、学校など）',
    '개인 (본인, 가족, 친척, 지인 등)': '個人（本人、家族、親戚、知人など）',
    '3. 필요 서류 안내 및 구비 여부 선택': '3.必要書類の案内および具備の有無選択',
    '신고 접수 시 접수 유형과 당사자 선택에 따라 필요 서류가 달라지며 서류 구비가 되지 않은 경우, 신고 접수가 진행되지 않습니다.':
      '申告受付の際、受付種類と当事者の選択によって必要書類が異なり、書類が具備されていない場合、申告受付が行われません。',
    '① 저작권 침해 (고객님의 작품, 컨텐츠 등을 침해한 상품 관련)':
      '①著作権侵害（顧客の作品、コンテンツなどを侵害した商品関連）',
    '해당 셀러의 저작권 침해 사실을 증빙할 수 있는 자료 첨부':
      '当該クリエイターの著作権侵害の事実を証明できる資料添付',
    '기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부':
      '企業／団体の場合、事業者登録証、法人登録証の写し1部添付',
    '접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부':
      '受付者が団体代表者ではない場合、団体職員や代表者の署名が捺印された委任状1部添付',
    '② 위조 상품 판매 (상표권/디자인권 침해 상품 관련)': '②偽造商品の販売（商標権／デザイン権侵害商品関連）',
    '상표권/디자인권 및 해당 셀러의 침해 사실을 증빙할 수 있는 자료 첨부':
      '商標権／デザイン権および当該クリエイターの侵害の事実を証明できる資料添付',
    '③ 마플샵 내부 규정 위반 (연령 제한, 타인 명의로 운영 중인 샵 관련)':
      '③Marpple Shop内部規定違反（年齢制限、他人名義で運営中のショップ関連）',
    '해당 셀러가 마플샵 내부 규정을 위반했음을 증빙할 수 있는 자료 첨부':
      '当該クリエイターがMarpple Shop内部規定に違反したことを証明できる資料添付',
    '서류 구비 완료': '書類具備完了',
    '서류 구비가 되지 않음': '書類が具備されていない',
    '본인 인증 후 신고 접수하기': '本人認証後、申告受付する',
    '본인 인증 후 신고 조회하기': '本人認証後、報告を見る',
    '요청 처리 불가': '要請処理不可',
    '신고 접수 유형을 선택해 주세요.': '申告受付種類を選択してください。',
    '권리 침해를 받은 당사자를 선택해 주세요.': '権利侵害を受けた当事者を選択してください。',
    '서류 구비가 되지 않으면 요청을 처리할 수 없습니다.':
      '書類が具備されていない場合、要請を処理することができません。必要な書類を準備した後、再び受付をお願いします。',
    '신고 접수 유형': '申告受付種類',
    '권리 침해를 받은 당사자': '権利侵害を受けた当事者',
    '신고 접수자': '申告受付者',
    '상표권/디자인권 보유자': '商標権／デザイン権の保有者',
    '개인, 법인명, 단체이름 등을 입력해 주세요.': '個人、法人名、団体名などを入力してください。',
    'ⓘ 위의 이메일과 연락처':
      'ⓘ 上のメールと連絡先に申告進捗課程が送信されます。必ず正確に作成してください。',
    '해당 샵 URL': '当該ショップURL',
    '해당 상품 URL': '当該商品URL',
    '존재하지 않는 주소입니다.': '存在しないアドレスです。',
    '마플샵 주소를 다시 확인해 주세요.': 'Marpple Shopの{{type}}アドレスを再び確認してください。',
    '신고 사유': '申告事由',
    '원활한 신고 접수를 위하여 내용을 구체적으로 적어주세요.':
      '円滑な申告受付のために内容を具体的に書いてください。',
    '필요 서류': '必要書類',
    'ⓘ 상표권 / 디자인권':
      'ⓘ 商標権／デザイン権および当該クリエイターの侵害の事実を証明できる資料を添付してください。（ファイルサイズ：10MB以下）',
    '인증 문제가 있습니다. 다시 시도 부탁드립니다.': '認証に問題があります。再び行ってください。',
    '단체인 경우 사업자등록증':
      '団体の場合、事業者登録証、法人登録証など証明できる写し1部を添付してください。',
    '신고 접수 후 내용을 수정할 수 없습니다.':
      '申告受付後の内容を修正することはできません。<br/>続けますか？',
    '신고 접수가 완료되었습니다.': '申告受付が完了しました。',
    '스레드 생성이 완료 되었습니다.':
      'スレッド生成が完了しました。申告受付関連クリエイターの回答および返信は、スレッドでのみ可能です。',
    '비공개 스레드 바로가기': '非公開スレッドへのショートカット',
    '내부 규정 위반 여부 확인':
      '内部規定違反かどうかを確認後、登録したemailに処理結果を案内する予定です。どうもありがとうございます。',
    '스레드 종료': 'スレッド終了',
    '재요청 기간이 몇일 남았습니다.': '再要請期間が{{- days}}日残っています。',
    '답변 기간이 몇일 남았습니다.': '回答期間が{{- days}}日残っています。',
    '신고 대상 셀러': '申告対象クリエイター',
    '신고 상세 내용': '申告詳細内容',
    '답변 대기': '回答待ち',
    '판매 재개': '販売再開',
    '판매 중지': '販売中止',
    '내부 규정 위반 답변완료': '内部規定違反の回答完了',
    '샵 정상 운영': 'ショップ通常運営',
    '샵 운영 중지': 'ショップ運営中止',
    답변완료: '回答完了',
    '판매 재개/샵 정상 운영': '販売再開／ショップ通常運営',
    '판매 중지/샵 운영 중지': '販売中止／ショップ運営中止',

    '경찰청 사이버안전국': '警察庁サイバー安全局',
    '생년 월일': '生年月日',
    연락처: '連絡先',
    이메일: 'メール',
    '이메일을 입력해 주세요.': 'メールを入力してください。',
    '파일 첨부': 'ファイルの添付',
    '단체 증명 자료': '団体証明資料',
    스토어: 'ストア',
    상품: '商品',
    '신고 접수에 대한 답변이 완료되었습니다.': '報告受付への回答が完了しました。',
    '해당 상품 판매가 재개되었습니다.': '該当商品の販売が再開されました。',
    '이의신청 내용을 검토하여 알려드리겠습니다.': '異議申し立て内容を確認してお知らせします。',
    '저작권 침해': '著作権侵害',
    '위조상품 판매': '偽造商品の販売',
    개인: '個人',
    단체: '団体',
    '신고 조회하기': '申告照会',
    '신고 접수는 PC에서만 가능합니다.': '申告の受付は、PCでのみ可能です。',
    한국저작권위원회: '韓国著作権委員会',
    '1. 저작권침해': '1. 著作権侵害',
    '저작물에 대한 정당한 권리가 있는 당사자(개인 및 단체) 및 무단 도용된 상품에 대한 원본 제작 당사자':
      '著作権侵害 著作物に対する正当な権利がある当事者（個人および団体）および無断盗用された商品の原本製作当事者',
    '2. 위조상품 판매': '2. 偽造商品の販売',
    '판매 중인 상품이 상표권 또는 디자인권을 위반한 경우 해당 권리자 또는 권리자로부터 위임 받은 대리인':
      '偽造商品販売 販売中の商品が商標権またはデザイン権に違反した場合、当該権利者または権利者から委任を受けた代理人',
    '3. 마플샵 내부 규정 위반': '3. Marpple Shop内部規定違反',
    '만 14세 미만 미성년자 셀러, 저작권자가 아닌 타인의 명의로 운영 중인 셀러의 위반 사실을 입증할 수 있는 자':
      'Marpple Shop内部規定違反 満14歳未満の未成年者のクリエイター、著作権者ではない他人の名義で運営中のクリエイターの違反事実を立証することができる者',
    '4. 본인 인증': '4. 本人認証',
  },

  format: {
    date: {
      short: 'M月 d日',
      ym_locale: 'yyyy年 M月',
      ym_digit: 'yyyy-MM',
    },
  },

  seller_type: {
    유튜버: 'ユーチューバー',
    틱톡커: 'ティックトッカー',
    스트리머: 'ストリーマー',
    크리에이터: 'クリエイター',
    인플루언서: 'インフルエンサー',
    일러스트레이터: 'イラストレーター',
    타투이스트: 'タトゥーイスト',
    포토그래퍼: 'フォトグラファー',
    '캐릭터 디자이너': 'キャラクターデザイナー',
    '웹툰/만화 작가': 'ウェブトゥーン／マンガ作家',
    뮤지션: 'ミュージシャン',
    아티스트: 'アーティスト',
    브랜드: 'ブランド',
    '마플샵 플러스': 'MARPPLESHOP PLUS',
    가나다: 'カナダ',
  },

  mshop: {
    Shopping: 'ショッピング',
    About: 'アバウト',
    Review: 'レビュー',
    Live: 'リアルタイム',
    Community: 'レビュー',
    PhotoReview: 'フォトレビュー',
    Search: '検索',
    Mypage: 'マイページ',
    bnb_coupon: 'Nexon cash (3,000 won)',
    'welcome text': '{{- name}}様、こんにちは。',
    Login: 'ログイン',
    Please: '{{- login}}してください',
    Cart: 'カート',
    Cart2: 'カートに入れる',
    '인기 셀러': '人気クリエイター',
    '인기 상품': '人気商品',
    'Go to Seller Studio': 'クリエイタースタジオに行く{{emoji}}',
    'Go to Apply Seller': 'Marpple Shopクリエイター申請する{{emoji}}',
    'Go to Seller Apply2': 'クリエイター申請する',
    '댓글이 등록되었습니다.': 'コメントが登録されました。',
    Close: '閉じる',
    'Edit My Info': 'マイ情報修正',
    'All Menu': '全体メニュー',
    신규: '新規',
    셀러: 'クリエイター',
    스타일: 'スタイル',
    상품상세: '商品詳細',
    Contents: 'コンテンツ',
    'Content Detail': 'コンテンツ詳細',
    'Related Contents': '関連コンテンツ',
    '베스트 후기': 'ベストレビュー',
    '배경화면, 아이콘 등의 디지털 상품은 앱에서 결제할 수 없습니다. 장바구니에서 디지털 상품을 삭제한 후 결제를 다시 시도하시거나 PC에서 구매해 주세요.':
      '<h3>壁紙、アイコンなどのデジタル商品は、アプリで決済できません。</h3><p>カートからデジタル商品を削除した後で決済を再び行うか、PCで 購入してください。</p>',
    '조건에 딱! 맞는 상품이 없어요.': '条件にピッタリ！合う商品がありません。',
    '검색조건을 초기화하거나, 바꿔서 검색해 보세요.':
      '検索条件を初期化するか、{{- br}}変更して検索してください。',
    '목록으로 돌아가기': 'リストに戻る',
    '상품 준비 중입니다.': '商品準備中です。',
    '상품을 준비하는 동안 마플샵의 상품을 만나보세요!':
      '商品を準備する間、Marpple Shopの商品をご覧ください。',
    '마플샵 구경하기': 'Marpple Shopを見る',
    '주소가 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.':
      'しばらくしてから再び利用してください。サービス利用に御不便をおかけして申し訳ございません。',
    '페이지를 찾을 수 없습니다.': 'ページが見つかりません。',
    '잠시 후 다시 이용해 주세요. 서비스 이용에 불편을 드려 죄송합니다.':
      'しばらくしてから再び利用してください。<br/>サービス利用に御不便をおかけして申し訳ございません。',
    '서버 에러가 발생하였습니다.': 'サーバーエラーが発生しました。',
    '이전 페이지': '前のページ',
    '메인 페이지': 'メインページ',
    '#지금이_아니면_안돼_한정판': '#今で_なければ_ダメ_限定版',
    '#난리났네_난리났어_인기상품': '#大騒ぎだ_大騒ぎだ_人気商品',
    '#신상은_타이밍!': '#新商品は_タイミング！',
    상품목록: '商品リスト',
    '셀러명 혹은 상품명으로 검색해보세요.': 'クリエイター名を検索してください',
    '어떤 상품을 원하시나요?': 'どんな商品をお探しですか',
    '인기 검색어': '人気検索語',
    '검색 결과가 없습니다.': '検索結果がありません。',
    '에 대한 검색 결과가 없습니다.': '<span class="keyword">{{q}}</span>の検索結果がありません。',
    '검색어의 철자나 띄어쓰기가 정확한지 확인해 주세요.': 'キーワードをご確認ください。',
    '최근 검색어': '最近検索語',
    '최근 검색어 내역이 없습니다.': '最近の検索履歴はありません。',
    '등록된 상품이 없습니다.': '商品がありません。',
    '스타일 필터': 'スタイルフィルター',
    '게시물을 등록해 보세요!': '掲示物を登録してみてください。',
    '카테고리 선택': 'カテゴリーの選択',
    Apply: '確認',
    신규상품: '新規商品',
    '좋아요 목록에 추가되었습니다.': 'いいねしたリストに追加されました。',
    '좋아요 목록에서 삭제되었습니다.': 'いいねしたリストから削除されました。',
    인기순: '人気順',
    최신순: '新着順',
    '낮은 가격순': '安い順(価格)',
    '높은 가격순': '高い順(価格)',
    '리뷰 많은 순': 'レビューの多い順',
    '좋아요 순': 'いいですね、スン',
    '최근 팔린 순': '最近売れた順',
    '신규 등록 순': '新規登録順',
    '첫번째 글을 등록해 보세요!': '最初の文を登録してください！',
    '첫 번째 리뷰어가 될 기회': '最初のレビュアーになるチャンス',
    '첫 번째 게시물이 될 기회': '最初の掲示物になる機会。',
    '게시물을 첫번째로 작성하고 셀러의 댓글을 받아보세요!':
      '掲示物を最初に作成し、クリエイターのコメントをお受け取りください！',
    더보기: 'もっと見る',
    '마플샵 셀러 신청': 'Marpple Shopクリエイター申請',
    '상품을 구매하고 리뷰를 등록하시면 포인트를 드려요.':
      '商品を購入してレビューを登録するとポイントを取得できます。',
    채널ON: 'チャンネルON',
    '마플샵 바로가기': '{{name}}Marpple Shop',
    '관심있는 셀러는 좋아요 해주세요.': '興味のあるクリエイターは「いいね」してください。',
    '더 많은 셀러 보러 가기': 'もっと多くのクリエイターを見に行く',
    '관심있는 상품은 좋아요 해주세요.': '興味のある商品は「いいね」してください。',
    '더 많은 상품 보러 가기': 'もっと多くの商品を見に行く',
    '세상 모든 크리에이터가 여기에!': 'クリエイターの皆さんが揃いました！',
    '이벤트 응모가 완료되었습니다!': 'イベント応募が完了しました！',
    '팬심으로 보고, 사심으로 사는 공식굿즈': '愛情を込めて購入するオフィシャルマーチャント',
    '마플샵 팝업스토어': 'ポップアップストア',
    '최애 아티스트와 브랜드의 공식굿즈까지!': '大好きなアーティスト&ブランドのオフィシャルグッズ！',
    '지금 주목해야 할 크리에이터': '今注目すべきクリエイター',
    '성인 인증이 필요합니다.인증을 진행하시겠습니까?': '年齢確認が必要です。<br>認証を進めますか？',
    'AGE VERIFICATION': '年齢確認',
    'You must be at least 18 years old to enter this shop!': '18歳以上のみアクセス可能です!',
    Age: '年齢',
    'I agree to the Marrple':
      'Marppleの<a href="/@/terms" class="go_terms" target="_blank">利用規約</a>に同意します。',
    "I'll be held responsible for the information I provided.":
      '提供した情報に対するすべての責任は本人にあります。',
    'I am 18+': '18歳以上です',
    'I am under 18': '18歳未満です',
    'Access Denied': 'アクセス拒否',
    'Users must be at least 18 to access age-restricted contents.':
      '18歳以上しか見られない年齢制限コンテンツです。',
    Return: '戻る',
    '본인 인증 되었습니다.': '本人認証されました。',
    HomeTitle: {
      '지금 이 순간에도 새로운 상품이 업데이트 중': '今この瞬間も新たな商品がアップデート中',
      '미리 미리 알아둬요, 언제 유명해질지 모르니까.':
        'あらかじめ知っておきましょう、{{- br}}いつ有名になるか分からないから。',
      '당신의 마음을 훔치러 온 뉴페이스 👋': 'あなたの心を盗みに来たニューフェイス 👋',
      '읽다 보니 재밌다. 자세히 보니 후기다.': '読んでみると面白い。詳しく見たら感想だ。',
      '관심 있을 만한 걸 가져왔어요 💘': 'これに興味があるんじゃないの？💘',
      '마음으로 낳아 지갑으로 길렀다.': '心で生んで財布で育てた。',
      '요즘은 이런 굿즈가 잘 나가요 😎': '最近人気のグッズ！😎',
      '어차피 할 덕질, 행복하게 덕질하자!': 'どうせオタ活するなら、幸せにやろう！',
      '직접 구매한 사람들의 후기 ✏️': '消費者の生新しい レビュー✏️',
      '추천은 내가 할게, 사는건 누가 할래?': 'お勧めは私がするよ、買うのは誰がする？',
      '늦기 전에 데려가세요! 🏃‍♂️': '早く注文してください！🏃‍♂️',
      '크리에이터를 위한 마플샵을 만나봐': 'クリエイターのためのMarpple Shopを見てみよう',
      '마플샵 셀러 신청하기': 'Marpple Shopクリエイター申請する',
      '한정판 & 마감임박 상품': '限定版＆締切間近の製品',
      '이런 신상 처음이야!': 'こんな新商品は初めてだ！',
      '최애는 지금 영업 중 🕺': '大好きなクリエイターはオンエア!🕺',
    },
    plusTitle: {
      '가장 많이 찾는 샵': '最もよく行くショップ',
      '오늘의 브랜드 스토리': '今日のブランドストーリー',
      '가장 사랑 받는 굿즈': '最も愛されるグッズ',
      '지금은 방송중': '今は放送中',
      '고객님의 소중한 리뷰': 'お客様の貴重なレビュー',
      '내일이면 못 살 굿즈': '明日になったら買えないグッズ',
      '마음을 사로잡을 신상 굿즈': '心を奪う新商品グッズ',
      '가장 많이 공감 받은 리뷰': '最も共感されたレビュー',
      '주목할 만한 새로운 샵': '注目すべき新ショップ',
    },
    ProductBadge: {
      매진: '売り切れ',
      한정: '限定',
      '??개남음': '残り{{c}}つ',
      '??시간전': '{{hour}}時間前',
      '??분전': '{{minute}}分前',
      출시예정: '発売予定',
      '출시??': '発売{{d}}',
      마감임박: '締切間近',
      '마감??': '締切{{d}}',
    },
    tag: {
      marpple_product: 'マープルショップ商品',
      seller_product: '셀러상품',
      is_free_shipping_product: '무료배송',
      custom_description: '원하는 대로 커스터마이징하여 주문할 수 있는 상품',
      hand_made_description: '셀러가 직접 손으로 만드는 상품',
      print_on_demand_description: '주문 후 제작을 시작하는 상품',
      marpple_product_description: '마플샵에서 제작하여 배송되는 상품',
      seller_product_description: '셀러가 제작하거나 보유한 상품을 직접 발송하는 상품',
    },
    ProductStatus: {
      pb_me: '마플샵 공개',
      prohibited: '販売保留',
      checking: '審査中',
      public: '公開',
      private: '非公開',
      not_confirmed: '検収中',
      ready_confirm: '検収未申請',
      not_use_product: '販売中止商品',
      // end_of_sale: '販売期間終了',
      end_of_sale: '{{start_at}} ~ {{end_at}}',
      until_close:
        '締切まで&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
      until_open:
        '発売まで&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
    },
    ProductStatusTooltip: {
      pb_me: '셀러샵과 마플샵에 공개되는 상품입니다.',
      prohibited: '저작권 등의 문제로 판매보류된 상품입니다.',
      checking: '디지털 상품은 심사 후 판매할 수 있습니다.',
      public: '셀러샵에서만 공개되는 상품입니다.',
      private: '셀러만 볼 수 있는 비공개 상품입니다.',
      not_confirmed: '판매 담당자에게 검수 요청한 상품입니다.',
      ready_confirm: '판매 담당자에게 검수를 요청해보세요.',
      not_use_product: '{{bp_name}} 제품이 단종되었습니다.',
      // end_of_sale: '판매 기간 종료',
    },
    ProductDetail: {
      tabs: {
        story: 'ストーリー',
        review: 'レビュー',
        bp: '商品情報',
        purchaseInfo: '購入案内',
        faq: 'FAQ',
      },
      purchaseInfo: {
        title: '配送のご案内',
        desc: 'すべての商品は、顧客の注文に合わせて新商品として製作および配送されます。',
        expect:
          'ただ今注文すると<span>{{start}}</span>から製作プロセスが開始され、<span>{{end}}</span>までに出荷される予定です。',
        tpl_expect:
          '只今ご注文いただくと、&nbsp;<span>{{start}}</span>から&nbsp;<span>{{end}}</span>&nbsp;の間に出荷予定です。<br />マープルショップのオーダーメイド商品と一緒にご注文する場合、オーダーメイド商品の出荷日に合わせて一緒に配送されます。',
        producing_period:
          '<strong style="color:#000">제작 소요 기간</strong>&nbsp;<span style="color:#828282;">평균&nbsp;{{avg_days}}&nbsp;/&nbsp;최대{{max_days}}</span>',
        refund_title: '交換/返品のご案内',
        refund_desc1:
          'すべてのマープルショップ配送商品はオーダーメイド方式で製作され、お客様都合による交換/返品は受け付けておりません。',
        refund_desc2:
          'ただし、受領した商品の不良または誤配送された場合は、7日以内にカスタマーセンターまたはメール、お問い合わせフォームにてお申し付けいただければ、交換及び返品·返金が可能です。',
        take_back_title: '반송지 정보',
        take_back_mobile: '문의 연락처',
        take_back_price: '반품 배송비',
        take_back_address: '보내실 곳',
        seller_info_title: '셀러 정보 안내',
        seller_name: '셀러명',
        seller_email: '이메일',
        seller_company_name: '상호명',
        seller_business_license_number: '사업자 번호',
        seller_company_tel: '연락처',
        cs_title: 'カスタマーサービス',
        cs_num: '1566-5496',
        email_title: 'メール',
        email: 'cs@marppleshop.com',
        btn_guide1: '請約撤回制限要件のご案内',
        btn_guide2: '品質保証基準のご案内',
      },
      faq: {
        group1: '注文／配送',
        group2: '交換／払戻',
        group3: 'デジタルグッズ購入ガイド',
        q1: '注文後、商品の受領までどのぐらいかかりますか？',
        a1: `Marpple Shopのすべての商品は、注文製作方式で製作され、注文後の商品受領まで一定期間を要します。<br/>配送予定日は、商品の詳細ページと注文内訳で確認することができます。`,
        q2: '注文の状態はどうやって知ることができますか？',
        a2: `
ログイン>マイページ>購入内訳で 注文 商品の 製作 進捗 状態 および 配送 日程を 確認する ことが できます。<br/>
商品を 注文すると 以下の 5段階を 経て 製作と 配送が 行われ 製作準備中 状態でのみ 注文 変更 および 取消が 可能です。<br/>
<br/>
製作準備中：決済が 完了し 商品 製作 前の 準備段階です。<br/>
製作中：注文した 商品が 製作されて いる 段階であり、 この 段階からは 注文 取消／変更／払戻しが できません。<br/>
製作準備中：製作が 完了して 商品の 検収 および 包装が 進行中です。<br/>
配送中：注文した 商品が 出荷され 宅配会社に 引き渡された 状態で 運送状 番号は テキストメールで 発送されます。出荷された 翌日から 送り状番号の 照会 による 配送 状況の 確認が 可能です。<br/>
配送完了 ：配送が 完了した 状態です。`,
        q3: '非会員も注文することができますか？',
        a3: `
非会員も注文可能で、注文完了時に入力されたメールに注文内訳を送信します。<br/>
メール注文内訳を通じて詳細注文情報と配送情報を確認することができます。<br/>
既存のMarpple会員は、同一のIDでMarpple Shopを利用でき、Marpple Shop会員にはポイント積立、イベントなど様々な特典が提供されますので、多くの利用をお願いします。`,
        q4: '注文取消が可能ですか？',
        a4: `
注文製作・オーダーメイド商品の特性上、商品の製作が開始されると、注文の取消はできません。<br/>
<br/>
注文後に製作準備中のステータスでのみ注文の変更および取消が可能で、製作中のステータス以降は取消ができませんので、製作が開始される前に取り消してください。<br/>
注文の取消は、ログイン> マイページ > 購入内訳 > 詳細ビューで「注文取消」ボタンにより可能です。Marpple Shopは、注文された商品のみ生産し、資源の浪費を防いで環境を考慮しています。<br/>
購入の決定は慎重にお願いします。`,
        q5: '商品の受領後に交換や払戻しが可能ですか？',
        a5: `
注文制作された商品は、オーダーメイドで製作され、再販売ができませんのでお客様の都合による返品/交換はお受けすることができません。<br/>
ただし、お受け取りになった商品が不良の場合、「電子商取引等における消費者保護に関する法律」に基づき、交換／返品できますので、MarppleShopのメール(cs@marppleshop.com)にてお問い合わせください。<br/>
お手数ですが、商品の不良である写真を2枚以上添付してお問い合わせいただければ、ご確認の上ご案内致します。<br/>
ご注文された商品以外の商品との交換はいたしかねます。洗濯の不注意による商品の変形及びプリントの損傷については返品/交換いたしかねます。、商品詳細ページの洗濯方法を必ずご覧ください。
`,
        q6: 'デジタルグッズ購入前に、必ず確認してください！',
        a6: '<img src="https://s3.marpple.co/files/u_1184090/2021/4/original/40992d192beed7c089fdaaa40136b2b78b1400021.jpg" alt="">',
        q7: 'デジタルグッズコンテンツのライセンスはクリエイターにあります。',
        a7: `
コンテンツの著作権を含む権利は、すべて当該「Marpple Shopクリエイター」にあります。<br/>
会社は、コンテンツの著作権およびライセンスに関するいかなる保証もしません。<br/>
コンテンツ購入のみでは購入者に著作権および所有権の権利が移転されません。<br/>
営利目的の直接または間接的なコンテンツ使用は不可能であり、広告およびマーケティング、プロモーションコンテンツなど、商業目的で使用できません。<br/>
上記すべての事項に違反の際、財産権および肖像権の問題が発生することがあり、それによるすべての責任は購入者にあります。`,
        q8: '購入したコンテンツは、個人的なご利用できます。',
        a8: `
一度購入した画像は、期間に制限なく使用することができます。<br/>
個人所蔵および1人（個人）使用のみを原則とし、非商業的用途の使用のみ可能です。<br/>
商業用途で無断使用する場合、著作権法などによって法的責任を負うことがあります。<br/>
拡張ライセンスの購入が必要な場合は、当該クリエイターにお問い合わせください。`,
        q9: 'ID共有禁止案内',
        a9: `
購入したコンテンツは、1アカウントにつき1人（個人）の使用を原則とします。<br/>
Marpple Shopは、顧客の個人情報保護および無断画像共有の問題を防止するため、ID共有を禁止しています。<br/>
ID共有履歴が確認された場合、Marpple Shop利用規約に基づきサービスの利用制限されることがあり、著作権法および関連法令に基づき処罰されることがあります。`,
        q10: '購入したデジタルグッズの修正／編集／共有および再販を禁じます。',
        a10: `
デジタルグッズを購入するということは、その画像の著作権まで購入するのではなく、画像の利用権のみを購入するものであるため、再配布／再分配／再販を行うことができません。<br/>
購入したコンテンツで、製品パッケージ、はがき、額縁などのような2次（印刷）著作物を作って販売することはできません。購入したコンテンツを元の形のまま、SNS、ブログ、カフェ、ウェブハードなどに再配布、再分配の目的でアップロードまたはスクラップすることを禁じます。<br/>
画像の使用制限に違反する使用が発見されたとき、違反使用によって会社および画像の著作権者に損害および被害が発生した場合、それによるすべての責任は購入者にあり、それに伴う補償を行わなければなりません。`,
        q11: 'デジタルグッズの払戻しはできません。',
        a11: `
デジタルコンテンツの特性上、購入完了した画像については払戻しできません。<br/>
デバイス別の画像の比率または解像度の違いによる払戻しはできないため、正確に確認のうえ購入することをお勧めします。<br/>
コンテンツの著作権および肖像権、財産権、使用範囲および画像内に使用されたフレーズなどすべての情報は、当該クリエイターが提供するものであり、当社がその正確性を100％保証することはできません。<br/>
例外として払戻しが発生する際には、決済時に発生した手数料を除いた金額が払い戻されます。`,
      },
      tpl_faq: {
        a1: '注文受付日から平日基準で3~5日程度で出荷されています。 祝日決済完了の件は翌営業日に発送されます。 配送業者の都合によって配送日程が変わることがありますのでご了承ください。',
        a2: 'ログイン > マイページ > 購入履歴から商品の注文状態及び配送日程をご確認できます。',
        a4: '注文受付段階まではシステム上で注文キャンセルが可能です。<br />注文のキャンセルはログイン > マイページ > 購入履歴 > 詳細情報で【キャンセル】ボタンを押してください。',
        a5: '通常の常時販売商品は、購入確定前に交換及び払い戻しが可能です。 交換と払い戻しによる送料は、マープルショップカスタマーセンター(1566-5496)までお問い合わせいただければ幸いです。',
      },
      preview_by_device: 'スマホに適用する。',
      color: '色',
      quantity: '数量',
      size: 'サイズ',
      sold_out: '品切れ',
      sold_out_products: '商品が売り切れました😢',
      sold_out_size: 'サイズが一時的に品切れです。',
      device: '機種',
      check_size_guide: 'ガイドを確認してください！',
      max_purchase_per_user: '1人当たり最大{{max_purchase_per_user}}個購入可能な商品です。',
      possible_quantity: ' 買い物かごに{{possible_quantity}}つまで入れることができます!',
      already_possible_quantity_title: '1人当たりの購入可能数量超過',
      already_possible_quantity: 'すでに購入した商品か、ショッピングカートに入っています。',
      expect: `ただ今注文すると {{start}} ~ {{end}} の間に出発します！`,
      producing_period:
        '<strong style="margin-right:6px;">제작 소요 기간</strong><span style="color:#828282;">평균&nbsp;{{avg_days}}일&nbsp;/&nbsp;최대{{max_days}}일</span>',
      total_count: `<span class="dynamic-value">{{count}}</span>個商品金額`,
      left_count: '{{total}}つのうち{{left}}つが残っています！ 🤭',
      coming_soon: 'ドドン！すぐ購入可能🤩',
      not_approve: '販売承認後に購入可能です。',
      end_sale: '販売が終了しました😢',
      only_pc: 'デジタルグッズはPCでのみ購入可能😉',
      purchased_dtg: '이미 구매한 상품이에요! 🛍',

      size_guide: 'サイズガイド',
      product_base_info: '商品基本情報',
      check_cart_in: 'カートに入れますか？',
      opt_prod: '商品オプション',
      preparing: '準備中です。',
      quality_modal: {
        p1: '本製品は、徹底した品質管理と工程管理を経て生産されており、外観、規格、物性検査で合格したものです。',
        p2: '本製品を長くご使用いただくため、製品に付属された取り扱い注意事項を必ずご確認ください。',
        p3: '本製品の品質に異常がある場合、お問い合わせください。',
        p4: '本製品の品質に異常がある場合、製品受け取りより7日以内に返品/交換が可能です。',
      },
      retraction_modal: {
        p1: '着用や洗濯の痕跡、臭い、汚れ、お直しの痕跡など商品が毀損しているか、不良商品にもかかわらず着用または使用した場合、交換／返品いたしかねます。予めご了承ください。',
        p2: 'クーリングオフ制限要件<br/>（第17条第2項第6号及び同法施行令第21条）',
        li1: '詳細サイズは表記サイズには若干の(約1~3cm)誤差がある場合がございますが、不良品ではないので交換／返品いたしかねます。ご了承ください。',
        li2: 'Marpple Shopで販売される商品は、各ブランド、卸売先でそれぞれのサンプリングを経て供給される製品であるため、特定のブランドやお持ちの商品と詳細サイズが異なる場合があります。<br/>実際、購入時に詳細サイズを必ず確認してください。',
        li3: 'サイズ測定は、商品を床に広げた後、端線から端線まで測定する必要があり、伸縮性のある素材の場合は、引っ張らずに床に広がった状態のまま測定しなければなりません。誤差範囲外の誤った測定による交換／返品いたしかねます。ご了承ください。',
      },
    },
    Menu: {
      Home: 'ホーム',
      Contents: 'コンテンツ',
      Product: '商品',
      Creator: 'クリエイター',
      작품: '作品',
      Official: '公式グッズ',
      All: '全体',
      가나다: 'カナダ /A, B, C',
      '마플샵 플러스': 'Marpple Shopプラス',
      'Customer Center': 'カスタマーサービス',
      'Order Status': '購入内訳',
      Community: 'レビュー',
    },
    Seller: {
      셀러: 'クリエイター',
      기타: 'その他',
      명: '{{- count}} 人',
      건: '{{- count}} 件',
    },
    Footer: {
      CompanyName: 'Marpple Corporation',
      CEO: 'CEO',
      CeoName: 'Hyeyun Park',
      CustomerCenter: 'CS',
      CustomerCenterNumber: '1566-5496',
      CustomerCenterInfo: '（平日10:00 ～18:00）',
      CompanyRegistrationInfo: '事業者登録番号',
      CompanyRegistrationNumber: '105-88-13322',
      Address: 'アドレス',
      AddressInfo: CreatorAddressConstantS.MPS_ADDRESS_JP,
      Email: 'メール',
      EmailInfo: 'cs@marppleshop.com',
      ReportCenter: '申告センター',
      ApplySeller: 'クリエイター申請',
      TermsAndConditions: '利用規約',
      PrivacyPolicy: '個人情報処理方針',
      Instagram: 'Instagram',
      Youtube: 'Youtube',
      Twitter: 'Twitter',
      Blog: 'Blog',
      MarppleCorp: 'Marpple Corporation',
    },
    Hello: {
      '안녕, 마플샵!': 'こんにちは、Marpple Shop',
      '3분 샵만들기': '3分ショップ作り',
      '아이디어가 있나요?': 'アイディアがありますか？',
      '마플샵이 다 해요!': 'Marpple Shopが全部やります！',
      '당신은 디자인만!': 'あなたはデザインだけ！',
    },
    live: {
      '지금 총알 쏘러 갑니다.': '今から弾丸を撃ちに行きます。',
      '라이브 방송중': 'ライブ放送中',
      '제일 인기있는 셀러': '一番人気のクリエイター。',
      '제일 인기있는 셀러를 보여줘.': '一番人気のクリエイターを見せて。',
      '지금 막! 팔렸어요.': '今ちょうど！売れました。',
      '제일 많이 팔린 상품을 보여줘.': '一番売れた商品を見せて。',
      '월간 랭킹': '月間ランキング',
      '내가 이 구역 리뷰왕!': '私がこのエリアのレビュー王！',
      '50위까지 펼쳐보기': '50位まで展開',
      '30개까지 보기': '30個まで見る。',
    },
    '몇월 몇째주': '{{month}}月 {{weeks}}',
    '언제 기준': '<strong>{{date}}</strong> 時点',
    week1: '第1週',
    week2: '第2週',
    week3: '第3週',
    week4: '第4週',
    week5: '第5週',
    week6: '第6週',
    '1:1 채팅 문의는 PC 혹은 모바일 웹사이트에서 이용가능합니다. 주소창에 marpple.shop 을 입력해 주세요.':
      '1：1チャット問い合わせは、PCまたはモバイルウェブで利用可能です。アドレスウィンドウにmarpple.shopと入力してください。',
    report: {
      title: '報告書',
      complete: '申告が受け付けられました。',
      limit: '100文字まで作成可能です。',
      placeholder: '報告書を提出した理由を記入してください。',
      noticeTitle: 'を報告する前に確認してください。',
      noticeDescription1:
        '著作権侵害、偽造品の販売、メイプルショップの内部規定違反などで団体や個人が投稿に違反した場合は、メイプルショップ>報告センターにPC版で登録してください。',
      noticeDescription2: 'このレポートはレポート作成の目的で運用され、別途の回答は提供されません。',
      confirmMessage: 'このコメントを報告しますか？',
      reportItem1: '主題に合わないです。',
      reportItem2: '情報が不正確です。',
      reportItem3: '過度に広告をします。',
      reportItem4: '悪口や誹謗がひどいです。',
      reportItem5: '暴力または嫌悪感のあるコンテンツです。',
      reportItem6: 'わいせつな内容が含まれています。',
      reportItem7: 'このユーザーの掲示物は見たくありません。',
      reportItem8: '(選択すると、ユーザーの掲示物及びコメントが露出されません。)',
    },
    QuickMenu: {
      t1: 'ようこそ',
      t2: '人気クリエイター',
      t2_mo: '人気クリエイター',
      t3: 'レビュー',
      t4: 'ライブランク',
      t5: 'アートショップ',
      t6: '公式グッズ',
      t7: 'OMNUUM NFT',
      t10: 'Creators Club',
      t11: 'Youtube Shopping',

      t12: 'クリエイター申請',
      t13: 'ポップアップストア',
      t14: 'Offline Pop-up',
      t15: '',
      t16: '특별한 고객 경험을 위한 오프라인 팝업스토어, 마플샵',
    },
    Styles: {
      t1: 'スタイル',
      t2: '一つのグッズで！ 雰囲気を変えられる！✨',
      t3: '私が直接作って販売してみようか。',
      t4: '지금 신청하면 5,000포인트 지급!',
    },
    meta_title: {
      mshop_home: 'MAKE IT REAL | MarppleShop',
      mshop_creator: 'Marpple Shop クリエイター',
      mshop_contents: 'Marpple Shop コンテンツ',
      mshop_products: 'Marpple Shop 商品',
      review: 'Marpple Shop レビュー',
      creator: 'Marpple Shop クリエイター',
      contents: 'Marpple Shop コンテンツ',
      products: 'Marpple Shop 商品',
      live: 'Marpple Shop リアルタイム ランキング',
      search: 'Marpple Shop 検索',
      registration: 'Marpple Shop クリエイター申請',
    },
    auth: {
      signup: '',
      signup_email: '',
      signup_id: '',
      signup_nickname: '',
      signup_station: '',
    },
    signup: {
      signup_use_term: '',
      signup_age_term: '',
      show_more: '',
      login: '',
      signup_error: '',
      signup_use_term_error: '',
      signup_age_term_error: '',
      agree_privacy: '',
      agree_privacy_error: '',
      signup_use_extra_term: '',
      signup_use_extra_term_error: '',
      signup_use_extra_term_title: '',
      signup_agree_ad: '',
    },
  },

  about: {
    갤러리: 'ギャラリー',
    '게시글이 없어요.': 'スレッドがありません。',
    '님의 갤러리를 기다려주세요.': '{{- store_name}}様のギャラリーをお待ちください。',
    '님, 갤러리를 등록해 보세요!': '{{- store_name}}様、ギャラリーを登録してください！',
  },

  common: {
    lang: {
      kr: '韓国語',
      en: 'English',
      jp: '日本語',
    },
    unit: {
      krw: 'ウォン',
      usd: 'ドル',
      jpy: '円',
    },
    action: {
      save: '保存',
      do_save: '保存する',
      cancel: '取消',
      do_cancel: '取消する',
      regist: '登録',
    },
    reveal_type: '公開可否',
    public: '公開',
    private: '非公開',
    all_cate: '全体カテゴリー',
    seller: 'クリエイター',
    save: '保存',
  },

  s_about: {
    edit_modal_title: 'プロファイル設定',
    sns_label: 'SNS',
    sns_holder: 'SNSのURLを入力してください。',
    desc_label: '紹介',
    desc_holder: '紹介内容を入力してください。',
    rep_label: '代表画像／動画',
    rep_image: '画像登録',
    rep_video: '動画URL登録',
    rep_video_holder: 'ユーチューブ、ツイッチ、アフリカ動画アドレス',
    rep_tip: '①代表画像または代表動画のうち1つのみ登録可能です。',
    copy_link: 'リンクコピー',
    url_copied: 'URLがクリップボードにコピーされました。',
    url_copied2: 'アドレスがコピーされました。お望みの場所にコピー＆ペーストしてください。',
    url_failed: 'URLコピーに失敗しました。',
    edit_profile: 'プロフィール編集',
    write: '掲示板書き込み。',
    completed: '修正されました。',
    failed: '保存に失敗しました。再度行ってください。',
    wrong_url: 'アドレスが間違っています。',
  },

  studio: {
    bank_account_required_product: '口座情報を登録しなければ、商品を作成できません。',
    title: 'クリエイタースタジオ',
    marpple_seller: 'Marpple Shopクリエイター',
    type_mobile: 'アカウント情報で電話番号を入力してください。',
    '아쉽게도 이번에는 마플샵 셀러로 모시지 못하게 되었습니다.':
      '残念ながら今回はMarpple Shopクリエイターとしてお迎えできなくなりました。&nbsp;<span class="m_br"></span>今後、より良い機会にお会いできることを楽しみにしています。',
    need_allow:
      'ショップを作り上げて商品を作ってください。&nbsp;<span class="m_br"></span>著作権関連のすべての法的責任はクリエイターにあります。',
    possible_device_change: '機種変更可能',
    select_category: '商品カテゴリー選択',
    shop_admin: {
      발급: '발급',
      'API Key 발급': 'API Key 발급',
      'Key 발급일시': 'Key 발급일시',
      '연동 컨설팅사': '연동 컨설팅사',
      '외부 서비스와의 연동을 관리하는 페이지 입니다.': '외부 서비스와의 연동을 관리하는 페이지 입니다.',
      '복사되었습니다.': '복사되었습니다.',
      '약관 동의': '약관 동의',
      '약관 보기': '약관 보기',
      'REST API Key 발급이 완료되었습니다.': 'REST API Key 발급이 완료되었습니다.',
      'REST API Key 안내':
        '고객님께서 가입하신 연동 컨설팅사에<br />발급된 REST API Key를 복사하여 서비스 연동을<br />등록해 주시기 바랍니다.',
      '이미 발급되었습니다.': '이미 발급되었습니다.',
      '이미 연동되었습니다.': '이미 연동되었습니다.',
      '외부 서비스 연동은 PC에서만 확인 가능합니다.': '외부 서비스 연동은 PC에서만 확인 가능합니다.',
      'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.':
        'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.',
      '연동 컨설팅사 해제': '연동 컨설팅사 해제',
      '연동 컨설팅사와 연동을 해제하시겠습니까?': '연동 컨설팅사와 연동을 해제하시겠습니까?',
    },
    menus: {
      make_products: 'デザイン商品作り',
      products: '商品作り',
      orders: '注文内訳',
      seller_product: 'クリエイター商品',
      real_pb_products: '私の商品登録',
      inventory_management: '在庫管理',
      pb_orders: '注文管理',
      pb_item_orders: '配送管理',
      pb_refund_orders: '払戻管理',
      digital_product: 'デジタル商品',
      make_digital_products: 'デジタル商品作り',
      question_list: 'お客様の管理',
      shop_management: 'ショップ管理',
      styles: 'ショップデザイン',
      arrangement: '商品並び順',
      shop_info: 'ショップ情報',
      ecommerce_info: 'クリエイター情報',
      open_api: 'OPEN API Key',
      adjustments: '精算管理',
      design_adjustments: 'デザイン商品の精算',
      seller_adjustments: 'クリエイター商品精算',
      tpl_adjustments: '入荷商品精算',
      dtg_adjustments: 'デジタル商品精算',
      withdraws: '出金申請',
      bank_account: '口座情報登録',
      analytics: '統計',
      analytics_by_prod: '商品別販売分析',
      my_shop: 'マイショップ',
      youtube: 'ユーチューブで販売',
      seller_guide: 'クリエイターガイド',
      seller_tip: 'クリエイターティップ',
      faq: 'FAQ',
      solution: '申告受付',
      invite: '友だちを招待する',
      creator_community_club: 'クリエイター·コミュニティ·クラブ',
      update_note: 'アップデートノート',
      invite_info: 'クリエイター招待',
      account: 'アカウント情報',
      marketing: 'マーケティング',
      help: 'カスタマーサービス',
      mp_shop: 'MarppleShop',
      contact_marppleshop: 'MarppleShopにお問い合わせ',
      logout: 'ログアウト',
      designer: 'デザイナー',
      adobe_illustrator: `イラストレーター設置`,
    },
    footer: {
      privacy: '個人情報処理方針',
      terms: '利用規約',
      p: 'Marpple Shopコンテンツの著作権は、著作権者または提供先にあり、これを無断使用および盗難する場合、著作権法などに基づき法的責任を負うことがあることをお知らせします。',
    },
    form: {
      title1: 'オプション名',
      title2: '追加金額（選択） ウォン／ドル／円',
    },
    shop_info: {
      p1: '현재 운영중인 샵의 기본 정보들을 관리하는 페이지 입니다.',
    },
    ecommerce_info: {
      p1: '셀러 배송 상품 판매를 위한 정보를 입력하는 페이지 입니다.',
    },
    styles: {
      p1: 'プロフィール・ロゴ・バナーなどを変更してショップをデザインしてください。',
      btn1: 'ショップデザイン変更',
    },
    products: {
      p1: '3分で簡単完成!',
      p2: '今日はどんな商品を作ってみましょうか？',
    },
    real_pb_products: {
      p1: '나만의 상품 등록 시작!',
      p2: '아이디어를 상품으로 만들어 볼까요?',
    },
    digital_products: {
      p1: 'デジタル商品登録開始です！',
      p2: '自分だけのデジタル商品を<br>登録してみましょうか？',
      p3: '販売するデジタル商品をお選びください！',
      p4: 'Marpple Shopで様々なデジタル商品を簡単かつ迅速に販売することができます。',
      cate_wall_paper: '背景画面',
      cate_voice_pack: 'ボイスパック',
      cate_good_note: 'グッドノート',
      cate_digital_asset: 'デジタルアセット',
      header: '製品登録',
      header_modify: '商品修正',
      required: '表示は必須入力が必要な項目です.',
      no_image_info_title: '登録された画像がありません.',
      no_image_info_contents: `
      商品画像として登録する画像をアップロードしてください.<span class="m_br w_br"></span> 画像の推奨サイズ :
      正四角形 740px ~ 1480px<span class="m_br w_br"></span>
      アップロード可能な形式です : jpg, png / 容量 : 5MB 以下
    `,
      image_upload_btn: '画像をアップロード',
      set_first: '代表画像に指定',
      recommend_size:
        '画像推奨サイズ : 正四角形 740px ~ 1480px / アップロード可能な形式です : jpg, png / 容量 5MB 以下',
      delete_image: '画像を削除',
      btn_register: '製品登録',
    },
    make: {
      p1: '商品を選んでデザインしてください。',
      p2: '高級ブランド製品で、最適の印刷方式を使用します。',
      p3: '様々なカテゴリーが準備されています。',
      p4: 'Marpple Shopは継続的にアップデートされています。',
    },
    invite: {
      p1: 'Marpple Shopが必要な友人にクリエイター招待状を送ってください。',
      p2: 'サンプル購入時に使用できる5,000ポイントをお二人に差し上げます。',
      p3: '{{name}}様の招待状を受け取った方には、申請課程なしですぐにショップを開ける特典とサンプル購入に使用できる5,000ポイントを差し上げます。',
      p4: '招待された方が商品を作って活動を開始すると、{{name}}様に感謝の気持ちを込めて5,000ポイントを差し上げます。',
      p5: '現在残りの招待状は計<span class="count">{{count}}</span>枚です。',
      p6: '招待する方の名前やニックネームを書いてください。',
      b1: '招待完了',
      t1: 'メールで招待する',
      t2: '招待リンク生成',
      l1: '招待状を送る',
      l2: '招待リンク生成',
      l3: 'リンクコピー',
      '이메일 주소를 확인해 주세요.': 'メールアドレスを確認してください。',
      '이메일 주소가 맞는지 확인해 주세요':
        'メールアドレスが合っているか確認してください。招待状をお送りになりますか？',
      '초대 링크를 생성하시겠습니까?': '{{name}}様のための招待リンクを生成しますか？',
    },
    invite_info: {
      p1: '{{name}}様が、あなたをMarppleShopの出品者として招待しました！',
      p2: 'ショップを作成して商品を作成すると、印刷適合度、著作権イシューなどを簡単にご検討した後、速やかに承認いたします。',
      p3: '{{name}}様の招待特典として、サンプルのお買い上げで使用できる5,000ポイントも支給致します。',
      p4: '必ずご確認ください!<br/>ご招待いただいた新出品者様にて本格的な活動に向けては、販売承認が必要です。<br >販売承認はショップデザインと商品を作っていただいた後、お待ちいただければ、ポイント支給のご案内とともにメールでご案内いたします。<br >(販売承認には2営業日~4日かかります)',
      t1: 'MarppleShopが気になりますか？',
      t2: '他の出品者様のMarppleShopが気になりますか？',
      t3: 'MarppleShopオープンのため、以下の情報を入力してください。',
      l1: 'MarppleShopホームを見に行く。',
      la1: 'ショップ名',
      la2: '携帯電話番号',
      la3: 'MarppleShopドメイン名(英文数字2文字以上20文字以内)',
      la4: 'SNS url(選択)',
    },
    marketing: {
      p1: 'ネイバーショッピング公開同意',
      agree: '同意',
      not_agree: '未同意',
      modify: '変更',
    },
    account: {
      p1: 'クリエイター様のアカウント情報は暗号化され、安全に保存されます。',
      email: 'メール',
      save_changes: 'メール変更',
      pw: 'パスワード',
      reset_pw: 'パスワード変更',
      name: '名前',
      brand_name: 'ブランド名',
      phone: '携帯電話番号',
      store_url: 'ショップURL',
      modify: '変更',
      e1: '有効なドメインを入力してください。',
      my_point: '保有ポイント',
    },
    orders: {
      p1: '1週間に発生した注文内訳を確認することができます。',
      t1: '週間商品別販売分析',
      empty: 'あ、まだ注文内訳がありません。',
    },
    analytics: {
      p1: '本人が作った商品の販売量順位を確認してください。',
    },
    table: {
      th1: '注文期間',
      th2: '注文数',
      th3: '注文取消数',
      th4: '数量',
      th5: '取消数量',
      th6: '収益',
      th7: '追加収益',
      th8: '総収益',
      th9: 'クリエイター収益',
      th10: '企業収益',
      th11: '順位',
      th12: '商品名',
      th13: '販売量',
      th14: '公開ステータス',
      th15: '精算日',
      th16: '精算金額',
      th16_1: 'PG社手数料',
      th16_2: 'MarppleShop手数料',
      th16_3: '販売手数料',
      th16_4: '入庫手数料',
      th17: '進捗ステータス',
      th18: '合計',
      th19: '種類',
      th20: '日付',
      th21: 'ショップ',
      th22: 'デザイン収益',
      th23: 'デザイン追加収益',
      th24: 'デザイン総収益',
      th25: 'クリエイターデザイン収益',
      th26: '企業デザイン収益',
      th27: '企業割引収益',
      th28: '企業総収益',
      th29: '総出金額',
      th30: '申請日時',
      th31: '出金申請金額',
      th32: '実入金額',
      th33: '入金予定日',
      th34: '入金日時',
      th35: '取消日時',
      th36: '事由',
      th37: '処理ステータス',
      th38: '金額',
      th39: '口座情報',
      th40: '処理日時',
      th41: '機能',
      th42: '名前',
      th43: 'ステータス',
      th44: '消費者販売金額',
    },
    adjustments: {
      long_format: 'yyyy年 M月',
      short_format: 'yyyy-MM',
      p1: '精算内訳は、出荷された日付を基準に毎月算定されます。',
      p2: '셀러 상품의 정산 내역은 구매확정일을 기준으로 월별 산정됩니다.',
      btn1: '日付変更',
      release: '出荷',
      confirmed: '購買確定',
      change: '変更',
      shipping: '送料',
      release_date: '出庫日',
    },
    withdraws: {
      p1: '1カ月間に発生した販売収益を出金することができます。',
      p1_1: '累積精算金額を出金申請することができます。',
      p2: '出金可能金額',
      p3: '企業出金申請金額を確認し、出金申請をしてください。',
      p4: 'クリエイター収益による出金申請金額を確認し、精算を進めてください。',
      t1: 'クリエイター出金',
      btn1: '出金申請',
      btn2: '修正',
      btn3: '処理',
      btn4: '承認処理',
      btn5: 'エクセルダウンロード',
      btn6: '日付変更',
      approve: '承認',
      finish: '完了',
      cancel: '取消',
      pending: '処理中',
      tt_title: '実入金額',
      lb1: '総入金額',
      lb2: '残額',
      opt1: '検証可否',
      opt2: '検証された出金',
      opt3: '検証されない出金',
      opt4: '口座種類',
    },
    arrangement: {
      only_pc: `商品並び順機能はPCでのみ可能です。`,
      desc: `マイショップで販売中の商品をドラッグして、好きなように商品の並び順を変更してください。`,
      desc2: `ここに商品をドラッグして保管し、再び左側のショップエリアに移動させると、簡単に並び順を変更することができます。`,
    },
    tooltip: {
      h2: `
<h4>굿즈 정산</h4><div>출고 날짜 기준으로 월별 산정됩니다.</div><br/>
<h4>셀러 상품 정산{{tpl_adjustments}} & 디지털 상품 정산</h4><div>구매확정일을 기준으로 월별 산정됩니다.</div>`,
      t1: '一週間の販売数量に応じて製作費を割引して追加収益を上げます。 デザイン価格設定時、その製品の追加収益が自動的に算出されます。',
      t2: `<h4>デザイン商品の精算</h4><div>デザイン価格＋追加収益－PG社手数料(3.52%)=精算金額<br/>事業者の場合、税金計算書の発行後に出金可能です。</div>`,
      t3: `収益 - MarppleShop手数料 - PG社手数料(3.52%) = 精算金額`,
      t3_1: `수익 - 입고 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t3_2: `수익 - 판매 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t4: `1週間の販売数量により制作費が割引となります。自分の収益を設定すると、<br/>1つにつき制作費割引金額と自分の収益が自動的に図表に算出されます。`,
      t5: '商品を限定的に販売したい場合に設定します。チェックボックスを選択しない場合、常時販売されます。<br/>限定数量と期間を設定することができ、設定する場合、限定版製品グッズとして商品の希少性を高めることができます。',
      'PG사 수수료': 'PG社手数料',
      'PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.':
        'PG社の手数料3.2%に10%の付加価値税を加えた価格です',
      '셀러 상품 정산': 'クリエイター商品精算',
      t6: `<div>デザイン商品計算金額+クリエイター商品精算金額=出金予定金額</div><br/><h4>デザイン商品計算金額とは?</h4><br/><div> - 個人出品者<div>デザイン商品精算金額 - 付加価値税(10%) - 源泉徴収税(3.3%)<br/>=デザイン商品計算金額</div><br/> - 事業者出品者<div>デザイン商品精算金額=デザイン商品計算金額<br/>※事業者の場合、税金計算書発行後に出金が可能です。</div></div>`,
    },
    youtube: {
      '유튜브에서 판매': 'Sold at Youtube',
      '유튜브에서도 마플샵 상품을 판매해 보세요.': 'Sell the goods of MarppleShop in Youtube as well.',
      '유튜브 채널 ID를 입력해 주세요.': 'Enter the Youtube Channel ID.',
      '이미 등록된 유튜브 채널 ID 입니다.': 'Already registered YouTube channel ID.',
      '올바른 채널 ID를 입력해 주세요.': 'Enter the right channel ID.',
      '새로고침 후 다시 시도해 주세요.': 'Please refresh and try again.',
      '유튜브 스튜디오에서 상품을 공개해 주세요.': 'Disclose the goods on the Youtube Studio.',
      '채널 ID 확인': 'Check Channel ID',
      '채널 ID 삭제': 'Delete Channel ID',
      '저장되었습니다.': 'Channel ID saved',
      '유튜브 상품 기능 사용 조건을 확인해 주세요.':
        'Check the condition that you can use the Youtube goods’ function',
      'delete-confirm':
        'Your YouTube account will be disconnected after 1-3 days. Are you sure you want to delete your channel ID though?',
      '셀러 스튜디오에 공개 중인 상품만 연동 프로세스가 진행됩니다.':
        'Only the goods disclosed on the Seller Studio is interlocked to the Youtube account.',
      '유튜브 팀에서 1~3일간 1차 심사를 완료하면 유튜브 스튜디오에서 상품을 확인할 수 있습니다.':
        'If the initial screening is complete by the Youtube team for 1~3 days, you may see the goods on the Youtube Studio.',
      '만 18세 이상 & 구독자 수 1,000명 이상이어야 합니다.':
        'You should be 18 years old or over, and the number of the subscriber to your channel should be more than 1,000.',
      'info-2':
        'You can post the goods on the bottom of your video, store tab, and live broadcast, etc.{{- br}}If the 2nd screening is complete by the Youtube team for 1~5 days, the goods will be disclosed to the viewers and you may sell the goods on Youtube.',
      'correct-info': 'You can check it on the "{{- check}}".',
      'YouTube 스튜디오 > 수익 창출 > 쇼핑에서 스토어 연결을 눌러 MarppleShop과 YouTube 계정을 연결해 주세요.': `Link your YouTube account with MarppleShop through "Connect Shop" in YouTube Studio→Monetization→Shopping`,
      'YouTube 스튜디오에서 노출하고 싶은 상품을 선택해 주세요': `Please select the products you want to display in YouTube Studio`,
      '채널에 제품 표시에 있는 메뉴를 눌러서 채널에 표시하고 싶은 상품을 선택해 주세요': `Select the product you want to display on the channel by clicking the menu in 'display products on channel'`,
      '(설정에 따라 하단에 표시되는 메뉴가 다를 수 있습니다)':
        '(The menu displayed at the bottom may differ depending on the setting)',
      '채널에 제품 표시에 있는 토글을 활성화 해주셔야 합니다.':
        'You will need to enable the toggle under Show Products on Channel.',
      '토글을 끄면 상품이 보이지 않습니다.': `If the toggle is turned off, the product will not be visible.`,
      '왼쪽에서 표시하기를 원하는 상품을 골라 오른쪽으로 드래그 해주세요.':
        'Select the product you want to display from the left and drag it to the right.',
      '상품은 총 30개까지 채널에 노출할 수 있습니다.': `A total of 30 products can be displayed on a channel.`,
      '채널 연동 후 1~3일 정도 지나면 YouTube 스튜디오 > 수익 창출 > 쇼핑에서 마플샵 상품을 확인하실 수 있습니다.': `After 1-3 days after linking the channel, you can see that the MappleShop products are displayed on YouTube.`,
      '※ 유의사항': `※ Precautions`,
      '유튜브 상품 연동을 위해서 마플샵 상품을 공개 상태로 설정해주세요.': `To link YouTube products, set the MappleShop product to public.`,
      '비공개 상품의 경우 유튜브 채널에 노출되지 않습니다.': `Private products are not exposed on the YouTube channel.`,
      '상품이 품절이 되어도 유튜브 채널에 노출되지 않습니다.': `Even if the product is out of stock, it will not be displayed on the YouTube channel.`,
      '영상 별로 노출하고 싶은 상품을 고를 수 있습니다.': `You can select the products you want to display for each video.`,
      'YouTube 영상을 업로드 할 때 YouTube 스튜디오→콘텐츠→세부 정보→수익창출에서 노출하고 싶은 상품을 선택할 수 있습니다.': `You can select the products you want to display in YouTube Studio→Contents→Details→Monetization`,
      '먼저 채널 콘텐츠의 수익 창출에서 쇼핑 탭을 눌러주세요. 이곳에서 어떤 상품을 노출할지 선택할 수 있습니다.': `Click the Shopping tab in Monetization. You can set which products to display.`,
      'a. 맞춤 설정 : 영상 별로 노출하고 싶은 상품을 고르고 싶을 때 사용합니다.': `a. CUSTOMIZE : You can select the product you want to display for each video.`,
      'b. 채널 선택사항 사용 : 채널에서 노출하고 있는 상품 설정 그대로 사용합니다.': `b. USE CHANNEL SELECTION : Products are displayed in the way previously set by the user.`,
      'c. 제품 세션 토글 : 토글을 끄면 해당 영상에서만 상품이 보이지 않습니다.': `c. PRODUCT SECTION : If the toggle is turned off, the product is not visible only in the video.`,
      'YouTube Shopping 기능 자세히 알아보기': `Check more about YouTube Shopping features`,
      '이미 등록 되어 있는 채널입니다. 연결된 샵에서 연동 해제 후 다시 등록해주세요.':
        'A channel that is already registered. Please unlink it from the connected shop and register again.',
    },
    pc_info: {
      '셀러 스튜디오를 PC에서 이용해 보세요': 'クリエイタースタジオをPCでも利用してください。',
      '스마트폰뿐 아니라 PC에서도 손쉽게 이용 가능해요 !':
        'スマートフォンのみならず、<br/>PCでも簡単に利用できます！',
    },
  },

  unsupported_browser: {
    title_for_ie: 'Internet Explorer<br/>サポート中断案内',
    description_for_ie: `Microsoftでインターネットエクスプローラのセキュリティ更新サポートを中断することにより、マルウェアおよびマイコンピュータの重要情報の露出など、セキュリティが脆弱になる可能性があります。高速かつ安全な最新ブラウザを使用してください。`,
    description_for_another_browser:
      'Internet Explorerを使用してこのページをご覧の場合、ブラウザを最新バージョンにアップデートしてください。',
    recommended_pc_browser: 'おすすめのPCブラウザ',
    recommended_mobile_browser: 'スマートフォンですぐに利用してください。',
    description_for_mobile_phone: {
      creator:
        'クローム、Safariなどモバイルで「Marpple Shop」を検索するか、アドレスバーにhttps://marpple.shop/を入力してください。',
      mp: 'クローム、Safariなどモバイルで「Marpple」を検索するか、アドレスバーにhttps://www.marpple.com/を入力してください。',
      line: 'クローム、Safariなどモバイルで「LINE FRIENDSクリエイター」を検索するか、アドレスバーにhttps://creator.linefriends.com/を入力してください。',
    },
    description_to_using_app: {
      creator: 'クリエイターグッズショップ、Marpple Shopを利用してください。',
      mp: '私の手の中のプリンティング、Marppleアプリを利用してください。',
    },
  },

  mp_app: {
    android_fb_login:
      '[Facebookログイン]が一時的にアンドロイドアプリで動作しません。 ウェブ ブラウザで [Facebook ログイン] を試してみてください。',
  },

  maker_text_editor: {
    '문자 간격': '文字間隔',
    '텍스트 입력': 'テキスト入力',
    텍스트: 'テキスト',
    '서체 선택': 'フォント',
    '글씨 색상': '文字の色',
    폰트: 'フォント',
    '텍스트 편집': 'テキスト編集',
  },

  maker_color_editor: {
    색상: 'カラー',
  },
  maker_path_editor: {
    패스: 'パス',
    '패스 편집': 'パスの編集',
    '도형 편집': '模型の編集',
  },
  maker_border_editor: {
    두께: '厚さ',
    테두리: '枠',
    '테두리 색상': '枠の色',
    점선: '点線',
  },

  maker_background_color_editor: {
    '배경 색상': 'Background Color',
  },

  maker_error_alert: {
    '사진 권한 엑세스':
      'アプリケーション設定で写真へのアクセスを許可してください。<br>同じ問題が引き続き発生する場合は、Webブラウザをご利用ください。',
    '문제가 발생했습니다. 다시 시도해 주세요.': '問題が発生しました。 もう一度やり直してください。',
    '문제가 발생했습니다. 디자인을 다시 확인해주세요.': '問題が発生しました。',
    'PNG 이미지를 해석할 수 없습니다.': 'PNGイメージの解析はできません。',
    'SVG 파일을 해석할 수 없습니다.': 'SVGファイルを解析できません。',
    'SVG 객체를 찾을 수 없습니다.': 'SVGオブジェクトが見つかりません。',
    '이미지를 그리는 데 실패했습니다.': 'イメージを描くのに失敗しました。',
    fail_upload_img: 'イメージをアップロードすることに失敗しました。 もう一度やり直してください。',
    too_large_file: 'ファイル容量が大きすぎます。<br />25MB 以下でアップロードしてください。',
    too_small_img: '良い品質で印刷するためには最低500px以上のイメージが必要です。',
    too_large_img: '8000px以下のイメージに変更してアップロードしてください。',
    too_large_img2: '横*縦が16,777,216px以下のイメージに変更してアップロードしてください。',
    '앗! 죄송합니다. 다시 시도해 주세요.': 'あっ、すみません。 もう一度やり直してください。',
    '이 기능이 지원되지 않는 상품입니다.': 'こちらの機能に対応していない商品です。',
    '앗! 제품을 만들지 않으셨어요.': 'あっ！製品を作っていません。😂',
    '임시 저장된 디자인을 불러오겠습니까?': '一時保存されたデザインを読み込みますか？',
    '크기에 맞춰 가격이 책정되었어요.': 'サイズに応じて価格が<br>設定されています。',
    '선택된 색상으로 패턴을 만들수 없습니다.': '選択した色でパターンを作られません。',
    '확인했습니다.': '確認しました。',
    '점선 밖으로 디자인을 위치 시킬수 없습니다.': '点線の外側にデザインを配置する事はできません。',
    '패치 위에 다른 다지인을 올릴 수 없습니다.': 'パッチの上に他のデザインを載せることはできません。',
    '특수 색상 글씨 위에 다른 다지인을 올릴 수 없습니다.':
      '特殊な色の文字上に他のデザインを重ねる事はできません。',
    'This is a temporary error. please try again.': '一時的なエラーです。もう一度お試しください。',
    'We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.':
      'We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.',
    'The selected background color is removed.': '選択された背景の色は削除されます。',
    'Pattern is not supported for this product. Pattern image is automatically removed.':
      'この製品にはパターンの対応ができません。<br/>使用されたパターンは自動的に削除されます。',
    '시스템 글꼴이 만들기툴에 영향을 줌': `The font design may be impacted by fonts in your web browser.<br>Because a font could appear differently on the screen, we ask that you use {{browsers}}.`,
    '시스템 글꼴이 마플샵 고객 뷰에 영향을 줌': `This product is only available for purchase via {{browsers}}. For purchase, please check it out on the {{browsers}}.`,
    '시스템 글꼴이 마플 고객 뷰에 영향을 줌': `The font design may be impacted by fonts in your web browser.<br>Because a font could appear differently on the screen, please use the {{browsers}} and re-design it.`,
    '품절되었습니다.': 'Out of stock.',
    '해당 상품은 태블릿에서 모바일 화면으로만 작업이 가능합니다.':
      'タブレット PC では、この製品はモバイル画面でのみ使用できます。<br>モバイル画面に切り替わります。',
    'PC 화면으로 전환됩니다.': 'PC画面に切り替わります。',
    '정보가 변경되어 새로고침 합니다.': '製品情報が正しくありません。<br>ページがリロードされます。',
    '해당 글자를 제공하지 않는 폰트입니다.': 'このフォントでは使用される文字は提供されません。',
    '자수 글자수 제한': 'この商品は最大{{char_length_in_line}}文字、二行まで刺繍印刷が可能です。',
    '화면을 크게해서 다시 시도 해주세요.': '画面を大きくしてもう一度試してください。',
  },
  error_alert: {
    reload:
      '問題が発生し、更新されます。<br>引き続き発生する場合は、カスタマーセンターにお問い合わせください。',
  },
  maker_upload_type: {
    '만들기 툴로 쉽게 제작': 'ツールで簡単に製作',
    '디자인 파일 업로드': 'プロなら? 直接製作',
    '내 디자인 업로드하기': 'プロなら? 直接製作',
    '디자인 업로드': 'デザインアップロード',
    '전문가용 제작하기': 'プロ向けに製作する',
    '내 디자인 파일로 만드는 방법': '自分のデザインファイルで作成する方法',
    '템플릿 다운로드': 'テンプレートダウンロード',
    '템플릿 다운로드 / 도안 업로드': 'テンプレートダウンロード / 図案アップロード',
    '도안 업로드': '図案アップロード',
    '일러스트레이터 버전': 'Illustrator 2021以降のバージョンでのみ使用可能。',
    '첨부 파일 형식: SVG (10MB 이하)': '添付ファイル形式 : SVG (10MB 以下)',
    '위 템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.':
      '上記ののテンプレートファイルのガイドに沿ってデザイン作業を行ってください。',
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 키링을 만들어 보세요.': `テンプレートをダウンロードして<br>図案を製作し自分だけの素敵なアクリルキーホル<br>ダーを作ってみてください。`,
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 스탠드를 만들어 보세요.': `テンプレートをダウンロードして<br>図案を製作し自分だけの素敵なアクリルスタンドを<br>作ってみてください。`,
    '커스텀하는 방법': 'カスタマイズ方法',
    '전문가용 에디터를 활용해 제작한 도안은 PC에서 확인하실 수 있어요.':
      'プロ向けエディターを活用して製作された図案はPCからご確認いただけます。',
    '템플릿을 다운로드하시고 직접 나만의 아크릴 키링을 만들어 보세요.': `テンプレートをダウンロードして<br />
    自分だけの素敵なアクリルキーホルダーを作ってみてください。`,
    '템플릿을 다운로드하시고 직접 나만의 아크릴 스탠드를 만들어 보세요.': `テンプレートをダウンロードして<br />
    自分だけの素敵なアクリルスタンドを作ってみてください。`,
    maker_upload_type_01: 'プロ向けエディターで製作された<br>デザインはPCからご確認ください。',
    maker_upload_type_02: 'このデザインはプロ用エディタで製作しました。<br>PCで修正することができます。',
    '템플릿은 일러스트레이터 CS6 이상에서만 사용 가능합니다.': '',
    '첨부 파일 형식 : PDF (100MB 이하)': '',
    '템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.': '',
    '만들기 툴은 곧 제공 예정이에요!': '',
  },

  maker_basic_word: {
    사이즈: 'サイズ',
    가격: 'お値段',
    '스탠드 포함': '台付き',
  },

  maker_unlock_editor: {
    Unlock: 'ロック解除',
  },

  maker_upload_image_editor: {
    '이미지 업로드': 'アップロード',
    '업로드 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일이며 25MB 이하입니다.':
      'アップロードできるファイル: JPEG, PNG, SVG (最大: 25MB)',
    '제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드 (CMYK/RGB)에 따라 차이가 발생할 수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작원에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지 않음을 확인합니다.':
      '画像をアップロードすることにより、当人にその画像を使用する法的権利があるということに同意したこととなります。詳しくはプライバシーポリシーおよびガイドラインをご覧ください。',
    '이미지 올리기': 'アップロード',
    '내 이미지 보기': 'ライブラリー',
  },

  marpple_shop_detail_alert: {
    '컬러 없음': `There is no selected color for this model. 😂`,
  },

  marpple_shop_select_page: {
    'Please select at least one thumbnail.': 'サムネイルを1つ以上ご選択してください。',
    'Please select 5 or fewer thumbnails.': 'サムネイルの選択は5つまで可能です。',
    'Thumbnails are being created.': 'サムネイルは製作中です',
    'Please, wait for a moment.': '少々お待ちください。',
  },

  tokenGate: {
    서명: '署名',
    tg001: '認証が満了しました。',
    tg002: '上記NFTがすべて必要です。',
    tg003: '{{storeName}}に戻ります。',
    tg004: 'この署名は、商品を購入するための認証手続きであり、いかなる費用も発生しません。',
    tg005: '',
    tg006: '上記NFTのうち1つ以上のNFTが必要です。',
    tg007: `Open Android settings , search "default" and select "Choose Default apps" > "Choose Default apps" > Opening Links > scroll down to MetaMask and tap it > Supported Web addresses > check if links are selected or "verified/trusted" by default (most importantly metamask.app.link). If they are not, then check them/it`,
    tg009: 'NFT認証して購入する',
    tg010: 'NFT認証',
    tg010_1: 'NFT認証',
    tg011: 'ホルダーのみ購入可能',
    tg012: '登録済みアドレスです。',
    tg013: '住所をチェックしてください。',
    tg014: 'コントラクトアドレスを追加すると、該当NFTの所有者のみ商品を購入することができます。',
    tg015: '全部',
    tg016: '一つだけ',
    tg017: 'トークンゲーティング設定\n',
    tg018:
      'NFTコントラクトアドレスを入力すれば、該当NFTの所有者のみ商品を購入できるように設定することができます。 現在、イーサリアムとポリゴンチェーンを支援しています。',
  },

  youtube: {
    '유튜브 쇼핑 공식 플랫폼 마플샵': 'YouTube ショッピング 公式プラットフォーム マープルショップ',
    '마플샵과 함께 유튜브 쇼핑을 시작하세요. 유튜브 영상, 쇼츠, 라이브에서 내 상품 또는 브랜드 제품을 전시하고 판매할 수 있습니다. 상품 제작부터, 배송, CS까지 원스톱 커머스 솔루션을 제공합니다.':
      'マープルショップと一緒にユーチューブショッピングを始めてください。 YouTubeの動画、ショーツ、ライブで自分の商品またはブランド品を展示および販売することができます。 商品製作から、配送、CSまでワンストップコマースソリューションを提供します。',
    '유튜브 쇼핑 플랫폼 마플샵': '유튜브 쇼핑 플랫폼 마플샵',
    '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.':
      '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.',
    '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.':
      '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.',
    '자격 요건이 충족되어야 연동할 수 있어요.': '자격 요건이 충족되어야 연동할 수 있어요.',
    '약관 동의하러 가기': '약관 동의하러 가기',
  },

  cart_modal: {
    quantity: '数量',
    일시품절: '品切れ',
  },
  global_port_one: {
    Failed: '支払いに失敗しました。{{error_msg}}',
    Expired: '支払い時間が期限切れになりました。{{error_msg}}',
    EtcError: `決済要請が完了できませんでした。{{error_msg}}`,
  },
  payment: {
    error1: '決済に失敗しました。',
    error2: 'すでにキャンセルされた注文書です。',
  },
};
