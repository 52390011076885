import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPReviewDetailTmplS } from '../../S/Tmpl/module/OMPReviewDetailTmplS.js';
import { OMPReviewDetailMuiF } from '../Mui/module/OMPReviewDetailMuiF.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';

export const openFrame = (review) =>
  OMPCoreUtilF.openFrameFit(OMPReviewDetailMuiF.frame, (frame, page, [tab]) => {
    const params = new URLSearchParams({ r_id: review.id });

    frame.title = TT('review::detail::detail_01');
    frame.hide_frame_button_type = 'X';
    frame.always_remove = true;
    frame.animation = true;
    frame.showed = () =>
      MuiF.extendHistoryState(
        null,
        null,
        `${window.location.origin}/${TT.lang}/review/detail?${params.toString()}`,
        true,
      );
    tab.makeData = () => review;
    tab.inner_scroll_target = '.omp-review-detail__body-content';
    if (OMPCoreUtilF.isMobile()) {
      frame.el_class = 'omp-frame-no-title omp-frame-right-button';
      tab.template = OMPReviewDetailTmplS.reviewDetailTmplMo;
    } else {
      tab.template = OMPReviewDetailTmplS.reviewDetailTmplPc;
    }
  });
