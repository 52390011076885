import { langTo_en } from '../../../../Util/S/LangAndCollaboType.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { needAdditionalPayment } from '../../../../Projection/S/fs.js';
import { strMap } from 'fxjs/es';

export const makePrjPaymentInfoHtml = (p, _en = langTo_en(p.lang)) => legacyHtml`
  <div class="payment_info_projection" lang="${p.lang}">
    <div class="left">
      <table>
        <tr>
          <th>상품 수량</th>
          <td>${UtilS.commify(p.quantity)} 개</td>
        </tr>
        <tr>
          <th>상품 금액</th>
          <td class="mp_currency">${PriceS.pricify_by(p['product_price' + _en], _en)}</td>
        </tr>
        <tr>
          <th>배송비</th>
          <td class="mp_currency">${PriceS.pricify_by(p['shipping_price' + _en], _en)}</td>
        </tr>
        <tr style="${PriceS.price(p['coupon_price' + _en]) < 0 ? '' : 'display: none;'}">
          <th>쿠폰 할인</th>
          <td class="mp_currency_minus">
            ${PriceS.pricify_by(Math.abs(PriceS.price(p['coupon_price' + _en])), _en)}
          </td>
        </tr>
        <tr style="${p.lang == 'kr' && PriceS.price(p['point_price' + _en]) < 0 ? '' : 'display: none;'}">
          <th>포인트 할인</th>
          <td class="mp_currency_minus">
            ${PriceS.pricify_by(Math.abs(PriceS.price(p['point_price' + _en])), _en)}
          </td>
        </tr>
        <tr style="${PriceS.price(p['other_price' + _en]) != 0 ? '' : 'display: none;'}">
          <th>추가금</th>
          <td>${PriceS.price(p['other_price' + _en])}</td>
        </tr>
        <tr style="${PriceS.price(p['other_shipping_price' + _en]) != 0 ? '' : 'display: none;'}">
          <th>추가 배송비</th>
          <td>${PriceS.price(p['other_shipping_price' + _en])}</td>
        </tr>
        <tr>
          <th>주문 금액</th>
          <td class="mp_currency">${PriceS.pricify_by(p['total_price' + _en], _en)}</td>
        </tr>
        <tr style="${p.is_cancel_req ? '' : 'display: none;'}">
          <th>주문 취소 금액</th>
          <td class="mp_currency_minus">${PriceS.pricify_by(p['total_price' + _en], _en)}</td>
        </tr>
      </table>

      <table>
        <tr>
          <th>결제한 총액</th>
          <td class="mp_currency">${PriceS.pricify_by(p['paid_amount' + _en], _en)}</td>
        </tr>
      </table>
    </div>
    <div class="right">
      <table
        style="${
          PriceS.add(
            p['refund_price' + _en],
            p['refunded_price' + _en],
            p.refunded_point,
            p['refunded_price' + _en],
          ) != 0
            ? ''
            : 'display: none;'
        }"
      >
        <tr style="${PriceS.price(p['refund_price' + _en]) != 0 ? '' : 'display: none;'}">
          <th>무조건 환불액</th>
          <td class="mp_currency">${PriceS.price(p['refund_price' + _en])}</td>
        </tr>
        <tr style="${PriceS.price(p['refunded_price' + _en]) != 0 ? '' : 'display: none;'}">
          <th>환불한 금액</th>
          <td class="mp_currency">${PriceS.pricify_by(p['refunded_price' + _en], _en)}</td>
        </tr>
        <tr style="${PriceS.price(p.refunded_point) != 0 ? '' : 'display: none;'}">
          <th>포인트로 환불</th>
          <td class="mp_currency">${PriceS.pricify(p.refunded_point)}</td>
        </tr>
        <tr style="${PriceS.price(p['refunded_price' + _en]) != 0 ? '' : 'display: none;'}">
          <th>환불한 총액</th>
          <td class="mp_currency">
            ${PriceS.pricify_by(PriceS.add(p['refunded_price' + _en], p.refunded_point), _en)}
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <th>미수금</th>
          <td class="mp_currency">${PriceS.pricify_by(Math.max(p['amount' + _en], 0), _en)}</td>
        </tr>
        <tr>
          <th>미지급금</th>
          <td class="mp_currency">${PriceS.pricify_by(Math.abs(Math.min(p['amount' + _en], 0)), _en)}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="payment_info_payments" lang="${p.lang}">
    <div class="additional_payment" style="${needAdditionalPayment(p) ? '' : 'display: none;'}">
      추가 결제 대기 중 ...
    </div>
    <div class="body">
      ${strMap(
        (p_payment) => legacyHtml`
          <div
            class="payment"
            _sel="./_->projection_payments->(#${p_payment.id})"
            pay_method="${p_payment.pay_method}"
          >
            <div class="title">
              <div class="id">#${p_payment.id}</div>
              <div class="type ${p_payment.type}${p_payment.pay_method}"></div>
            </div>
            <div class="paid_amount">
              <table>
                <tr>
                  <th>결제 금액</th>
                  <th class="without_bank">입금자 정보</th>
                  <th class="without_bank">입금은행</th>
                  <th>결제한 금액</th>
                  <th>미수금</th>
                  <th>결제 상태</th>
                  <th>결제한 시각</th>
                  <th>환불한 금액</th>
                  <th>생성</th>
                </tr>
                <tr>
                  <td class="mp_currency">${PriceS.pricify_by(p_payment['total_price' + _en], _en)}</td>
                  <td class="without_bank deposit_user_name">${p_payment.deposit_user_name}</td>
                  <td class="without_bank bank">${p_payment.bank_name}</td>
                  <td class="mp_currency">${PriceS.pricify_by(p_payment['paid_amount' + _en], _en)}</td>
                  <td class="mp_currency">${PriceS.pricify_by(p_payment['amount' + _en], _en)}</td>
                  <td class="is_paid">${p_payment.is_paid ? '결제 완료' : '결제 대기'}</td>
                  <td class="paid_at">
                    ${p_payment.paid_at ? moment(p_payment.paid_at).format('YY.MM.DD HH:mm') : '결제대기중'}
                  </td>
                  <td class="mp_currency">${PriceS.pricify_by(p_payment['refund_amount' + _en], _en)}</td>
                  <td>${moment(p_payment.created_at).format('YY.MM.DD HH:mm')}</td>
                </tr>
                <tr style="${p_payment._.bks.length ? '' : 'display: none;'}">
                  <td><b>입급 내역</b></td>
                  <td colspan="8" style="padding: 8px;">
                    <div
                      class="bks"
                      _sel="./_->bks"
                      style="${p_payment._.bks.length ? '' : 'display: none;'}"
                    >
                      <table>
                        <tr>
                          <th>#</th>
                          <th>입금시간</th>
                          <th>입금자명</th>
                          <th>입금액</th>
                          <th>은행</th>
                          <th>입금확인</th>
                        </tr>
                        ${strMap(
                          (bk, d = bk.bkdate, t = bk.bktime) => legacyHtml`
                            <tr>
                              <td>${bk.id}</td>
                              <td>
                                ${
                                  d[2] +
                                  d[3] +
                                  '.' +
                                  d[4] +
                                  d[5] +
                                  '.' +
                                  d[6] +
                                  d[7] +
                                  ' ' +
                                  t[0] +
                                  t[1] +
                                  ':' +
                                  t[2] +
                                  t[3]
                                }
                              </td>
                              <td>${UtilS.escape(bk.bkjukyo)}</td>
                              <td>${PriceS.pricify(bk.bkinput)}</td>
                              <td>${G.df.bks.bank(bk)}</td>
                              <td>${bk.projection_payment_id ? '연결됨' : `X`}</td>
                            </tr>
                          `,
                          p_payment._.bks,
                        )}
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="refunds"
              _sel="./_->refunds"
              style="${p_payment._.refunds.length ? '' : 'display: none;'}"
            >
              <h4>환불 카드</h4>
              <table>
                <tr>
                  <th>#</th>
                  <th>환불 요청 금액</th>
                  <th class="imp">아임포트 uid</th>
                  <th class="imp">환불 상태</th>
                  <th class="without_bank bank_code">환불 계좌 은행</th>
                  <th class="without_bank bank_account">환불 계좌 번호</th>
                  <th class="without_bank bank_user_name">환불 예금주</th>
                  <th class="without_bank">환불 상태</th>
                  <th>환불 완료 시간</th>
                  <th>환불자</th>
                  <th>생성</th>
                </tr>
                ${strMap(
                  (refund) => legacyHtml`
                    <tr class="refund" _sel="./(#${refund.id})">
                      <td>${refund.id}</td>
                      <td class="mp_currency">${PriceS.pricify_by(refund['price' + _en], _en)}</td>
                      <td class="imp">${p_payment.imp_uid}</td>
                      <td class="imp is_imp_cancel ${refund.is_imp_cancel}">
                        ${refund.is_imp_cancel ? '환불 완료' : '환불 필요'}
                      </td>
                      <td class="without_bank bank_code">
                        <select name="bank_code">
                          <option value="">은행선택</option>
                          <option value="04" ${refund.bank_code == '04' ? 'selected' : ''}>KB국민은행</option>
                          <option value="23" ${refund.bank_code == '23' ? 'selected' : ''}>SC제일은행</option>
                          <option value="39" ${refund.bank_code == '39' ? 'selected' : ''}>경남은행</option>
                          <option value="34" ${refund.bank_code == '34' ? 'selected' : ''}>광주은행</option>
                          <option value="03" ${refund.bank_code == '03' ? 'selected' : ''}>기업은행</option>
                          <option value="11" ${refund.bank_code == '11' ? 'selected' : ''}>농협</option>
                          <option value="31" ${refund.bank_code == '31' ? 'selected' : ''}>대구은행</option>
                          <option value="32" ${refund.bank_code == '32' ? 'selected' : ''}>부산은행</option>
                          <option value="02" ${refund.bank_code == '02' ? 'selected' : ''}>산업은행</option>
                          <option value="45" ${refund.bank_code == '45' ? 'selected' : ''}>새마을금고</option>
                          <option value="07" ${refund.bank_code == '07' ? 'selected' : ''}>수협</option>
                          <option value="88" ${refund.bank_code == '88' ? 'selected' : ''}>신한은행</option>
                          <option value="48" ${refund.bank_code == '48' ? 'selected' : ''}>신협</option>
                          <option value="05" ${refund.bank_code == '05' ? 'selected' : ''}>외환은행</option>
                          <option value="20" ${refund.bank_code == '20' ? 'selected' : ''}>우리은행</option>
                          <option value="71" ${refund.bank_code == '71' ? 'selected' : ''}>우체국</option>
                          <option value="37" ${refund.bank_code == '37' ? 'selected' : ''}>전북은행</option>
                          <option value="16" ${refund.bank_code == '16' ? 'selected' : ''}>축협</option>
                          <option value="90" ${
                            ['K2', '90'].includes(refund.bank_code) ? 'selected' : ''
                          }>카카오뱅크</option>
                          <option value="89" ${refund.bank_code == '89' ? 'selected' : ''}>케이뱅크</option>
                          <option value="81" ${
                            refund.bank_code == '81' ? 'selected' : ''
                          }>하나은행(서울은행)</option>
                          <option value="53" ${
                            refund.bank_code == '53' ? 'selected' : ''
                          }>한국씨티은행(한미은행)</option>        
                          <option value="92" ${
                            refund.bank_code == '92' ? 'selected' : ''
                          }>토스뱅크</option>                  
                          <option value="35" ${refund.bank_code == '35' ? 'selected' : ''}>제주은행</option>
                          <!-- option value="">-가상계좌자동환불안됨-</option>                
                          <option value="06" ${
                            refund.bank_code == '06' ? 'selected' : ''
                          }>국민은행 구)주택</option>
                          <option value="12" ${refund.bank_code == '12' ? 'selected' : ''}>단위농협</option>
                          <option value="21" ${
                            refund.bank_code == '21' ? 'selected' : ''
                          }>구)조흥은행</option>
                          <option value="22" ${refund.bank_code == '22' ? 'selected' : ''}>상업은행</option>
                          <option value="24" ${refund.bank_code == '24' ? 'selected' : ''}>한일은행</option>
                          <option value="25" ${refund.bank_code == '25' ? 'selected' : ''}>서울은행</option>
                          <option value="26" ${
                            refund.bank_code == '26' ? 'selected' : ''
                          }>구)신한은행</option>
                          <option value="27" ${refund.bank_code == '27' ? 'selected' : ''}>한미은행</option>
                          <option value="38" ${refund.bank_code == '38' ? 'selected' : ''}>강원은행</option>
                          <option value="41" ${refund.bank_code == '41' ? 'selected' : ''}>비씨카드</option>
                          <option value="50" ${
                            refund.bank_code == '50' ? 'selected' : ''
                          }>상호저축은행</option>
                          <option value="54" ${
                            refund.bank_code == '54' ? 'selected' : ''
                          }>홍콩상하이은행</option>
                          <option value="55" ${refund.bank_code == '55' ? 'selected' : ''}>도이치은행</option>
                          <option value="56" ${refund.bank_code == '56' ? 'selected' : ''}>ABN암로</option>
                          <option value="57" ${refund.bank_code == '57' ? 'selected' : ''}>JP모건</option>
                          <option value="64" ${refund.bank_code == '64' ? 'selected' : ''}>산림조합</option>
                          <option value="70" ${
                            refund.bank_code == '70' ? 'selected' : ''
                          }>신안상호저축은행</option>
                          <option value="83" ${refund.bank_code == '83' ? 'selected' : ''}>평화은행</option>
                          <option value="87" ${refund.bank_code == '87' ? 'selected' : ''}>신세계</option>
                          <option value="D1" ${
                            refund.bank_code == 'D1' ? 'selected' : ''
                          }>동양종합금융증권</option>
                          <option value="D2" ${refund.bank_code == 'D2' ? 'selected' : ''}>현대증권</option>
                          <option value="D3" ${
                            refund.bank_code == 'D3' ? 'selected' : ''
                          }>미래에셋증권</option>
                          <option value="D4" ${
                            refund.bank_code == 'D4' ? 'selected' : ''
                          }>한국투자증권</option>
                          <option value="D5" ${
                            refund.bank_code == 'D5' ? 'selected' : ''
                          }>우리투자증권</option>
                          <option value="D6" ${
                            refund.bank_code == 'D6' ? 'selected' : ''
                          }>하이투자증권</option>
                          <option value="D7" ${
                            refund.bank_code == 'D7' ? 'selected' : ''
                          }>HMC투자증권</option>
                          <option value="D8" ${refund.bank_code == 'D8' ? 'selected' : ''}>SK증권</option>
                          <option value="D9" ${refund.bank_code == 'D9' ? 'selected' : ''}>대신증권</option>
                          <option value="DA" ${
                            refund.bank_code == 'DA' ? 'selected' : ''
                          }>하나대투증권</option>
                          <option value="DB" ${
                            refund.bank_code == 'DB' ? 'selected' : ''
                          }>신한금융투자</option>
                          <option value="DC" ${refund.bank_code == 'DC' ? 'selected' : ''}>동부증권</option>
                          <option value="DD" ${
                            refund.bank_code == 'DD' ? 'selected' : ''
                          }>유진투자증권</option>
                          <option value="DE" ${refund.bank_code == 'DE' ? 'selected' : ''}>메리츠증권</option>
                          <option value="DF" ${refund.bank_code == 'DF' ? 'selected' : ''}>신영증권</option>
                          <option value="DG" ${refund.bank_code == 'DG' ? 'selected' : ''}>대우증권</option>
                          <option value="DH" ${refund.bank_code == 'DH' ? 'selected' : ''}>삼성증권</option>
                          <option value="DI" ${refund.bank_code == 'DI' ? 'selected' : ''}>교보증권</option>
                          <option value="DJ" ${refund.bank_code == 'DJ' ? 'selected' : ''}>키움증권</option>
                          <option value="DK" ${refund.bank_code == 'DK' ? 'selected' : ''}>이트레이드</option>
                          <option value="DL" ${refund.bank_code == 'DL' ? 'selected' : ''}>솔로몬증권</option>
                          <option value="DM" ${refund.bank_code == 'DM' ? 'selected' : ''}>한화증권</option>
                          <option value="DN" ${refund.bank_code == 'DN' ? 'selected' : ''}>NH증권</option>
                          <option value="DO" ${refund.bank_code == 'DO' ? 'selected' : ''}>부국증권</option>
                          <option value="DP" ${refund.bank_code == 'DP' ? 'selected' : ''}>LIG증권</option>
                          <option value="K1" ${refund.bank_code == 'K1' ? 'selected' : ''}>K뱅크</option -->
                        </select>
                      </td>
                      <td class="without_bank bank_account">${refund.bank_account || ''}</td>
                      <td class="without_bank bank_user_name">${refund.bank_user_name || ''}</td>
                      <td class="without_bank action ${refund.is_refunded}">
                        ${refund.is_refunded ? '환불 완료' : '환불 필요'}
                      </td>
                      <td>
                        ${refund.refunded_at ? moment(refund.refunded_at).format('YY.MM.DD HH:mm:ss') : ''}
                      </td>
                      <td>${refund._.refunded_worker.name}</td>
                      <td>${moment(refund.created_at).format('YY.MM.DD HH:mm')}</td>
                    </tr>
                  `,
                  p_payment._.refunds,
                )}
              </table>
            </div>
          </div>
        `,
        p._.projection_payments,
      )}
    </div>
  </div>
`;
