import { $attr, $val } from 'fxdom/es';
import { html } from 'fxjs/es';
import { CREATOR_ORDER_DETAIL_FRAME } from '../../../../../modules/Creator/Order/Detail/S/constant.js';

import { genMerchantUid } from '../../../../../modules/Iamport/SS/fs.js';
import { loadIamport } from '../../../../../modules/MPay/Checkout/F/fs.js';
import { MShopUtilF } from '../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { NewMakerGuideDigitalGoodsDownF } from '../../../../../modules/NewMaker/Guide/DigitalGoodsDown/F/Function/module/NewMakerGuideDigitalGoodsDownF.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { needAdditionalPayment } from '../../../../../modules/Projection/S/fs.js';
import { frameOpenReviewWrite } from '../../../../../modules/Review/Write/F/fs.js';
import { rowTo_en } from '../../../../../modules/Util/S/LangAndCollaboType.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

export const order_detail_frame_opt = {
  appending: function () {
    loadIamport();
    _p.each($('.up_c_item'), function (e) {
      G.mp.maker.draw_product_face2(e, box.sel(e)._.product_color);
    });
  },
  appended: _p.pipe(
    $.on('click', '.up_c_item a', function (e) {
      if (!MShopUtilF.isCreatorApp()) return;
      e.preventDefault();
      const a_el = e.currentTarget;
      MShopUtilF.postMessage({
        screen: {
          type: 'util',
          title: T('mshop::상품상세'),
          uri: $attr('href', a_el),
        },
      });
    }),

    $.on('click', '.projection_down_print .btn_projection_receipt', function (e) {
      window.open(
        '/print_receipt/' + box.sel('projection->id'),
        'receipt_popup',
        'width=1100, height=700, top=100, left=200, menubar=1, toolbar=1',
      );
    }),
    $.on('click', '.up_c_item button.write_a_review', function (e) {
      const up = window.box.sel(e.currentTarget);
      const bp = _p.val(up, '_.product_color._.base_product');
      if (!bp || !bp['is_use' + G._collabo + G._en]) return $.alert(T('order::Sold Out'));
      return frameOpenReviewWrite(up, e.currentTarget);
    }),

    $.on('click', '.up_c_item button.btn_down_img', function (e) {
      if (box.sel('projection->status') == 'ordering' || box.sel('projection->is_cancel_req'))
        return $.alert('결제를 해주신 후 다시 시도해 주세요.');

      NewMakerGuideDigitalGoodsDownF.open($attr('id', e.currentTarget), box.sel('projection->store_id'));
    }),

    $.on('click', '.up_c_item button.btn_review_end', function (e) {
      $.alert(
        G.collabo_type == 'creator'
          ? T('review::리뷰 응원이 완료된 상품입니다.')
          : T('review::Thank you for your comment'),
      );
    }),

    $.on('click', '.change_pay_method', function () {
      $.frame.open({ frame_name: 'mp.order.change_pay_method' });
    }),
    $.on2('click', '.additional_payment', go_iamport_or_payment_types),

    $.on('click', '.info_and_controls .btn.cancel_order[need_refund_bank="false"]', async function () {
      return _p.go(
        box.sel('projection'),
        _p.if2(
          (projection) =>
            projection.status !== 'ordering' && $.confirm(T('Are you sure you want to cancel order?')),
        )(_p.pick('id'), _p($.post_load, '/@api/projection/cancel_req'), async function (res) {
          $.don_loader_end();
          if (res.err) {
            await $.alert(`${T('Cancel request is fail.')}<br><br>${res.err}`);
            location.reload();
            return;
          }
          _p.go($.alert(T('Cancel request is success.')), function () {
            location.reload();
          });
        }),
      );
    }),
    $.on2('click', '.info_and_controls .btn.cancel_order[need_refund_bank="true"]', async function () {
      $.frame.open({
        frame_name: 'mp.order.refund_bank',
        closing: function (X, refund_info) {
          if (refund_info)
            _p.go(
              { id: box.sel('projection').id, refund_info },
              _p($.post_load, '/@api/projection/cancel_req'),
              async function (res) {
                $.don_loader_end();
                if (res.err) {
                  await $.alert(`${T('Cancel request is fail.')}<br><br>${res.err}`);
                  location.reload();
                  return;
                }
                _p.go($.alert(T('Cancel request is success.')), function () {
                  location.reload();
                });
              },
            );
        },
      });
    }),

    _p.if2(function () {
      return (
        needAdditionalPayment(box.sel('projection')) &&
        $.confirm(T('You have pay an unpaid balance due to the order has been changed'))
      );
    })(function () {
      _p.defer(go_iamport_or_payment_types);
    }),
  ),
};

$.frame.defn_frame({
  frame_name: collabo_type == 'creator' ? CREATOR_ORDER_DETAIL_FRAME : 'mp.order.detail',
  page_name: 'mp.order.detail',
  ...order_detail_frame_opt,
});

$.frame.defn_page({
  page_name: 'mp.order.detail',
  tabs: [
    {
      tab_name: 'mp.order.detail',
    },
  ],
});

$.frame.defn_frame({
  el_class: 'additional_or_change_pay_method',
  always_remove: true,
  frame_name: 'mp.order.refund_bank',
  page_name: 'mp.order.refund_bank',
  hide_frame_button_type: G.collabo_type === 'creator' ? 'X' : '',
  title: G.collabo_type === 'creator' ? '환불 계좌 입력' : '',
  ...(G.collabo_type === 'creator' ? { header_height: MShopUtilF.isMobile() ? 84 : 98 } : {}),
  is_modal: !UtilF.isLegacyMobile(),
  appending: __(
    $.on('click', function (e) {
      if (e.target == e.delegateTarget) $.frame.close();
    }),
    $.on2('click', '.submit', function (e) {
      const refund = {
        bank_code: $val($.find1(e.delegateTarget, '[name="bank_code"]')).trim(),
        bank_account: $val($.find1(e.delegateTarget, '[name="bank_account"]')).trim(),
        bank_user_name: $val($.find1(e.delegateTarget, '[name="bank_user_name"]')).trim(),
      };
      if (!refund.bank_code || !refund.bank_account || !refund.bank_user_name) {
        return $.alert('모든 정보를 입력해 주세요.');
      }
      const projection_payment = _p.find(box.sel('projection')._.projection_payments, function (pp) {
        return (
          pp.pay_method === 'vbank' ||
          (pp.paid_amount > pp.refund_amount && ['without_bank'].includes(pp.pay_method))
        );
      });
      return _p.go(
        {
          projection_payment_id: projection_payment.id,
          refund: {
            bank_code: $.val($.find1(e.delegateTarget, '[name="bank_code"]')),
            bank_account: $.val($.find1(e.delegateTarget, '[name="bank_account"]')),
            bank_user_name: $.val($.find1(e.delegateTarget, '[name="bank_user_name"]')),
          },
        },
        $.frame.close,
      );
    }),
  ),
});

$.frame.defn_page({
  page_name: 'mp.order.refund_bank',
  hide_frame_button_type: G.collabo_type !== 'creator' ? 'X' : '',
  title: G.collabo_type !== 'creator' ? '환불 계좌 입력' : '',
  tabs: [
    {
      tab_name: 'mp.order.additional_payment',
      template: () => html`
        <div class="page_body">
          <div class="section payment_type_container">
            <div class="section_header">
              <div class="txt">환불 받으실 계좌를 입력해 주세요.</div>
            </div>
            <div class="card_body">
              <div class="bank_info">
                <select name="bank_code">
                  <option value="04">KB국민은행</option>
                  <option value="23">SC제일은행</option>
                  <option value="39">경남은행</option>
                  <option value="34">광주은행</option>
                  <option value="03">기업은행</option>
                  <option value="11">농협</option>
                  <option value="31">대구은행</option>
                  <option value="32">부산은행</option>
                  <option value="02">산업은행</option>
                  <option value="45">새마을금고</option>
                  <option value="07">수협</option>
                  <option value="88">신한은행</option>
                  <option value="48">신협</option>
                  <option value="05">외환은행</option>
                  <option value="20">우리은행</option>
                  <option value="71">우체국</option>
                  <option value="37">전북은행</option>
                  <option value="16">축협</option>
                  <option value="90">카카오뱅크</option>
                  <option value="89">케이뱅크</option>
                  <option value="81">하나은행(서울은행)</option>
                  <option value="53">한국씨티은행(한미은행)</option>
                  <option value="92">토스뱅크</option>
                </select>
                <input type="text" class="width366" name="bank_account" placeholder="환불 받으실 계좌 번호" />
                <input
                  type="text"
                  class="width366"
                  placeholder="예금주명을 정확히 입력해 주세요."
                  name="bank_user_name"
                  value=""
                />
              </div>
            </div>
            <div class="options">
              <button type="button" class="submit">입력하기</button>
            </div>
          </div>
        </div>
      `,
    },
  ],
});

$.frame.defn_frame({
  el_class: 'additional_or_change_pay_method',
  always_remove: true,
  frame_name: 'mp.order.additional_payment',
  page_name: 'mp.order.additional_payment',
  hide_frame_button_type: G.collabo_type === 'creator' ? 'X' : '',
  title: G.collabo_type === 'creator' ? '추가 결제하기' : '',
  is_modal: !UtilF.isLegacyMobile(),
  appending: __(
    $.on('click', function (e) {
      if (e.target == e.delegateTarget) $.frame.close();
    }),
    $.on('click', '.payment_types >.selector button', function (e) {
      _p.go(
        e.delegateTarget,
        $.find1('.payment_types'),
        $.attr({
          type: $.attr(e.currentTarget, 'payment_type'),
        }),
      );
    }),
    $.on('click', '.submit', function (e) {
      const payment_type = _p.go(e.delegateTarget, $.find1('.payment_types'), $.attr('type'));
      let bank_name;
      let bank_number;
      let deposit_user_name;
      if (payment_type == 'without_bank') {
        const bank_info_el = _p.go(e.delegateTarget, $.find1('.bank_info'));
        const bank_el = $.find1(bank_info_el, 'select[name="bank"]');
        const bank_infos = $.val(bank_el).split('|');
        bank_name = bank_infos[0];
        bank_number = bank_infos[1];
        const deposit_user_name_el = $.find1(bank_info_el, 'input[name="deposit_user_name"]');
        deposit_user_name = $.val(deposit_user_name_el);
        if (!bank_name)
          return _p.go($.alert('입금 은행을 선택해 주세요.'), function () {
            bank_el.focus();
          });
        if (!deposit_user_name)
          return _p.go($.alert('입금자명을 입력해 주세요.'), function () {
            deposit_user_name_el.focus();
          });
      }

      $.frame.close();

      payment_type == 'without_bank'
        ? (location.href =
            location.href +
            '/additional_payment?' +
            $.param({
              marpple_success: true,
              bank_name: bank_name,
              bank_number: bank_number,
              deposit_user_name: deposit_user_name,
            }))
        : go_iamport(payment_type);
    }),
  ),
});

$.frame.defn_page({
  page_name: 'mp.order.additional_payment',
  hide_frame_button_type: G.collabo_type !== 'creator' ? 'X' : '',
  title: G.collabo_type !== 'creator' ? '추가 결제하기' : '',
  tabs: [
    {
      tab_name: 'mp.order.additional_payment',
      template: () => pug`
        .page_body
          .section.payment_type_container
            .section_header
              .txt 결제방법 선택
            .card_body
              .payment_types[type="card"]
                .selector
                  ${
                    G.collabo_type === 'creator'
                      ? pug`
                        button[type="button" payment_type="card"] 신용카드
                        button[type="button" payment_type="naverpay"] 네이버페이
                        button[type="button" payment_type="kakaopay"] 카카오페이
                        button[type="button" payment_type="tosspay"] 토스
                        button[type="button" payment_type="vbank"] 가상계좌 (무통장)
                        button[type="button" payment_type="trans"] 실시간 계좌이체
                        button[type="button" payment_type="samsung"] 삼성페이
                        button[type="button" payment_type="ssgpay"] SSGPAY
                        button[type="button" payment_type="payco"] 페이코
                      `
                      : pug`
                        button[type="button" payment_type="card"] 신용카드
                        button[type="button" payment_type="without_bank"] 무통장입금
                        button[type="button" payment_type="trans"] 실시간이체
                        button[type="button" payment_type="naverpay"] 네이버페이
                        button[type="button" payment_type="kakaopay"] 카카오페이
                        button[type="button" payment_type="tosspay"] 토스
                        button[type="button" payment_type="samsung"] 삼성페이
                        button[type="button" payment_type="ssgpay"] SSGPAY
                        button[type="button" payment_type="payco"] 페이코
                      `
                  }
                .payment_type.card
                .payment_type.without_bank
                  .bank_info
                    select.width366[name="bank"]
                      option[value=""] 입금 은행을 선택해 주세요.
                      option[value="국민은행 (주)마플코퍼레이션|037601-04-041747"] 국민은행 (주)마플코퍼레이션 037601-04-041747
                      option[value="우리은행 (주)마플코퍼레이션|1005-702-680981"] 우리은행 (주)마플코퍼레이션 1005-702-680981
                      option[value="기업은행 (주)마플코퍼레이션|078-174234-01-013"] 기업은행 (주)마플코퍼레이션 078-174234-01-013
                    input.width366[type="text" placeholder="입금자명을 정확히 입력해 주세요." name="deposit_user_name" value=""]
                .payment_type.trans
            .options
              button[type="button"].submit 결제하기
      `,
    },
  ],
});

$.frame.defn_frame({
  el_class: 'additional_or_change_pay_method',
  frame_name: 'mp.order.change_pay_method',
  always_remove: true,
  page_name: 'mp.order.change_pay_method',
  is_modal: !UtilF.isLegacyMobile(),
  appending: __(
    $.on('click', function (e) {
      if (e.target == e.delegateTarget) $.frame.close();
    }),
    $.on('click', '.cancel', function () {
      $.frame.close();
    }),
    $.on('click', '.payment_types >.selector button', function (e) {
      _p.go(
        e.delegateTarget,
        $.find1('.payment_types'),
        $.attr({
          type: $.attr(e.currentTarget, 'payment_type'),
        }),
      );
    }),
    $.on('click', '.submit', function (e) {
      $.frame.close();
      const payment_type = _p.go(e.delegateTarget, $.find1('.payment_types'), $.attr('type'));
      go_iamport(payment_type, '/change_pay_method');
    }),
  ),
});

$.frame.defn_page({
  page_name: 'mp.order.change_pay_method',
  hide_frame_button_type: 'X',
  title: '결제수단 변경하기',
  tabs: [
    {
      tab_name: 'mp.order.change_pay_method',
      template: _p.t$(
        '\
      .page_body\
        .section.payment_type_container\
          .section_header\
            .txt 결제방법 선택\
          .card_body\
            .payment_types[type="card"]\
              .selector\
                button[type="button" payment_type="card"] 신용카드\
                button[type="button" payment_type="trans"] 실시간 계좌이체\
            .payment_type.card\
            .payment_type.trans\
          .options\
            button[type="button"].submit 결제하기\
    ',
      ),
    },
  ],
});

function go_iamport(payment_type, url_tail) {
  url_tail = url_tail || '/additional_payment';

  const projection = box.sel('projection');
  return _p.go(
    $.get('/@api/projection_only', {
      id: projection.id,
    }),
    async function (projection) {
      const _en = rowTo_en(projection);
      if (projection['amount' + _en] == 0) return location.reload();

      const payment_obj = {
        m_redirect_url: location.href + url_tail,
        pg: projection.lang === 'kr' ? (payment_type === 'naverpay' ? 'naverpay' : 'html5_inicis') : 'paypal',
        pay_method: payment_type || 'card',
        merchant_uid: genMerchantUid(projection.id) + '_' + Date.now(),
        name: T('Additional Payment'),
        amount: PriceS.price(projection['amount' + _en]),
        buyer_tel: `${projection.orderer_mobile}`,
        buyer_email: projection.orderer_email,
        buyer_name: projection.orderer_name,
        currency: projection.lang === 'kr' ? 'KRW' : projection.lang === 'jp' ? 'JPY' : 'USD',
        custom_data: {
          location: url_tail.substr(1),
          projection_id: projection.id,
          timestamp: Date.now(),
        },
      };

      if (payment_obj.pay_method === 'naverpay') {
        payment_obj.tax_free = 0;
        // payment_obj.naverV2 = true;
        if ($1('html[is_mobile="true"]')) {
          payment_obj.naverPopupMode = false;
        } else {
          payment_obj.naverPopupMode = true;
        }
        payment_obj.naverProducts = _p.go(
          box.sel('projection->_->up_cs'),
          _p.flatten,
          _p.map((up_c) =>
            _p.map(up_c._.up_c_ss, (up_c_s) => ({
              categoryType: 'PRODUCT',
              categoryId: 'GENERAL',
              uid: up_c_s.id,
              name: up_c._.product_color._.base_product.name,
              count: 1,
            })),
          ),
          _p.flatten,
        );
      }

      if (G.collabo_type === 'creator' ? MShopUtilF.isCreatorApp() : window.ReactNativeWebView) {
        /* 리액트 네이티브 환경에 대응하기 */
        const params = {
          userCode: 'imp03882726', // 가맹점 식별코드
          data: payment_obj, // 결제 데이터
          type: 'payment', // 결제와 본인인증 구분을 위한 필드
        };
        const paramsToString = JSON.stringify(params);
        window.ReactNativeWebView.postMessage(paramsToString);
        return;
      }

      $1('html[is_mobile="false"]')
        ? IMP.request_pay(payment_obj, function (rsp) {
            // 결제 성공시
            if (rsp.success) {
              location.href =
                location.href +
                url_tail +
                '?imp_success=true&' +
                $.param(_p.pick(rsp, ['imp_uid', 'merchant_uid', 'imp_success']));
            } else {
              if (rsp.error_msg) {
                $.alert(rsp.error_msg);
              } else {
                $.alert(T('Payment has been stopped'));
              }
            }
          })
        : IMP.request_pay(payment_obj);
    },
  );
}

function go_iamport_or_payment_types() {
  box.sel('projection->lang') == 'kr'
    ? $.frame.open({ frame_name: 'mp.order.additional_payment' })
    : go_iamport();
}
