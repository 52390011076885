import { $data, $qs } from 'fxdom/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { pdfPrintingFileUploadHtml } from '../../S/Tmpl/tmpl.js';
import { PrintTemplateF } from '../../../../../PrintTemplate/F/Function/module/PrintTemplateF.js';

/**
 * @define PDF 파일이 업로드 되었을 때 DOM 갱신
 * @param {PdfPrintingFileRow | undefined} pdf_printing_file
 * @param {number} bp_id
 * @return {Promise<undefined>}
 * */
export async function refreshFileUploadHtml({ pdf_printing_file, bp_id }) {
  const file_upload_el = $qs('.pdf_uploader__body__upload');
  const template = await PrintTemplateF.getPrintTemplate({
    bp_id,
    lang: T.lang,
  });
  if (file_upload_el) {
    file_upload_el.innerHTML = pdfPrintingFileUploadHtml({ pdf_printing_file, template });
  }
}

/**
 * @define DOM 에 저장된 pdf 파일 정보 객체화
 * @return {PdfPrintingFileRow | null}
 * */
export function getPdfFileUploadedFromDOM() {
  const file_el = $qs('.pdf_uploader__body__upload .section.file');
  if (file_el) {
    try {
      const pdf_printing_file = $data(file_el);
      return UtilS.returnIfEmptyNull(pdf_printing_file);
    } catch (err) {
      return null;
    }
  }

  return null;
}

/**
 * @define 헤더에 완료 버튼 활성/비활성화 - PDF 파일 존재할 때만 완료 가능
 * @param {boolean} is_disabled
 * @return {undefined}
 * */
export function switchHeaderConfirmButton({ is_disabled }) {
  const confirm_button_el = $qs('.pdf_uploader__header button[name="confirm"]');
  if (confirm_button_el) {
    if (is_disabled) {
      confirm_button_el.setAttribute('disabled', 'true');
    } else {
      confirm_button_el.removeAttribute('disabled');
    }
  }
}
