import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthChangeEmailTmplS } from '../../S/Tmpl/module/OMPAuthChangeEmailTmplS.js';
import { OMPAuthChangeEmailMuiF } from '../Mui/module/OMPAuthChangeEmailMuiF.js';

/**
 * @param {{email: string?, social_id: string?}} data
 * @param {(frame: HTMLElement, user: object) => void?} closed
 */
export const openChangeEmailFrame = (data, closed) => {
  return OMPCoreUtilF.openFrameFit(OMPAuthChangeEmailMuiF.frame, (frame, page, [tab]) => {
    page.title = TT('auth::change_email::change_email_01');
    tab.makeData = () => data;
    tab.template = OMPAuthChangeEmailTmplS.changeEmailTmpl;
    if (closed) frame.closed = closed;
  });
};
