import { go, html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import {
  BIZ_APPLICATION_COMP_TYPE,
  BIZ_APPLICATION_CONSULTATION_TYPE,
  BIZ_APPLICATION_STATUS,
} from '../../../S/Constant/bizapplication.js';
import { selectManagers, selectStatus } from '../../../S/Tmpl/bizApplicationListTmpl.js';

export const detail = ({ item, managers }) => {
  return html`
    <div class="df_biz_application_detail p-4">
      <h2>패키지 / 키트 상담 신청서</h2>
      <form name="bizApplicationForm" class="mt-4" data-app-id="${item.id}">
        <div class="row">
          <div class="col border-end">
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">ID</div>
              <div class="col-sm-8">${item.id}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">상담 유형</div>
              <div class="col-sm-8">
                <span class="badge text-bg-primary"
                  >${BIZ_APPLICATION_CONSULTATION_TYPE[item.consultation_type]}</span
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">신청 제목</div>
              <div class="col-sm-8">${UtilS.escape(item.title)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">희망 제작 수량</div>
              <div class="col-sm-8">${UtilS.escape(item.count)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">납기일</div>
              <div class="col-sm-8">${UtilS.formatDateWithDash(item.due_date)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">굿즈 수령지</div>
              <div class="col-sm-8">${UtilS.escape(item.address)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">제작 목적</div>
              <div class="col-sm-8">${UtilS.escape(item.purpose)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">예산</div>
              <div class="col-sm-8">${UtilS.escape(item.budget ?? '')}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">세부 내용</div>
              <div class="col-sm-8 pre text-break">${UtilS.linkify(item.description)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">첨부 파일</div>
              <div class="col-sm-8">
                ${item.files.length > 0 && item.files[0]?.url
                  ? `
            <ul>
            ${go(
              item.files,
              strMap((file) => {
                return html`<li>
                  <a href="${UtilS.escape(file.url)}" target="_blank">파일 보기</a>
                </li>`;
              }),
            )}
            </ul>
          `
                  : `X`}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">기업 형태</div>
              <div class="col-sm-8">${BIZ_APPLICATION_COMP_TYPE[item.comp_type]}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">기업명</div>
              <div class="col-sm-8">${UtilS.escape(item.comp_name)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">기업 담당자</div>
              <div class="col-sm-8">${UtilS.escape(item.comp_mng_nm)}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">기업 담당자 이메일</div>
              <div class="col-sm-8">
                ${item.comp_mng_email
                  ? html`<a class="mail-link" href="mailto:${item.comp_mng_email}"
                      >${UtilS.escape(item.comp_mng_email)}</a
                    >`
                  : ''}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 fw-semibold">기업 담당자 연락처</div>
              <div class="col-sm-8">${item.comp_mng_mobile}</div>
            </div>
          </div>
          <div class="col">
            <div class="row mb-3">
              <label class="col-sm-4 fw-semibold col-form-label" for="biz_application_manager">담당자</label>
              <div class="col-sm-8">
                <select name="manager_id" id="biz_application_manager" class="form-control form-select">
                  ${selectManagers(managers, item.manager_id)}
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-4 fw-semibold col-form-label" for="biz_application_status"
                >상담 현황</label
              >
              <div class="col-sm-8">
                <select name="status" id="biz_application_status" class="form-control form-select">
                  ${selectStatus(BIZ_APPLICATION_STATUS, item.status, false)}
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-4 fw-semibold col-form-label" for="biz_application_memo">메모</label>
              <div class="col-sm-8">
                <textarea
                  id="biz_application_memo"
                  name="memo"
                  placeholder="메모를 작성해 주세요."
                  class="form-control"
                >
${item.memo ?? ''}</textarea
                >
              </div>
            </div>
            <div class="d-grid">
              <button type="button" class="btn btn-primary btn_modify">수정</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  `;
};
