import {
  CREATOR_CHECKOUT_FRAME,
  CREATOR_CHECKOUT_PAGE,
  CREATOR_CHECKOUT_TAB,
} from '../../../../../modules/Creator/Checkout/S/constant.js';

import {
  MPAY_CHECKOUT_FRAME,
  MPAY_CHECKOUT_PAGE,
  MPAY_CHECKOUT_TAB,
} from '../../../../../modules/MPay/Checkout/S/constant.js';

import { MPayLegacyCheckoutF } from '../../../../../modules/MPayLegacy/Checkout/F/Function/module/MPayLegacyCheckoutF.js';
import './mp.checkout.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { ConfigSentryF } from '../../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';

const [CHECKOUT_FRAME, CHECKOUT_PAGE, CHECKOUT_TAB] =
  collabo_type == 'creator'
    ? [CREATOR_CHECKOUT_FRAME, CREATOR_CHECKOUT_PAGE, CREATOR_CHECKOUT_TAB]
    : [MPAY_CHECKOUT_FRAME, MPAY_CHECKOUT_PAGE, MPAY_CHECKOUT_TAB];

!(function () {
  $.frame.defn_frame({
    frame_name: CHECKOUT_FRAME,
    page_name: CHECKOUT_PAGE,
    ...MPayLegacyCheckoutF.frame_opt,
  });

  $.frame.defn_page({
    page_name: CHECKOUT_PAGE,
    tabs: [{ tab_name: CHECKOUT_TAB }],
    ...MPayLegacyCheckoutF.page_opt,
  });
  try {
    if (G.collabo_type === '') {
      const url = new URL(window.location);
      if (url.searchParams.get('redirect') === 'true') {
        const msg = url.searchParams.get('msg');
        OMPCoreUtilF.alert({
          content: msg ? msg.replace(/__br__/g, '<br>') : TT('error::common::error_01'),
          msg_width: 355,
        });
        url.searchParams.delete('redirect');
        url.searchParams.delete('msg');
        $.frame.extend_state(
          void 0,
          null,
          `${url.pathname.toString()}?${url.searchParams.toString()}`,
          'replace',
        );
      }
    }
  } catch (e) {
    ConfigSentryF.error(e);
  }
})({});
