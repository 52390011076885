import { NewMakerFramesMobilePdfUploaderMuiF } from '../Mui/module/NewMakerFramesMobilePdfUploaderMuiF.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { setFrameCustomData } from '../../S/Function/mobilepdfuploader.js';
import { PrintTemplateF } from '../../../../../PrintTemplate/F/Function/module/PrintTemplateF.js';

/**
 * @define PDF 업로드 프레임 열기
 * @param {number} bp_id
 * @param {PdfPrintingFileRow | undefined} pdf_printing_file
 * @return {Promise<{pdf_printing_file: PdfPrintingFileRow}>}
 * */
export async function openFrame({ bp_id, pdf_printing_file = undefined } = {}) {
  if (bp_id == null) throw new Error(`bp_id is required`);
  return new Promise((res, rej) => {
    try {
      MuiF.openFrame(NewMakerFramesMobilePdfUploaderMuiF.frame, async (frame, page, [tab]) => {
        const template = await PrintTemplateF.getPrintTemplate({
          bp_id,
          lang: T.lang,
        });
        tab.makeData = () => ({ bp_id, pdf_printing_file, template });

        setFrameCustomData({ frame, data: { bp_id, pdf_printing_file } });
        frame.closed = async (_, { pdf_printing_file } = {}) => {
          res({ pdf_printing_file });
        };
      });
    } catch (err) {
      rej(err);
    }
  });
}
