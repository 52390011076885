import { filter, ippL, map, pipe, sel, strMap, sumBy, go, some, html, sortBy, join } from 'fxjs/es';
import moment from 'moment';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UserProductS } from '../../../../UserProduct/S/Function/module/UserProductS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';

export const fn = () => {};
const productUrlWithUpC = (up_c) => {
  const ev = sel('_.product_color.product_faces2.ev', up_c);
  if (ev?.url) {
    return `/${T.lang}/${ev.url}?bp_id=${up_c.base_product_id}&pc_id=${up_c.product_color_id}`;
  }
  return `/${T.lang}/product/detail?bp_id=${up_c.base_product_id}&pc_id=${up_c.product_color_id}`;
};

const makeUpCProductPriceContentHtml = pipe(
  function (up_c, _en) {
    _en = _en === undefined ? G._en : _en;
    return {
      discounted_price: PriceS.pricify_by(
        sumBy((up_c_s) => PriceS.mult(up_c_s['discounted_price' + _en], up_c_s.quantity), up_c._.up_c_ss),
        _en,
      ),
      price: PriceS.pricify_by(
        sumBy((up_c_s) => PriceS.mult(up_c_s['price' + _en], up_c_s.quantity), up_c._.up_c_ss),
        _en,
      ),
      pdf_printing_file: up_c._.product_color._.pdf_printing_file || {},
    };
  },
  ($) => {
    const { pdf_url, file_name, created_at, expired_at } = $.pdf_printing_file;
    const is_expired = OMPCoreUtilS.isExpired({ expired_at });
    const [file_title] = (file_name || '').split('.pdf');
    return legacyHtml`
    ${
      !UtilS.isEmpty($.pdf_printing_file)
        ? html`<div class="product_file_wrapper">
            <div class="product_file">
              ${is_expired
                ? `<div class="product_file_name ${is_expired ? 'disabled' : ''}">
                     <span class="product_file_title">${file_title}</span>
                     <span class="product_file_mimetype">.pdf</span>
                   </div>`
                : `<div data-file-url="${pdf_url}" data-file-name="${file_name}" class="product_file_name">
                <span class="product_file_title">${file_title}</span>
                <span class="product_file_mimetype">.pdf</span>
              </div>`}
              <div class="product_file_date">
                ${is_expired ? TT('pdf::expired::2') : moment(created_at).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
          </div>`
        : ''
    }
    <div class="product_price_content">
      <div class='origin'>${$.price == $.discounted_price ? '' : $.price}</div>
      <div class='discounted mp_currency'>${$.discounted_price}</div>
    </div>
  `;
  },
);

const bpNameByUpC = (up_c) => {
  const can_not_change_color = Boolean(sel('_.product_color.product_faces2.can_not_change_color', up_c));
  const color_name = up_c._.product_color._.base_product_color['name' + _en];
  const option_title = sel(`_.product_color._.selected_option_group.title${_en}`, up_c);

  return `${up_c._.product_color._.base_product['name' + _collabo + _en]} 
      ${
        isNeedPreview(sel('_.product_color._.base_product', up_c)) || can_not_change_color || !color_name
          ? ''
          : `[${color_name}]`
      }
      ${option_title ? ` - ${option_title}` : ''}`;
};
const makeUpCSItemHtml = (up_c_ss, key) => pug`
  .up_c_s_item[_sel='./_->up_c_ss'] 
    .size ${key} 
    .quantity ${_p.commify(_p.sum(up_c_ss, (up_c_s) => up_c_s.quantity))}`;

const makeUpCItemHtml = (up_c, is_mobile) => {
  const { is_bps_hidden } = up_c._.product_color._.base_product;

  const discontinued = go(
    up_c._.up_c_ss,
    some(({ _is_discontinued, quantity }) => quantity > 0 && _is_discontinued),
  );
  const notStock = go(
    up_c._.up_c_ss,
    some(({ _is_not_stock, quantity }) => quantity > 0 && _is_not_stock),
  );

  const product_color = up_c._.product_color;
  const selected_option_group = product_color._.selected_option_group;
  const base_product = product_color._.base_product;
  const product_faces2 = product_color.product_faces2;
  const base_product_color = product_color._.base_product_color;

  const all_option_names = go(
    UserProductS.naming.makeOptionNames1(
      {
        selected_option_group,
        base_product,
        base_product_color,
        product_faces2,
      },
      '',
    ),
    sortBy((option) => option.option_group_name === '사이즈'),
  );

  const option_names = go(
    all_option_names,
    ippL,
    filter(([idx]) => {
      const is_last = idx + 1 === all_option_names.length;
      return UtilS.isEmpty(selected_option_group?.option_names) ? true : !is_last;
    }),
    map(([, option]) => option),
  );

  const [last_option_name] = [...all_option_names].reverse();
  const last_option_el =
    !UtilS.isEmpty(selected_option_group?.option_names) && last_option_name
      ? html` <span class="sub_title">${last_option_name.option_group_name}</span>
          <span class="up_c_s_list">
            ${_p.go(
              up_c._.up_c_ss,
              _p.group_by((up_c_s) => {
                const not_stock = up_c_s._is_not_stock;
                const discontinued = up_c_s._is_discontinued;
                return (
                  last_option_name.option_name +
                  (not_stock
                    ? `<br>(${TT('cart::cart_23')})`
                    : discontinued
                    ? `<br>(${TT('cart::cart_24')})`
                    : '')
                );
              }),
              _p.sum(makeUpCSItemHtml),
            )}
          </span>`
      : '';

  const last_option_wrapper = is_mobile
    ? last_option_el
    : last_option_el
    ? `<div class="sizes">${last_option_el}</div>`
    : '';

  const options_el = go(
    option_names,
    map((option) => {
      return html`
        <span class="sub_title">${option.option_group_name}</span>
        <span class="face_list">${option.option_name}</span>
      `;
    }),
  );

  const options_wrapper = go(
    options_el,
    map((option_el) => {
      return is_mobile ? option_el : option_el ? `<div class="faces">${option_el}</div>` : '';
    }),
    join(''),
  );

  const base_product_sizes_el =
    UtilS.isEmpty(selected_option_group?.option_names) && !is_bps_hidden
      ? html`<span class="sub_title">${T('Size')}</span>
          <div class="up_c_s_list">
            ${_p.go(
              up_c._.up_c_ss,
              _p.group_by((up_c_s) => {
                const not_stock = up_c_s._is_not_stock;
                const discontinued = up_c_s._is_discontinued;
                return (
                  up_c_s['_name' + _en] +
                  (not_stock
                    ? `<br>(${TT('cart::cart_23')})`
                    : discontinued
                    ? `<br>(${TT('cart::cart_24')})`
                    : '')
                );
              }),
              _p.sum(makeUpCSItemHtml),
            )}
          </div>`
      : '';

  const base_product_sizes_wrapper = is_mobile
    ? base_product_sizes_el
    : base_product_sizes_el
    ? `<div class="sizes">${base_product_sizes_el}</div>`
    : '';

  const pdf_printing_file = up_c._.product_color._.pdf_printing_file;

  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const pdf_file_expiration = has_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  const { pdf_url, file_name, created_at, expired_at } = pdf_printing_file || {};
  const is_expired = OMPCoreUtilS.isExpired({ expired_at });
  const [file_title] = (file_name || '').split('.pdf');

  const pdf_file_expiration_el = html`<div class="expiration">${TT('pdf::expired::1')}</div>`;

  const pdf_file_el = has_pdf_printing_file
    ? `${
        !UtilS.isEmpty(pdf_printing_file)
          ? html`<div class="sub_title product_file">${TT('cart::cart_28')}</div>
              <div class="product_file">
                ${is_expired
                  ? `<div class="product_file_name ${is_expired ? 'disabled' : ''}">
                 <span class="product_file_title">${file_title}</span>
                 <span class="product_file_mimetype">.pdf</span>
               </div>`
                  : `<div data-file-url="${pdf_url}" data-file-name="${file_name}" class="product_file_name">
            <span class="product_file_title">${file_title}</span>
            <span class="product_file_mimetype">.pdf</span>
          </div>`}
                <div class="product_file_date">
                  ${is_expired ? TT('pdf::expired::2') : moment(created_at).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>`
          : ''
      }`
    : '';

  return pug`
  .up_c_item[_sel='./_->up_cs->(#${
    up_c.id
  })' data-discontinued="${discontinued}" data-not-stock="${notStock}"]
    .item_header
      span ${moment(up_c.created_at).format('YYYY-MM-DD HH:mm')}
      span.no No.${up_c.id}
    .item_body
      .item_body_wrap1
        a[href="${productUrlWithUpC(up_c)}"].thumb
          ${
            pdf_file_expiration
              ? pdf_file_expiration_el
              : pug`
          ${UserProductS.thumbnail.smallCanvasHtml()}
          `
          }
        .up_c_info
          a[href="${productUrlWithUpC(up_c)}"].title
            .pc_name ${
              up_c._.product_color.store_id
                ? up_c._.product_color['name' + _en] || up_c._.product_color.name || bpNameByUpC(up_c)
                : ''
            }
            .name ${up_c._.product_color._.base_product['name' + _collabo + _en]} 
          .options
            ${options_wrapper}
            ${last_option_wrapper}
            ${base_product_sizes_wrapper}
            ${pdf_file_el}
          .product_price
            ${makeUpCProductPriceContentHtml(up_c)}`;
};

const makeUpItemHtml = (up, is_mobile) => pug`
  .up_item[_sel='./(#${up.id})']
    .up_c_list ${_p.go(
      up._.up_cs,
      strMap((up_c) => makeUpCItemHtml(up_c, is_mobile)),
    )}`;

export const makeUpListHtml = (is_mobile, ups) => strMap((up) => makeUpItemHtml(up, is_mobile), ups);

export const makeCheckoutKrUserInfoSectionHtml = (user) => pug`
  .section.orderer_info.user_info 
    .section_header
      .txt 주문자 정보
    .section_body
      .input_wrap.checkout-row-1
        label 이름
        input.width366.label_content_css[type="text" name="orderer_name" value="${UtilS.escape(
          user.name || '',
        )}" placeholder="이름을 입력해 주세요." required maxlength="50"]
      .input_wrap.checkout-row-1
        label 연락처
        input.width366.only_number.label_content_css.phone_number[type="text" name="orderer_mobile" value="${UtilS.escape(
          user.mobile || '',
        )}" placeholder="- 없이 01000000000" required] 
      ${
        user.email
          ? pug`
            .input_wrap.email.user_email.checkout-row-1
              label 이메일
              .email_text.label_content_css.checkout-row-1__body 
                sp.value ${UtilS.escape(user.email)}
                button[type="button"].btn.checkout-row-1__option 이메일 변경
                .msg 위 이메일로 주문 내역 메일이 전송됩니다. <span class="m_br">사용 가능한 메일인지 확인해 주세요.`
          : pug`
            .input_wrap.has_not_email.checkout-row-1
              label 이메일
              input.width366.label_content_css[placeholder="주문 내역을 받을 이메일을 입력해 주세요." type="email" name="email" required].email
              .msg 위 이메일로 주문 내역 메일이 전송됩니다.`
      }
      ${
        user.type == 'TEMP'
          ? pug`
            .input_wrap.has_not_email.checkout-row-1
              label 비밀번호
              input.width366.label_content_css[placeholder="주문 내역을 확인할 비밀번호를 입력해 주세요." type="password" name="password" required].password
              .msg 입력한 주문자 정보로 자동 회원가입이 진행되며, 주문 이후 로그인하여 구매내역 확인이 가능합니다.
            `
          : ''
      }
      .omp_msg ! 위 이메일 주소로 주문 내역 관련 메일이 전송될 예정입니다. 사용 가능한 이메일인지 먼저 확인해 주세요.
      `;

function makeOnlyNum(str_or_num_or_null) {
  if (typeof str_or_num_or_null === 'string') {
    return str_or_num_or_null.replace(/[^0-9]/g, '');
  } else {
    return str_or_num_or_null + '';
  }
}
export const makeReceiptInfoHtml = (last_projection_payment) => {
  const receipt = sel('receipt', last_projection_payment) || {};
  return pug`
    .receipt_info.radio_box_wrapper.postcode_scroll[receipt_type=${
      receipt.type === 1 ? 'cash' : receipt.type === 2 ? 'company' : receipt.type === 3 ? 'taxBill' : 'none'
    }]
      .header 
        .txt 영수증
      .radio_box.receipt_type
        label.checkout-label-radio
          sp.mp_input
            input[type="radio" name="receipt_type2" value="none" ${
              receipt.type === 0 || receipt.type === undefined ? 'checked' : ''
            }]
            span 
          sp 미발행
        label.checkout-label-radio
          sp.mp_input
            input[type="radio" name="receipt_type2" value="cash" ${receipt.type === 1 ? 'checked' : ''}]
            span 
          sp 현금영수증 소득공제
        label.checkout-label-radio
          sp.mp_input
            input[type="radio" name="receipt_type2" value="company" ${receipt.type === 2 ? 'checked' : ''}]
            span 
          sp 사업자 지출증빙
        label.checkout-label-radio
          sp.mp_input
            input[type="radio" name="receipt_type2" value="taxBill" ${receipt.type === 3 ? 'checked' : ''}]
            span 
          sp 세금계산서
      .body
        .cash.radio_target.receipt_body
          select.width366[name="identifier_type"]
            option[value="phone"] 휴대폰 번호 *
          input.width366.phone_number.only_number.receipt_required[type="text" name="identifier" placeholder="- 없이 01000000000" value="${UtilS.escape(
            receipt.identifier_type === 'phone' ? makeOnlyNum(receipt.identifier) : '',
          )}" size=50]
        .company.radio_target.receipt_body
          select.width366[name="identifier_type"]
            option[value="business"] 사업자등록번호 *
          input.width366.only_number.receipt_required.business_number[type="text" name="identifier" value="${UtilS.escape(
            receipt.identifier_type === 'business' ? makeOnlyNum(receipt.identifier) : '',
          )}" size=50]
        .taxBill.radio_target.receipt_body
          .body
            .input_wrap.checkout-row-1
              label 상호 *
              input.width366.receipt_required[type="text" name="company_name" placeholder="상호(법인명)을 입력해 주세요." value="${UtilS.escape(
                receipt.company_name ? receipt.company_name : '',
              )}" size=50 maxlength=50]
            .input_wrap.checkout-row-1
              label 대표 *
              input.width366.receipt_required[type="text" name="worker_name" placeholder="이름을 입력해 주세요." value="${UtilS.escape(
                receipt.worker_name ? receipt.worker_name : '',
              )}" size=50 maxlength=50]
            .input_wrap.checkout-row-1
              label 사업자번호 *
              input.width366.only_number.receipt_required[type="text" name="company_num" placeholder="사업자 등록번호를 입력해 주세요." value="${UtilS.escape(
                receipt.company_num ? makeOnlyNum(receipt.company_num) : '',
              )}" size=50 maxlength=10]
            .input_wrap.checkout-row-1
              label 담당자 이메일 *
              input.width366.receipt_required[type="text" name="email" placeholder="이메일을 입력해 주세요." value="${UtilS.escape(
                receipt.email ? receipt.email : '',
              )}" size=50 maxlength=50]
            .input_wrap
              .checkout-row-1
                label 사업장 주소
                .checkout-row-1__body
                  input.postcode[type="text" name="postcode" placeholder="우편번호" value="${UtilS.escape(
                    receipt.postcode ? receipt.postcode : '',
                  )}" size=20 maxlength=20 readonly]
                  button[type="button"].search_postcode.checkout-row-1__option 우편번호 검색
              .checkout-row-1
                label.no_mobile_label
                input.address.no_label.width366[type="text" name="company_addr" value="${UtilS.escape(
                  receipt.company_addr ? receipt.company_addr : '',
                )}" placeholder="주소" size=50 maxlength=50]
              .checkout-row-1
                label.no_mobile_label
                input.no_label.address2.width366[type="text" name="company_addr2" placeholder="상세 주소를 입력해 주세요."  value="${UtilS.escape(
                  receipt.company_addr2 ? receipt.company_addr2 : '',
                )}" size=50 maxlength=50]
              .postcode_layer
                img.postcode_close[src="//t1.daumcdn.net/postcode/resource/images/close.png"]
            .input_wrap.checkout-row-1
              label 업태
              input.width366[type="text" name="business_status" placeholder="업태를 입력해 주세요." value="${UtilS.escape(
                receipt.business_status ? receipt.business_status : '',
              )}" size=50 maxlength=50]
            .input_wrap.checkout-row-1
              label 업종
              input.width366[type="text" name="business_type" placeholder="종목을 입력해 주세요." value="${UtilS.escape(
                receipt.business_type ? receipt.business_type : '',
              )}" size=50 maxlength=50]
            .input_wrap.checkout-row-1[class="${receipt.business_license ? 'done_upload' : ''}"]
              label 사업자등록증
              .checkout-row-1__body
                input.file_company_license[type="file" accept="image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript"]
                input.hidden[type="text" name="business_license" value="${UtilS.escape(
                  receipt.business_license ? receipt.business_license : '',
                )}"]
                .done_upload_text.hidden.checkout-row-1__option (업로드 완료)
      .option
        label.checkout-label-checkbox
          sp.mp_input
            input[type="checkbox" name="save_receipt_info"]
            span 
          sp 영수증 신청 정보 저장
`;
};
