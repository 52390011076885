import { $delegate } from 'fxdom/es';
import { go } from 'fxjs/es';
import { makeDfProjectionListUpdate } from '../../List/F/fs.js';
import { makeDfProjectionSidebarItemHtml } from '../../List/F/dfSidebarItemTmpl.js';

export const renderProjectionSidebarInDetail = function (prj_id) {
  $1(`.projection_item[_sel="./(#${prj_id})"]`).outerHTML = makeDfProjectionSidebarItemHtml(
    box.sel('df/projection/detail->projection'),
    box.sel(`./(#${prj_id})`),
  );
};

export const isCanQuickCancel = (prj) =>
  prj._.projection_payments &&
  prj._.projection_payments.length === 1 &&
  !['without_bank', 'offline_card', 'offline_cash', 'vbank'].includes(
    prj._.projection_payments[0].pay_method,
  );

export const initQuickCancel = function (el) {
  return go(
    el,
    $delegate('click', 'button.quick_cancel', async (e) => {
      $.don_loader_start();
      await go(e.currentTarget, box.sel, quickCancel);
      $.don_loader_end();
    }),
  );
};

const quickCancel = async function ({ id }) {
  const projection = await $.get('/@api/projection/take', { id });
  const projection_payment = projection._.projection_payments[0];
  const refund = projection_payment._.refunds[0];
  if (!isCanQuickCancel(projection) || projection_payment._.refunds.length !== 1)
    return $.alert('간편 취소가 불가능한 주문서입니다.');

  const res = await $.post('/@api/refund/imp_cancel', {
    projection_payment_id: projection_payment.id,
    imp_uid: projection_payment.imp_uid,

    projection_id: projection_payment.projection_id,
    refund_id: refund.id,
    imp_cancel_wid: box.sel('user->id'),
  });
  if (res.err) {
    return $.alert(
      res.err || '이미 취소 되었거나 일시적인 오류입니다. 계속 동작하지 않는다면 개발팀에 문의주세요.',
    );
  }
  await $.post(`/@api/projection/canceled`, { id });
  await makeDfProjectionListUpdate();
};
