export const bizapplication = () => {};

export const BIZ_APPLICATION_LIST_TABLE_COLUMN = {
  id: {
    title: 'ID',
    show: true,
  },
  comp_name: {
    title: '업체명',
    show: true,
  },
  manager_id: {
    title: '담당자 ID',
    show: false,
  },
  manager_name: {
    title: '담당자 선택',
    show: true,
  },
  manager_name_text: {
    title: '담당자',
    show: true,
  },
  status: {
    title: '상담현황',
    show: true,
  },
  created_at: {
    title: '접수일자',
    show: true,
  },
};

export const BIZ_APPLICATION_STATUS = {
  PENDING: '접수 전(신청)',
  RECEIVED: '접수 완료',
  IN_CONSULTATION: '상담 중',
  IN_PRODUCTION: '제작 중',
  COMPLETE: '상담 종료',
};

export const BIZ_APPLICATION_COMP_TYPE = {
  NORMAL: '일반 기업',
  PUBLIC: '학교 / 공공기관',
  ETC: '기타',
};

export const BIZ_APPLICATION_CONSULTATION_TYPE = {
  BIZ: '단체굿즈 / 판촉',
  KIT: '패키지 / 키트',
};
