import $dataStr from 'fxdom/es/dataStr.js';
import { equals2, mapL, rangeL, go, strMap, filter, entries, values, html } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { BIZ_APPLICATION_LIST_TABLE_COLUMN, BIZ_APPLICATION_STATUS } from '../Constant/bizapplication.js';

export const selectStatus = (status, status_id, allOption = false, allOptionTitle = '전체') => {
  return html`
    ${allOption ? html`<option value="">${allOptionTitle}</option>` : ''}
    ${go(
      status,
      entries,
      strMap(([id, name]) => `<option value="${id}" ${status_id === id ? 'selected' : ''}>${name}</option>`),
    )}
  `;
};

export const selectManagers = (managers, manager_id, firstOptionTitle = '선택') => {
  return html`
    <option value="">${firstOptionTitle}</option>
    ${go(
      managers,
      strMap(
        ({ id, name }) =>
          `<option value="${id}" ${parseInt(manager_id) === id ? 'selected' : ''}>${name}</option>`,
      ),
    )}
  `;
};

const makePaginationAnchorHtml = ({ page, url, is_current }) => legacyHtml`
  <a ${is_current ? 'class="current page-link"' : `href="${url}" class="page-link"`}>${page}</a>
`;

export const makeBootstrapPaginationHtml = ({
  start,
  end,
  current_page,
  total_page_count,
  has_prev,
  has_next,
  is_not_last,
  makeUrlForPage,
} = {}) =>
  (start == 1 && start == end) || current_page > total_page_count
    ? ''
    : legacyHtml`
        <ul class="pagination justify-content-center mt-2">
          ${
            is_not_last
              ? ''
              : legacyHtml`
                <li class="page-item" data-page_type="first">
                  <a
                    data-direction="first"
                    ${
                      current_page > 1
                        ? `href="${makeUrlForPage(1)}" class="page-link"`
                        : 'class="page-link disabled"'
                    }
                    >«</a
                  >
                </li>
              `
          }
          ${
            has_prev
              ? `<li class="page-item" data-page_type="prev"><a href="${makeUrlForPage(
                  start - 1,
                )}" class="page-link">‹</a></li>`
              : ''
          }
          ${go(
            rangeL(start, end + 1),
            mapL((page) => ({
              page,
              url: makeUrlForPage(page),
              is_current: equals2(page, current_page),
            })),
            mapL(makePaginationAnchorHtml),
            strMap((a_html) => legacyHtml` <li class="page-item" data-page_type="page">${a_html}</li> `),
          )}
          ${
            has_next
              ? `<li class="page-item" data-page_type="next"><a href="${makeUrlForPage(
                  end + 1,
                )}" class="page-link">›</a></li>`
              : ''
          }
          ${
            is_not_last
              ? ''
              : legacyHtml`
                <li class="page-item" data-page_type="last">
                  <a
                    data-direction="last"
                    ${
                      total_page_count > current_page
                        ? `href="${makeUrlForPage(total_page_count)}" class="page-link"`
                        : 'class="page-link disabled"'
                    }
                    >»</a
                  >
                </li>
              `
          }
        </ul>
      `;

export const bizApplicationListBodyTmpl = ({ list, pagination_state, managers, columns, status }) => {
  return html`
    <table class="table table-bordered table-hover">
      <thead class="table-light">
        <tr class="text-center">
          ${go(
            columns,
            values,
            filter(({ show }) => show),
            strMap(({ title }) => html` <th>${title}</th>`),
          )}
        </tr>
      </thead>
      <tbody>
        ${list.length > 0
          ? strMap((row) => {
              return html` <tr class="text-center" data-fx-json="${$dataStr({ managers, item: row })}">
                ${go(
                  columns,
                  entries,
                  filter(([col, { show }]) => show),
                  strMap(([col]) => {
                    if (col === 'created_at') {
                      return html` <td class="align-middle">
                        ${moment(row[col]).format('YYYY-MM-DD HH:mm')}
                      </td>`;
                    }

                    if (col === 'manager_name') {
                      return html` <td class="align-middle">
                        <select class="form-select select-manager">
                          ${selectManagers(managers, row.manager_id)}
                        </select>
                      </td>`;
                    }

                    if (col === 'status') {
                      return html` <td class="align-middle">
                        <select class="form-select select-status">
                          ${selectStatus(status, row[col])}
                        </select>
                      </td>`;
                    }

                    if (col === 'comp_name') {
                      return html` <td class="align-middle comp-name"><u>${UtilS.escape(row[col])}</u></td>`;
                    }

                    return html` <td class="align-middle">${row[col] ?? ''}</td>`;
                  }),
                )}
              </tr>`;
            }, list)
          : html`<tr class="text-center">
              <td
                colspan="${go(
                  columns,
                  values,
                  filter(({ show }) => show),
                ).length}"
              >
                주어진 검색 조건으로 조회된 신청 내역이 존재하지 않습니다.
              </td>
            </tr>`}
      </tbody>
    </table>
    ${makeBootstrapPaginationHtml(pagination_state)}
  `;
};
export const bizAppplicationListTmpl = ({ list, pagination_state, query, managers }) => {
  return html` <div class="df_biz_application_list p-4">
    <div class="header filter_search">
      <div class="title">
        <h1>패키지 / 키트 상담 신청 내역</h1>
      </div>
      <div class="search_bar">
        <form action="/biz_application_list" method="get" id="search_bar">
          <div class="sd bg-body-tertiary p-5 rounded mt-3">
            <div class="row g-3 align-items-center">
              <div class="col-auto">
                <label class="col-form-label fw-semibold" for="biz_application_search">검색어</label>
              </div>
              <div class="col-auto">
                <input
                  class="form-control"
                  id="biz_application_search"
                  type="text"
                  name="search"
                  placeholder="회사명, 메모"
                  value="${query.search}"
                />
              </div>
              <div class="col-auto">
                <label class="col-form-label fw-semibold" for="biz_application_status_search"
                  >상담 현황</label
                >
              </div>
              <div class="col-auto">
                <select class="form-select" id="biz_application_status_search" name="status">
                  ${selectStatus(BIZ_APPLICATION_STATUS, query.status, true)}
                </select>
              </div>
              <div class="col-auto">
                <label class="col-form-label fw-semibold" for="biz_application_manager_search">담당자</label>
              </div>
              <div class="col-auto">
                <select class="form-select" id="biz_application_manager_search" name="manager_id">
                  ${selectManagers(managers, query.manager_id, '전체')}
                </select>
              </div>
            </div>
            <div class="row mt-1 g-3 align-items-center">
              <div class="col-auto">
                <label class="col-form-label fw-semibold" for="order_at">정렬</label>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center gap-1">
                  <select class="form-select" id="order_at" name="order_at">
                    <option value="created_at" ${query.order_at !== 'comp_name' ? 'selected' : ''}>
                      접수일자
                    </option>
                    <option value="comp_name" ${query.order_at === 'comp_name' ? 'selected' : ''}>
                      업체명
                    </option>
                  </select>
                  <span class="col-auto">기준</span>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="order_by_desc"
                    value="DESC"
                    type="radio"
                    name="order_by"
                    ${query.order_by !== 'ASC' ? 'checked' : ''}
                  />
                  <label class="form-check-label" for="order_by_desc">내림차순</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="order_by_asc"
                    value="ASC"
                    type="radio"
                    name="order_by"
                    ${query.order_by === 'ASC' ? 'checked' : ''}
                  />
                  <label class="form-check-label" for="order_by_asc">오름차순</label>
                </div>
              </div>
            </div>
            <div class="row mt-4 align-items-center">
              <div class="col">
                <div class="btn-group" role="group" aria-label="initial and search buttons">
                  <a class="init btn btn-outline-primary" href="/biz_application_list">초기화 </a>
                  <button class="btn btn-primary" type="submit">검색</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="body mt-3">
      ${bizApplicationListBodyTmpl({
        list,
        pagination_state,
        managers,
        columns: BIZ_APPLICATION_LIST_TABLE_COLUMN,
        status: BIZ_APPLICATION_STATUS,
      })}
    </div>
  </div>`;
};
