import { go, filter, pluck, reject } from 'fxjs/es';

export const TEST_USERS = [193535, 4469081];
export const IS_PUBLIC = {
  MARPPLE: false,
  MARPPLE_SHOP: false,
};
export const ALL_PAYMENT_METHODS = [
  {
    method_key: 'PAYMENTWALL_CREDIT_CARD',
    name: 'Credit Card',
    method_type: 'CARD',
    langs: ['en', 'jp'],
    use: ['', 'creator'],
    pmt_channel: 'PAYMENTWALL',
    channel_key: 'PAYMENTWALL',
  },
  {
    method_key: 'PAYMENTWALL_KONBINI',
    name: 'Konbini',
    method_type: 'OTC',
    langs: ['jp'],
    // use: ['', 'creator'],
    use: [],
    pmt_channel: 'PAYMENTWALL',
    channel_key: 'PAYMENTWALL',
  },
  {
    method_key: 'KOMOJU_PAY_EASY',
    name: 'Pay Easy',
    method_type: 'BANK_TRANSFER',
    langs: ['jp'],
    // use: [''],
    use: [],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
  },
  {
    method_key: 'KOMOJU_KONBINI_DAILY_YAMAZAKI',
    name: 'Daily Yamazaki',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    // use: [''],
    use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_LAWSON',
    name: 'Lawson',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_FAMILY_MART',
    name: 'FamilyMart',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    // use: [''],
    use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_MINISTOP',
    name: 'MiniStop',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    // use: [''],
    use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_SEICOMART',
    name: 'Seicomart',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    // use: [''],
    use: [],
  },
];

function extractPayments(lang, collabo_type) {
  return go(
    ALL_PAYMENT_METHODS,
    filter(({ langs, use }) => langs?.includes(lang) && use.includes(collabo_type)),
  );
}

export const CURRENT_PAYMENT_METHODS_EN = extractPayments('en', '');
export const CURRENT_PAYMENT_METHODS_JP = extractPayments('jp', '');

export const CURRENT_PAYMENT_METHODS_CREATOR_EN = extractPayments('en', 'creator');
export const CURRENT_PAYMENT_METHODS_CREATOR_JP = extractPayments('jp', 'creator');

export const STATUS = {
  Success: 'Success',
  Pending: 'Pending',
  Failed: 'Failed',
  Expired: 'Expired',
  Initiated: 'Initiated',
  UnderReview: 'Under Review',
  /* 새로 이런 상태를 봤길래 추가해 봄 */
};

export const METHOD_TYPE = {
  CARD: 'CARD',
  OTC: 'OTC',
  BANK_TRANSFER: 'BANK_TRANSFER',
};

export const VBANK_STYLE_PAY_METHODS = go(
  ALL_PAYMENT_METHODS,
  filter(({ method_type }) => [METHOD_TYPE.BANK_TRANSFER, METHOD_TYPE.OTC].includes(method_type)),
  pluck('method_key'),
);

export const CHECKOUT_SERVICE_PAYMENT_METHODS = go(
  ALL_PAYMENT_METHODS,
  filter(({ pmt_channel }) => pmt_channel === 'PAYMENTWALL'),
);
export const PAYMENT_SERVICE_PAYMENT_METHODS = go(
  ALL_PAYMENT_METHODS,
  reject(({ pmt_channel }) => pmt_channel === 'PAYMENTWALL'),
);

export const METHOD_KEYS = go(ALL_PAYMENT_METHODS, pluck('method_key'));
