import { sel, flat, go, map, sumBy } from 'fxjs/es';
import { AdScriptClicksF } from '../../../../modules/AdScript/Clicks/F/Function/module/AdScriptClicksF.js';
import { AdScriptCriteoMpF } from '../../../../modules/AdScript/Criteo/Mp/F/Function/module/AdScriptCriteoMpF.js';
import { AdScriptNaverF } from '../../../../modules/AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { AdScriptTwitterF } from '../../../../modules/AdScript/Twitter/F/Function/module/AdScriptTwitterF.js';
import { MPAY_RECEIPT_FRAME } from '../../../../modules/MPay/Receipt/S/constant.js';
import { PriceS } from '../../../../modules/Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS.js';
import { ConfigSentryF } from '../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';

export default async function () {
  $.frame({ frame_name: MPAY_RECEIPT_FRAME });

  const projection = box.sel('projection');
  const p_lang = projection.lang;

  window.email_address = '';
  window.phone_number = '';

  try {
    if (typeof gtag == 'function' && G.collabo_type == '') {
      if (p_lang == 'kr') {
        window.email_address = projection.orderer_email;
        window.phone_number = `+${UtilS.makeGlobalPhoneNumberKr(projection.orderer_mobile)}`;
      }

      gtag('event', 'purchase', {
        send_to: 'G-Y184NEXE0W',
        transaction_id: projection.id,
        affiliation: 'MARPPLE',
        value:
          p_lang == 'kr'
            ? projection.product_price
            : p_lang == 'jp'
            ? projection.product_price_jp
            : projection.product_price_en,
        currency: p_lang == 'kr' ? 'KRW' : p_lang == 'jp' ? 'JPY' : 'USD',
        tax: 0,
        dynx_itemid: map(function (up) {
          return up.base_product_id;
        }, projection._.ups), // 구해한 상품의 아이디 배열
        dynx_itemid2: map(function (up) {
          return up.base_product_id;
        }, projection._.ups), // 구해한 상품의 아이디 배열
        dynx_pagetype: 'conversion',
        dynx_totalvalue: PriceS.price(projection.product_original_price + _en), // 총 결제금액
        ecomm_prodid: map(function (up) {
          return up.base_product_id;
        }, projection._.ups),
        ecomm_pagetype: 'conversion',
        event_label: 'conversion',
        shipping:
          p_lang == 'kr'
            ? projection.shipping_price
            : p_lang == 'jp'
            ? projection.shipping_price_jp
            : projection.shipping_price_en,
        items: map(function (up) {
          const up_c_ss = go(
            up._.up_cs,
            map(function (up_c) {
              return up_c._.up_c_ss;
            }),
            flat,
          );

          const quantity = sumBy(function (up_c_s) {
            return up_c_s.quantity;
          }, up_c_ss);

          return {
            id: up.base_product_id,
            name: up._.base_product.name,
            list_name: '결제완료 페이지',
            item_category: sel('_.base_product._.cate_list.name', up),
            item_category2: sel('_.base_product._.cate_item.name', up),
            // "brand": "Google",
            // "category": "Apparel/T-Shirts",
            // "variant": "Black",
            list_position: 1,
            quantity,
            price: up._.product_color['price' + _en],
          };
        }, projection._.ups),
      });

      if (p_lang == 'kr') {
        gtag('event', 'conversion', {
          send_to: 'AW-881715956/e675CIj52GYQ9NW3pAM',
          value: projection.product_price,
          currency: 'KRW',
          transaction_id: projection.id,
        });
      } else if (p_lang == 'jp') {
        gtag('event', 'conversion', {
          send_to: 'AW-828951906/cQpHCObO0sIBEOKao4sD',
          value: projection.product_price_jp,
          currency: 'JPY',
          transaction_id: projection.id,
        });
      } else {
        gtag('event', 'conversion', {
          send_to: 'AW-828951906/iAo3CPqw3Y8BEOKao4sD',
          value: projection.product_price_en,
          currency: 'USD',
          transaction_id: projection.id,
        });
      }
    }

    if (typeof fbq == 'function' && G.collabo_type == '') {
      fbq('track', 'Purchase', {
        value: projection['product_price' + _en],
        currency: p_lang == 'kr' ? 'KRW' : 'USD',
        content_type: 'product',
        contents: map(function (up) {
          const up_c_ss = go(
            up._.up_cs,
            map(function (up_c) {
              return up_c._.up_c_ss;
            }),
            flat,
          );

          const quantity = sumBy(function (up_c_s) {
            return up_c_s.quantity;
          }, up_c_ss);

          return {
            id: up.base_product_id,
            name: up._.base_product.name,
            quantity,
            item_price: up._.product_color['price' + _en],
          };
        }, projection._.ups),
      });
    }

    if (p_lang == 'kr' && typeof kakaoPixel == 'function' && G.collabo_type == '') {
      const products = map(function (up) {
        const up_c_ss = go(
          up._.up_cs,
          map(function (up_c) {
            return up_c._.up_c_ss;
          }),
          flat,
        );

        const quantity = sumBy(function (up_c_s) {
          return up_c_s.quantity;
        }, up_c_ss);

        return {
          name: up._.base_product.name,
          quantity,
          price: up._.product_color.price,
        };
      }, projection._.ups);

      kakaoPixel('2648073228351915297').purchase({
        total_quantity: sumBy(function (p) {
          return p.quantity;
        }, products), // 주문 내 상품 개수(optional)
        total_price: projection.product_price, // 주문 총 가격(optional)
        currency: 'KRW', // 주문 가격의 화폐 단위(optional, 기본 값은 KRW)
        products, // 주문 내 상품 정보(optional)
      });
    }

    if (p_lang == 'kr' && typeof window.naver_wcs_trans == 'function' && G.collabo_type == '') {
      window.naver_wcslog('1', projection.product_price);
      AdScriptNaverF.sendMpPurchase({
        id: `${projection.id}`,
        value: projection['product_price' + _en],
        items: map(function (up) {
          const up_c_ss = go(
            up._.up_cs,
            map(function (up_c) {
              return up_c._.up_c_ss;
            }),
            flat,
          );

          const quantity = sumBy(function (up_c_s) {
            return up_c_s.quantity;
          }, up_c_ss);

          return {
            id: up.base_product_id,
            name: up._.base_product.name,
            category: sel('_.base_product._.cate_list.name', up),
            quantity,
            payAmount: up._.product_color['price' + _en],
          };
        }, projection._.ups),
      });
    }

    if (p_lang == 'kr' && G.collabo_type == '') {
      // 다음 클릭스
      AdScriptClicksF.purchase(projection);
    }

    if (p_lang == 'kr' && window.criteo_q) {
      AdScriptCriteoMpF.purchase(projection);
    }

    AdScriptTwitterF.purchaseMp(projection, p_lang);
  } catch (err) {
    ConfigSentryF.error(err);
  }
}
